import React, { useState, useEffect } from 'react';
import { Button, Container } from 'react-bootstrap';
import Images from '../Components/Images';
import { Link, NavLink } from 'react-router-dom';
import $ from 'jquery'

const Sidebar = () => {

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, []);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  const [mbmenu, setMbmenu] = useState(windowWidth > 575 ? false : true);

  $(window).scroll(function () {
    var scroll = $(window).scrollTop();
    if ($(this).scrollTop()) {
      $(".sidebarimg").addClass("fixedTop");
    } else {
      $(".sidebarimg").removeClass("fixedTop");
    }
    if (scroll >= 100) {
      $(".fixedTop").addClass('scroll');
      $("#scroll-top").addClass('show');
    }
    else {
      $(".fixedTop").removeClass('scroll');
      $("#scroll-top").removeClass('show');
    }
  });

  return (
    <>

      <div className='sidebar'>

        <div className={mbmenu ? 'sidebarimg widthsmall' : 'sidebarimg'}>
          <img src={Images.menuss} onClick={() => setMbmenu(!mbmenu)} className='menuss mb-3' />
          <div className='navlist'>
            <ul>
              <li><NavLink to="/dashboard">Dashboard</NavLink></li>
              <li><NavLink to="/mybank">My Bank</NavLink></li>
              <li><NavLink to="/history">History</NavLink></li>
              <li><NavLink to="/referral">Referral Link</NavLink></li>
            </ul>
          </div>
        </div>

      </div>

    </>

  )
}

export default Sidebar; 