import React, { useState} from "react";
import Chart from "react-apexcharts";

export default function ApexChart(props) {

  const series = [
    {
      name: "Member",
      data: props.data
    }
  ];

  const options = {
    chart: {
      id: "realtime",
      foreColor: '#ffffff',
      height: 300,
      type: "line",
      animations: {
        enabled: true,
        easing: "linear",
        dynamicAnimation: {
          speed: 1000
        }
      },
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      }
    },
    grid: {
      borderColor: "gray",
    },
    dataLabels: {
      enabled: false
    },
    colors: ['#70FDFC'],
    stroke: {
      width: 4,
      curve: "smooth",
    },
    title: {
      text: "Polynyx Chart",
      align: "left"
    },
    markers: {
      size: 0
    },
    xaxis: {
      // type: 'category',
      // categories: ["dsadsa","sadasd","sadsad","wqewq","fdssd","wqesd","qwdsd","qager"],
      axisBorder: {
        show: true,
        color: '#fff',
    },
    title: {
      text: "Days",
      offsetX: 0,
      offsetY: 5,
      style: {
          color: '#fff',
          fontSize: '17px',
          fontFamily: 'Blade logic DEMO',
          fontWeight: 400,
          cssClass: 'apexcharts-xaxis-title',
      },
  },
    labels:{
      show: true,
    },
    },
    yaxis: {
      max: 180,
      title: {
        text: "Daily Earnings",
        offsetX: -5,
        offsetY: 20,
        style: {
            color: '#fff',
            fontSize: '17px',
            fontFamily: 'Blade logic DEMO',
            fontWeight: 400,
            cssClass: 'apexcharts-xaxis-title',
        },
    },
    },
    legend: {
      show: false
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        gradientToColors: [ '#c52efadb','#c52efadb'],
      },
    },

  };
  return (
    <div id="chart" className="px-2 px-md-4 py-4 py-md-5">
      <Chart options={options} series={series} type="line"  width="100%" />
    </div>
  );
}
