let Front_URL = "";
let Back_URL = "";
let Users_URL = "";
let Contract = "";
let v1Url = "";
let Admin_Url = "";
let Image_url = "";
let Provider = "";
let ChainID = "11155111"
let RPC_URL = "https://sepolia.infura.io/v3/b1b3f95471bb4a728aa9558e6f1ab569"
let BscScan = "https://sepolia.etherscan.io/"
let NetworkName = "sepolia"
let HexString = "0xaa36a7"
let TokenAddress = "0xc6aBa068A91d327B259327523f71f51221943186".toLowerCase()
let AdminAddress = "0x025c1667471685c323808647299e5DbF9d6AdcC9".toLowerCase()
let TokenName = "CAKE"
let encodekey = "PoLyNyX05062024";
let AdminReferralKey = "I5gq3n8oC0nq"
var nameFormat = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
let ClaimLimit = 1500
var toasterOption = {
    position: "top-right",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    limit: 1,
};
//let WalletconnectProjectID = d92e2db22a14de8b6f2b3ac926e7793c
let EnvName = "demo";
let currAdrress = "";
if (EnvName === "production") {
    Front_URL = "";
    Back_URL = "";
    v1Url = "";
    Users_URL = "";
    Contract = "";
    Provider = "";
} else if (EnvName === "demo") {
    Front_URL = "https://polynyx.maticz.in/";
    Users_URL = "https://backend-ploynyx.maticz.in/user";
    Back_URL = "https://backend-ploynyx.maticz.in";
    v1Url = "https://backend-ploynyx.maticz.in/v1";
    Admin_Url = "https://backend-ploynyx.maticz.in/admin";
    Image_url = "https://backend-ploynyx.maticz.in/";
    Contract = "0xB0A6738Be82bcEae7fb8a34d7E70dd0720e662fe".toLowerCase();
    Provider = "https://sepolia.infura.io/v3/b1b3f95471bb4a728aa9558e6f1ab569";
} else if (EnvName === "testdemo") {
    Front_URL = "http://192.53.121.26/";
    Users_URL = "http://192.53.121.26:6004/user";
    Back_URL = "http://192.53.121.26:6004";
    v1Url = "http://192.53.121.26:6004/v1";
    Admin_Url = "http://192.53.121.26:6004/admin";
    Image_url = "http://192.53.121.26:6004/";
    Contract = "0x3AC95A9c633cB77B4ed29A23d7f841fF2061D7e9".toLowerCase();
    Provider = "https://sepolia.infura.io/v3/b1b3f95471bb4a728aa9558e6f1ab569";
} else {
    Front_URL = "http://localhost:3000/";
    Back_URL = "http://localhost:7001";
    v1Url = "http://localhost:7001/v1";
    Users_URL = "http://localhost:3010/user";
    Admin_Url = "http://localhost:7001/admin";
    Image_url = "http://localhost:7001/";
    Contract = "0x3AC95A9c633cB77B4ed29A23d7f841fF2061D7e9".toLowerCase();
    Provider = "https://sepolia.infura.io/v3/b1b3f95471bb4a728aa9558e6f1ab569";
}
let key = {
    Front_URL: Front_URL,
    Back_URL: Back_URL,
    v1Url: v1Url,
    vUrl: v1Url,
    Admin_Url: Admin_Url,
    Image_url: Image_url,
    EMAIL: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
    toasterOption: toasterOption,
    Users_URL: Users_URL,
    currAdrress: currAdrress,
    encodekey: encodekey,
    ChainID: ChainID,
    RPC_URL: RPC_URL,
    BscScan: BscScan,
    NetworkName: NetworkName,
    HexString: HexString,
    AdminReferralKey: AdminReferralKey,
    nameFormat: nameFormat,
    TokenAddress: TokenAddress,
    TokenName: TokenName,
    Contract: Contract,
    ClaimLimit: ClaimLimit,
    AdminAddress: AdminAddress
};

export default key;
