import { EncryptData, Decryptdata, handleCallback } from '../Helper/EncHelper';
import isEmpty from '../../Config/isEmpty';
import axios from 'axios'

export const axiosFunc = handleCallback(async (data) => {
    console.log("Comming Inside Axios", data);

    let Resp = await axios(data)

    console.log("Response From Axios", Resp?.data, Decryptdata(Resp.data), isEmpty(Decryptdata(Resp.data)))

    if (Resp?.data) {
        Resp.data = Resp.data ? isEmpty(Decryptdata(Resp.data)) ? Resp.data : Decryptdata(Resp.data) : Resp.data
    }
    console.log("After Decrypting Data", Resp?.data)
    return Resp.data
})
