import React, { useState, useEffect } from 'react';
import { Button, Container, Accordion } from 'react-bootstrap';
import Header from "../Layouts/header";
import Footer from "../Layouts/footer";
import Images from '../Components/Images';
import { getCmsContent } from '../Action/Axios/cmsaxios';

import { fetchnewslist, getnewslist } from '../Action/Axios/adminaxios';
import { useParams } from 'react-router-dom';


const Readmore = () => {
    // let location = useLocation()
    // console.log("datass",location.state);
    // let datas = location.state;
    // console.log("dats",datas)
    // const [abouts,setabouts] = useState([])
    const { heading } = useParams()
    console.log("dataas", heading);
    const [news, setnews] = useState([])
    console.log("newwss", news);
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
        Fetchnews();
    }, []);


    const Fetchnews = async () => {
        let resp = await fetchnewslist({ heading })
        console.log("Getnewslist", resp.data);
        if (resp?.status) setnews(resp?.data)
    }
    return (

        <>

            <div className='faqpage animehead'>

                <Header />

                <div className='marketplacedetail_sec firsttop pb-5'>
                    <span className='violentgrad'></span>
                    <span class="blugradient"></span>
                    <img src={Images.detailleftarrow} className='detailleftarrow d-none d-xl-block' />
                    <div className='container pt-4'>
                        {/* <h1 className='mainheading mt-4 pb-5'><img src={Images.leftdot} className='leftdot' /> <img src={Images.headingblink} className='headingblink' /> About <img src={Images.headingblink} className='headingblink' />  <img src={Images.leftdot} className='leftdot rightdot' /></h1> */}
                        <h3 className='mainheading mt-4 mb-4'>{news?.heading}</h3>
                        <div className='row'>
                            <div className='col-xl-11 col-xxl-11 mx-auto'>
                            <p className='subtext mt-4'>
                            <br /><br /><div dangerouslySetInnerHTML={{ __html: news?.content }}></div>
                            {/* <div dangerouslySetInnerHTML={{
                                        __html: abouts?.description,
                                    }}></div> */}
                        </p>
                            </div>
                        </div>

                      

                    </div>
                </div>

                <Footer />
            </div>
        </>

    )
}

export default Readmore;



