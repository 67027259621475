import React, { useState, useEffect } from 'react';
import { Button, Container } from 'react-bootstrap';
import Header from "../Layouts/header";
import Footer from "../Layouts/footer";
import Images from '../Components/Images';
import { FaSearch } from "react-icons/fa";
import Nftcardpage from "../Components/nftcard";
import { Link, useLocation } from 'react-router-dom';
import { GetNFTs } from '../Action/Controller/Token'
import { socket } from "../Action/Helper/EncHelper";
import Buymodals from '../Components/Modals/buymodal';

const Marketplace = (props) => {
    const location = useLocation();
    // const[buymodal, setBuymodal] = useState(false);
    const [NFTDet, SetNFTDet] = useState([])
    const [RENDTDet, SetRENDTDet] = useState([])
    let state = location.state;
    console.log("statelocationlocation", state, location);
    const [accouninfo, setAccountinfo] = useState([
        {
            desc: 'Lorem Ipsum',
            number: '1.22 ETH',
            name: 'Jack Wilson',
            subtext: 'Bored Ape',
            image: Images.eth,
            profileimg: Images.profile,
            nftimg: Images.monkey,
        },
        {
            desc: 'Lorem Ipsum',
            number: '1.22 ETH',
            name: 'Jack Wilson',
            subtext: 'Bored Ape',
            image: Images.eth,
            profileimg: Images.profile,
            nftimg: Images.monkey,
        },
        {
            desc: 'Lorem Ipsum',
            number: '1.22 ETH',
            name: 'Jack Wilson',
            subtext: 'Bored Ape',
            image: Images.eth,
            profileimg: Images.profile,
            nftimg: Images.monkey,
        },
        {
            desc: 'Lorem Ipsum',
            number: '1.22 ETH',
            name: 'Jack Wilson',
            subtext: 'Bored Ape',
            image: Images.eth,
            profileimg: Images.profile,
            nftimg: Images.monkey,
        },
        {
            desc: 'Lorem Ipsum',
            number: '1.22 ETH',
            name: 'Jack Wilson',
            subtext: 'Bored Ape',
            image: Images.eth,
            profileimg: Images.profile,
            nftimg: Images.monkey,
        },
        {
            desc: 'Lorem Ipsum',
            number: '1.22 ETH',
            name: 'Jack Wilson',
            subtext: 'Bored Ape',
            image: Images.eth,
            profileimg: Images.profile,
            nftimg: Images.monkey,
        },
        {
            desc: 'Lorem Ipsum',
            number: '1.22 ETH',
            name: 'Jack Wilson',
            subtext: 'Bored Ape',
            image: Images.eth,
            profileimg: Images.profile,
            nftimg: Images.monkey,
        },
        {
            desc: 'Lorem Ipsum',
            number: '1.22 ETH',
            name: 'Jack Wilson',
            subtext: 'Bored Ape',
            image: Images.eth,
            profileimg: Images.profile,
            nftimg: Images.monkey,
        },
    ]
    )

    const [filterText, setfilterText] = useState("")

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }, []);

    useEffect(() => {
        FetchNFTsData()
    }, []);

    useEffect(() => {
        console.log("dasdasdas");
        socket.on("send", (data) => {
            console.log("Reciving Data from Backend Socket", data);
        });
    }, [socket]);

    const FetchNFTsData = async () => {
        console.log("FetchNFTsData");
        var Response = await GetNFTs()
        console.log("GetNFTs", Response?.Details);
        if (Response.status) {
            SetNFTDet(Response?.Details)
            SetRENDTDet(Response?.Details)
        }
    }
    useEffect(() => {
        filteredItems()
    }, [filterText])

    const filteredItems = async () => {
        if (filterText !== "") {
            var FilerData = NFTDet.filter(
                item =>
                    item.searchtext.toLocaleLowerCase().includes(filterText.toLowerCase())
                // ||
                // item.title.toLocaleLowerCase().includes(filterText)
            );
            console.log("FilerData", FilerData);
            if (FilerData) {
                SetNFTDet(FilerData)
            } else {
                SetNFTDet(RENDTDet)
            }
        } else {
            SetNFTDet(RENDTDet)
        }
    }

    return (
        <>
            {/* {buymodal && <Buymodals onDismiss={() => setBuymodal(false)} />} */}
            <div className='Marketplace animehead'>

                <Header />

                <div className='pt-5'>
                    <div className='marketplace_section firsttop pb-5'>
                        <span className='violentgrad'></span>
                        <img src={Images.topvector} className='topvector' />
                        <div className='container pb-2'>
                            <div className='row align-items-center'>
                                <div className='col-md-6 col-lg-6 mt-4'>
                                    <h2 className='heading'>Browse Marketplace <img src={Images.headingblink} className='headingblink' /></h2>
                                    <p className='subtext'>Browse through more than 50k NFTs on the NFT Marketplace.</p>
                                </div>
                                <div className='col-md-6 col-lg-6 mt-4'>
                                    <div class="input-group mb-3">
                                        <input type="text" class="form-control" onChange={(e) => { setfilterText(e.target.value) }} placeholder="Search your favorite NFT’s" />
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1"><FaSearch /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div className='marketback  pt-5 pb-5'>
                    {/* <h4 className='text-start mainheading buytextnft' onClick={() => setBuymodal(true)}>Buy NFT</h4> */}
                    <img src={Images.circle} className='circle' />
                    <img src={Images.circle} className='circle1' />
                    <img src={Images.circle} className='circle2' />
                    <div className='container pb-5'>
                        <div className='row'>
                            {NFTDet.length > 0 ?
                                <>
                                    {
                                        NFTDet.map((NFT) => (
                                            <div className='col-sm-6 col-md-6 col-lg-4 col-xl-3 mt-5'>
                                                <Link state={NFT} to="/marketplacedetail"><Nftcardpage datas={NFT} /></Link>
                                            </div>
                                        ))
                                    }
                                </>
                                :
                                <h4 className='textclr mainheading text-center'>No items found</h4>
                            }
                        </div>
                    </div>
                </div>

                {/* <div className='h-100vh'>
                    <h1 className='mainheading'>Coming Soon...</h1>
                </div> */}

                <Footer />
            </div>


        </>
    )
}

export default Marketplace;