import React, { useRef, useEffect, useState } from "react";
import { Button, Container, Row, Nav, NavDropdown, Navbar, Modal } from 'react-bootstrap';
import Images from "../Images";
import { useSelector, useDispatch } from 'react-redux';
import { Account_Connect, Account_disConnect } from "../../Redux/action";
import Config from "../../Config/Config"
import { connectWallet } from "../../Action/Hooks/useWallet"
import { toast } from "react-toastify";
import { GetOrSave_Action, CheckReferred, CheckReferalCode } from '../../Action/Controller/Token'
import CopyToClipboard from "react-copy-to-clipboard";
import { FiCopy } from "react-icons/fi";

export default function Walletmodal(props) {
    console.log("propsprops", props);
    const dispatch = useDispatch();
    const Wallet_Details = useSelector((state) => state.wallet_connect_context);
    const [Walletmodals, setWalletmodals] = useState(true);

    const initialConnectWallet = async (type, register) => {
        var toasttxt = null
        console.log("bnbnbnbnbnbbnbnbnbnbbn");
        if (type === 'MetaMask') {
            toasttxt = "Unlock Metamask & Refresh Page"
        }
        else if (type === 'Coinbase') {
            toasttxt = "Coinbase Wallet Checking"
        }
        else {
            toasttxt = "Wallet Connect Checking"
        }
        if (type == "WalletConnect") {
            setWalletmodals(false)
        }
        var networkConfiguration = {}
        const id = toast.loading(`${toasttxt}`, { closeButton: true, theme: "dark" });
        try {


            console.log("connecttype............", type, Config.ChainID)
            var accountDetails = await connectWallet(type, Config.ChainID, register)
            console.log("accountDetails......................", accountDetails?.accountAddress, accountDetails?.coinBalance, accountDetails)
            if (accountDetails && accountDetails?.accountAddress !== undefined) {
                if (accountDetails && accountDetails?.accountAddress) {
                    const IDcheck = await accountDetails?.web3?.eth?.getChainId();
                    console.log("ChainID Checking", IDcheck);

                    let Payload11 = { user: { curraddress: accountDetails?.accountAddress } }
                    console.log("Payload11", Payload11);
                    var RefereddCheckReferred = await CheckReferred(Payload11)
                    console.log("RefereddCheckReferred", RefereddCheckReferred, localStorage.getItem("StatusMaintainRef"));

                    if ((RefereddCheckReferred.status || localStorage.getItem("StatusMaintainRef")) || localStorage.getItem("HeaderID")) {
                        let SendData = {
                            addr: accountDetails?.accountAddress,
                            Referral: localStorage.getItem("ReferralID") ? localStorage.getItem("ReferralID") : Config.AdminReferralKey
                            // Config.AdminReferralKey
                        }
                        console.log("localStorage.getIte", localStorage.getItem("ReferralID"), typeof localStorage.getItem("ReferralID"));
                        if (!(localStorage.getItem("ReferralID")) && Wallet_Details.NotRefered) {
                            localStorage.clear();
                            return toast.update(id, {
                                render: "Enter Referral code",
                                type: "error",
                                isLoading: false,
                                autoClose: 2500,
                                closeButton: true,
                                theme: "dark"
                            });
                        }
                        let PayloadRef = { user: { curraddress: (localStorage.getItem("ReferralID") || Config.AdminReferralKey) } }
                        var CheckRef = await CheckReferalCode(PayloadRef)
                        console.log("CheckRef", CheckRef);
                        if (CheckRef.status) {
                            var UserSave = await GetOrSave_Action(SendData)
                            console.log("UserSave", UserSave);
                            if (UserSave?.data?.status) {
                                dispatch({
                                    type: Account_Connect,
                                    Account_Detail: {
                                        UserAccountAddr: accountDetails?.accountAddress,
                                        UserAccountBal: accountDetails?.coinBalance,
                                        TokenBalance: accountDetails?.tokenBalance,
                                        WalletConnected: "true",
                                        Web3: accountDetails?.web3,
                                        provider: accountDetails?.web3?.providers,
                                        User: UserSave?.data?.User,
                                        UserLevels: UserSave?.data?.LevelUser,
                                        RefDetails: UserSave?.data?.RefDetails,
                                        HowRefered: UserSave?.data?.HowRefered,
                                        AllUsers: UserSave?.data?.AllUser,
                                        NotRefered: false
                                    }
                                })

                                if (accountDetails.accountAddress) {
                                    toast.update(id, {
                                        render: "Wallet Connected Successfully",
                                        type: "success",
                                        isLoading: false,
                                        autoClose: 2500,
                                        closeButton: true,
                                        theme: "dark"
                                    });
                                    localStorage.removeItem("ReferralID")
                                    localStorage.removeItem("HeaderID")
                                    localStorage.removeItem('StatusMaintainRef')
                                    setWalletmodals(false)
                                }
                            } else {
                                toast.update(id, {
                                    render: "Failed to Connect Wallet.Tryit Again",
                                    type: "error",
                                    isLoading: false,
                                    autoClose: 2500,
                                    closeButton: true,
                                    theme: "dark"
                                });
                                setWalletmodals(false)
                                localStorage.clear();
                            }
                        } else {
                            if (localStorage.getItem("walletConnectType") == "WalletConnect") {
                                setWalletmodals(true)
                            }
                            toast.update(id, {
                                render: "Enter Valid Referral code",
                                type: "error",
                                isLoading: false,
                                autoClose: 2500,
                                closeButton: true,
                                theme: "dark"
                            });
                            localStorage.removeItem("ReferralID")
                            localStorage.setItem('StatusMaintainRef', false)
                            dispatch({
                                type: Account_Connect,
                                Account_Detail: {
                                    NotRefered: true
                                }
                            })
                        }
                    } else {
                        console.log("localStorage.getItem", localStorage.getItem("walletConnectType"));
                        if (localStorage.getItem("walletConnectType") == "WalletConnect") {
                            setWalletmodals(true)
                        }
                        toast.update(id, {
                            render: "You Don't Any referral.Enter Referal Code",
                            type: "error",
                            isLoading: false,
                            autoClose: 2500,
                            closeButton: true,
                            theme: "dark"
                        });
                        //setWalletmodals(false)

                        localStorage.setItem('StatusMaintainRef', false)
                        dispatch({
                            type: Account_Connect,
                            Account_Detail: {
                                NotRefered: true
                            }
                        })
                    }

                }
            } else {
                toast.update(id, {
                    render: "Failed to Connect Wallet",
                    type: "error",
                    isLoading: false,
                    autoClose: 2500,
                    closeButton: true,
                    theme: "dark"
                });
                setWalletmodals(false)
                localStorage.clear();
            }


        } catch (err) {
            toast.update(id, {
                render: "Failed to Connect Wallet",
                type: "error",
                isLoading: false,
                autoClose: 2500,
                closeButton: true,
                theme: "dark"
            });
            setWalletmodals(false)
            localStorage.clear();
        }
    }

    // const initialConnectWallet = async (type, register) => {
    //     const toasttxt = getToastText(type);
    //     const id = toast.loading(`${toasttxt}`, { closeButton: true, theme: "dark" });

    //     try {
    //         const accountDetails = await connectWallet(type, Config.ChainID, register);

    //         if (accountDetails && accountDetails.accountAddress) {
    //             const IDcheck = await accountDetails.web3.eth.getChainId();
    //             const referralID = localStorage.getItem("ReferralID");
    //             const Payload11 = { user: { curraddress: accountDetails?.accountAddress } }
    //             const referredCheckResult = await CheckReferred(Payload11);

    //             if (referredCheckResult.status || localStorage.getItem("StatusMaintainRef")) {
    //                 if (!referralID && Wallet_Details.NotRefered) {
    //                     handleToastUpdate(id, "Enter Referral code", "error");
    //                     return;
    //                 }
    //                 const PayloadRef = { user: { curraddress: localStorage.getItem("ReferralID") } }
    //                 const checkRefResult = await CheckReferalCode(PayloadRef);

    //                 if (checkRefResult.status) {
    //                     const sendData = { addr: accountDetails.accountAddress, Referral: referralID };
    //                     const userSaveResult = await GetOrSave_Action(sendData);

    //                     if (userSaveResult?.data?.status) {
    //                         dispatchAccountConnect(accountDetails, userSaveResult.data);
    //                         handleToastUpdate(id, "Wallet Connected Successfully", "success");
    //                         localStorage.removeItem("ReferralID");
    //                         localStorage.removeItem("StatusMaintainRef");
    //                         setWalletmodals(false);
    //                         return;
    //                     }
    //                 } else {
    //                     handleInvalidReferralCode(id);
    //                     return;
    //                 }
    //             } else {
    //                 handleToastUpdate(id, "You Don't Have Any referral. Enter Admin Referral Code", "error");
    //                 localStorage.setItem("StatusMaintainRef", false);
    //                 dispatchNotRefered();
    //                 return;
    //             }
    //         }

    //         handleToastUpdate(id, "Failed to Connect Wallet", "error");
    //         setWalletmodals(false);
    //     } catch (err) {
    //         handleToastUpdate(id, "Failed to Connect Wallet", "error");
    //         setWalletmodals(false);
    //     }
    // }

    // function getToastText(type) {
    //     switch (type) {
    //         case 'MetaMask':
    //             return "Unlock MetaMask & Refresh Page";
    //         case 'Coinbase':
    //             return "Coinbase Wallet Checking";
    //         default:
    //             return "Wallet Connect Checking";
    //     }
    // }

    // function handleToastUpdate(id, render, type) {
    //     toast.update(id, {
    //         render,
    //         type,
    //         isLoading: false,
    //         autoClose: 2500,
    //         closeButton: true,
    //         theme: "dark"
    //     });
    // }

    // function handleInvalidReferralCode(id) {
    //     handleToastUpdate(id, "Enter Valid Referral Code", "error");
    //     localStorage.removeItem("ReferralID");
    //     localStorage.setItem("StatusMaintainRef", false);
    //     dispatchNotRefered();
    // }

    // function dispatchNotRefered() {
    //     dispatch({
    //         type: Account_Connect,
    //         Account_Detail: { NotRefered: true }
    //     });
    // }

    // function dispatchAccountConnect(accountDetails, UserSave) {
    //     console.log("accountDetails, UserSave", accountDetails, UserSave);
    //     dispatch({
    //         type: Account_Connect,
    //         Account_Detail: {
    //             // UserAccountAddr: accountDetails.accountAddress,
    //             // UserAccountBal: accountDetails.coinBalance,
    //             // TokenBalance: accountDetails.tokenBalance,
    //             // WalletConnected: "true",
    //             // Web3: accountDetails.web3,
    //             // provider: accountDetails.web3.providers,
    //             // User: userData.User,
    //             // UserLevels: userData.LevelUser,
    //             // RefDetails: userData.RefDetails,
    //             // HowRefered: userData.HowRefered,
    //             // AllUsers: userData.AllUser,
    //             // NotRefered: false
    //             UserAccountAddr: accountDetails?.accountAddress,
    //             UserAccountBal: accountDetails?.coinBalance,
    //             TokenBalance: accountDetails?.tokenBalance,
    //             WalletConnected: "true",
    //             Web3: accountDetails?.web3,
    //             provider: accountDetails?.web3?.providers,
    //             User: UserSave?.data?.User,
    //             UserLevels: UserSave?.data?.LevelUser,
    //             RefDetails: UserSave?.data?.RefDetails,
    //             HowRefered: UserSave?.data?.HowRefered,
    //             AllUsers: UserSave?.data?.AllUser,
    //             NotRefered: false
    //         }
    //     });
    // }


    const Onfunchange = async (e) => {
        localStorage.setItem('ReferralID', e.target.value)
        localStorage.setItem('StatusMaintainRef', true)
    }
    return (
        <>

            <div className="walletlist">
                <Modal
                    className="walletmodal"
                    onHide={props.onDismiss}
                    show={Walletmodals}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Header closeButton onClick={props.onDismiss}></Modal.Header>
                    <Modal.Body className="pb-5">
                        <h1 className="mainheading">Connect your wallet</h1>
                        <p className="subtext text-center">Connect with one of available wallet providers</p>


                        {Wallet_Details.NotRefered &&
                            <div className="marketplace_section pb-2">
                                <p className="subtext text-center mb-2 pt-3">You don't have any referral code .Enter admin referral code</p>
                                <div className="row mx-0">
                                    <div className="col-lg-6 mt-3 mb-3">
                                        <p className='text-left inputname namess mb-2'>Enter the referral code</p>
                                        <input type="text" id="text" class="form-control" placeholder="Referral Code" onChange={(e) => { Onfunchange(e) }} />
                                    </div>
                                    <div className="col-lg-6 mt-3 mb-3">
                                        <p className='text-left inputname namess mb-2'>Admin Referral code</p>
                                        <CopyToClipboard text={Config.AdminReferralKey}
                                            onCopy={() =>
                                                toast.success("Copied Successfully", {
                                                    closeButton: true,
                                                    autoClose: 1500,
                                                    theme: "dark",
                                                })
                                            }
                                        >
                                            <div className='text-start d-flex align-items-center'><p className='bluclr mb-0 me-2'>{Config.AdminReferralKey}</p><FiCopy className='ficpoy' /></div>
                                        </CopyToClipboard>
                                    </div>
                                </div>
                            </div>
                        }


                        <div className="row justify-content-center px-3">
                            <div className="col-lg-4 col-md-6 col-sm-6 mt-4" onClick={() => initialConnectWallet("MetaMask")}>
                                <div className="walletborder text-center py-3 px-3">
                                    <h4 className="mainheading">Metamask</h4>
                                    <p className="subtext">Connect to your
                                        metamask wallet</p>
                                    <img className="metamask img-fluid" src={Images.metamask} alt="metamask" />
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6 mt-4" onClick={() => initialConnectWallet("Coinbase", "register")}>
                                <div className="walletborder text-center py-3 px-3">
                                    <h4 className="mainheading">Coinbase</h4>
                                    <p className="subtext">Connect to your Coinbase wallet</p>
                                    <img className="metamask img-fluid" src={Images.coinbase} alt="metamask" />
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6 mt-4" onClick={() => initialConnectWallet("WalletConnect", "register")}>
                                <div className="walletborder text-center py-3 px-3">
                                    <h4 className="mainheading">WalletConnect</h4>
                                    <p className="subtext">Connect to your Walletconnect account</p>
                                    <img className="metamask img-fluid" src={Images.wallcon} alt="metamask" />
                                </div>
                            </div>
                        </div>

                    </Modal.Body>

                </Modal>

            </div>

        </>
    );
}