import React, { useState, useEffect } from 'react';
import { Button, Container } from 'react-bootstrap';
import Header from "../Layouts/header";
import Footer from "../Layouts/footer";
import Images from '../Components/Images';
import { Link, useLocation } from 'react-router-dom';
import Nftcardpage from '../Components/nftcard';
import { GetNFTs, GetAdminDetails } from '../Action/Controller/Token';
import Buymodals from '../Components/Modals/buymodal';
import Config from '../Config/Config'
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';

const Marketplacedetail = () => {
    const location = useLocation();
    const Wallet_Details = useSelector((state) => state.wallet_connect_context);
    const [buymodal, setBuymodal] = useState(false);
    const [NFTDet, SetNFTDet] = useState([])
    const [NFTCreatorDet, SetNFTCreatorDet] = useState({})
    let state = location.state;
    console.log("statelocationlocation", state, location);
    const [accouninfo, setAccountinfo] = useState([
        {
            desc: 'Lorem Ipsum',
            number: '1.22 ETH',
            name: 'Jack Wilson',
            subtext: 'Bored Ape',
            image: Images.eth,
            profileimg: Images.profile,
            nftimg: Images.monkey,
        },
        {
            desc: 'Lorem Ipsum',
            number: '1.22 ETH',
            name: 'Jack Wilson',
            subtext: 'Bored Ape',
            image: Images.eth,
            profileimg: Images.profile,
            nftimg: Images.monkey,
        },
        {
            desc: 'Lorem Ipsum',
            number: '1.22 ETH',
            name: 'Jack Wilson',
            subtext: 'Bored Ape',
            image: Images.eth,
            profileimg: Images.profile,
            nftimg: Images.monkey,
        },
        {
            desc: 'Lorem Ipsum',
            number: '1.22 ETH',
            name: 'Jack Wilson',
            subtext: 'Bored Ape',
            image: Images.eth,
            profileimg: Images.profile,
            nftimg: Images.monkey,
        },
        {
            desc: 'Lorem Ipsum',
            number: '1.22 ETH',
            name: 'Jack Wilson',
            subtext: 'Bored Ape',
            image: Images.eth,
            profileimg: Images.profile,
            nftimg: Images.monkey,
        },
        {
            desc: 'Lorem Ipsum',
            number: '1.22 ETH',
            name: 'Jack Wilson',
            subtext: 'Bored Ape',
            image: Images.eth,
            profileimg: Images.profile,
            nftimg: Images.monkey,
        },
        {
            desc: 'Lorem Ipsum',
            number: '1.22 ETH',
            name: 'Jack Wilson',
            subtext: 'Bored Ape',
            image: Images.eth,
            profileimg: Images.profile,
            nftimg: Images.monkey,
        },
        {
            desc: 'Lorem Ipsum',
            number: '1.22 ETH',
            name: 'Jack Wilson',
            subtext: 'Bored Ape',
            image: Images.eth,
            profileimg: Images.profile,
            nftimg: Images.monkey,
        },
    ]
    )

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }, []);

    useEffect(() => {
        FetchNFTsData()
        GetAdminsDet()
    }, []);

    const FetchNFTsData = async () => {
        console.log("FetchNFTsData");
        var Response = await GetNFTs()
        console.log("GetNFTs", Response?.Details);
        if (Response.status) {
            SetNFTDet(Response?.Details)
        }
    }

    const GetAdminsDet = async () => {
        var Response = await GetAdminDetails()
        console.log("Response_Details_NFTOwner", Response);
        if (Response.status) {
            console.log("Response?.UserDet", Response?.UserDet);
            SetNFTCreatorDet(Response?.UserDet)
        }
    }



    return (
        <>
            {console.log("dfcaddasdasdasda", buymodal, state)}
            {buymodal && <Buymodals onDismiss={() => setBuymodal(false)} state={state} />}

            <div className='marketplacedetail animehead'>

                <Header />

                <div className='marketplacedetail_sec firsttop pb-5'>
                    <span className='violentgrad'></span>
                    <img src={Images.detailleftarrow} className='detailleftarrow' />
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-6 text-center mt-4'>
                                <img src={state?.image} className='img-fluid heightlist' />
                            </div>
                            <div className='col-lg-6 mt-4'>
                                {console.log("NFTCreatorDet", NFTCreatorDet)}
                                <h2 className='text-start mainheading pb-3'>{state?.tokenName}</h2>
                                <p className='subtext'>Created by</p>
                                <div className='d-flex align-items-center'><img src={NFTCreatorDet?.image ? `${Config?.Back_URL}/Users/${NFTCreatorDet?.curraddress}/profileImg/${NFTCreatorDet?.image}` : Images.dashprofimg} className='profimg img-fluid me-2' /> <p className='subtext mb-0'>{NFTCreatorDet?.name ? NFTCreatorDet?.name : NFTCreatorDet?.curraddress?.slice(0, 14)}</p></div>
                                <p className='subtext mt-4'>{state?.tokenDesc}</p>
                                {console.log("state?.tokenOwner !== Config.AdminAddress", state?.tokenOwner, Wallet_Details?.UserAccountAddr)}
                                {state?.tokenOwner !== Wallet_Details?.UserAccountAddr &&
                                    < div className='mt-4 nftbtn position-relative'>
                                        <img src={Images.buyntnbtn} className='buyntnbtn img-fluid' />
                                        <h4 className='text-start mainheading buytextnft' onClick={() => {
                                            if (Wallet_Details.UserAccountAddr) {
                                                setBuymodal(true)

                                            } else {
                                                return toast.error("Connect Your Wallet")
                                            }
                                        }
                                        }>Buy NFT</h4>
                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                </div>


                <div className='marketplacedetail_sec pt-4 pb-5'>
                    <img src={Images.detailbottomarrow} className='detailbottomarrow' />
                    <img src={Images.lefttop} className='curve lefttop' />
                    <img src={Images.righttop} className='curve righttop' />
                    <img src={Images.circle} className='circle' />
                    <img src={Images.circle} className='circle1' />
                    <img src={Images.circle} className='circle2' />
                    <span class="yellowgradient1"></span>
                    <span class="blugradient"></span>
                    <div className='container pb-lg-5'>
                        <h2 className='text-start mainheading pb-1'>More from this artist</h2>
                        <div className='row'>
                            {NFTDet.map((NFT) => (
                                <>
                                    {
                                        NFT?.tokenID !== state?.tokenID &&
                                        <div className='col-sm-6 col-lg-4 col-xl-3 mt-5'>
                                            <Link state={NFT} to="/marketplacedetail" onClick={()=>{window.scrollTo({top: 0, left: 0, behavior: 'smooth' })}}><Nftcardpage datas={NFT} /></Link>
                                        </div>
                                    }
                                </>
                            ))}
                        </div>
                    </div>
                </div>



                <Footer />

            </div >

        </>
    )
}

export default Marketplacedetail;