import React from 'react';
import Images from './Images';
import Config from "../Config/Config"
// toggleNode
import { useSelector } from "react-redux";

const CustomNode = ({ nodeDatum, toggleNode, foreignObjectProps = {} }) => {
  const imageUrl = nodeDatum.attributes && nodeDatum.attributes.imageUrl;
  const Wallet_Details = useSelector((state) => state.wallet_connect_context);
  console.log("nodeDatum", nodeDatum, Config.AdminAddress, Wallet_Details);
  return (
    <g onClick={toggleNode}>
      {/* <image
        src={Images.blogimg}
        x="-24" // Adjust as needed to center the image
        y="-24" // Adjust as needed to center the image
        height="48px" // Specify the size of the image
        width="48px" // Specify the size of the image
        // onError={(e) => { e.target.onerror = null; e.target.href = '/path/to/fallback/image.png'; }} // Fallback image
      /> */}
      <foreignObject x="-80" y="-105" width={80} height={80} {...foreignObjectProps}>
        <img src={Images.personimg} height="80" width="80" />
        {/* <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="#79CCCA"  class="bi bi-person-circle" viewBox="0 0 16 16" > <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" /> <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/> </svg> */}
      </foreignObject>
      <rect r="15" width="150" height={Wallet_Details?.UserAccountAddr == Config.AdminAddress ? "90" : "65"} x="-110" y="-30" rx="4" ry="4" fill="#1e1e1e" stroke="#FF5C00" strokeWidth="1"
      // onClick={toggleNode}
      />
      <text fill="#fff" stroke="#fff" strokeWidth="0.1" x="-38" y="-10" textAnchor="middle" alignmentBaseline="middle" style={{ fontSize: "13px", fontWeight: '200' }}>
        {nodeDatum?.name ? nodeDatum?.name : nodeDatum?.curraddress?.slice(0, 10)}
      </text>

      =
      {nodeDatum?.Referral && (
        <text fill="#fff" stroke="#fff" x="-38" y="15" strokeWidth="0.1" textAnchor="middle" alignmentBaseline="middle" style={{ fontSize: "14px", fontWeight: '400' }}>
          {nodeDatum?.Referral}
        </text>
      )}
      {Wallet_Details?.UserAccountAddr == Config.AdminAddress &&
        <text fill="#fff" stroke="#fff" strokeWidth="0.1" x="-38" y="40" textAnchor="middle" alignmentBaseline="middle" style={{ fontSize: "13px", fontWeight: '400' }}>
          {nodeDatum?.curraddress == Config.AdminAddress ? "" : nodeDatum?.Invesment}
        </text>
      }

    </g>




  );
};

export default CustomNode;
