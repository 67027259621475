import React, { useState, useEffect } from 'react';
import { Button, Container } from 'react-bootstrap';
import Header from "../Layouts/header";
import Headermobile from "../Layouts/headermobile";
import Footer from "../Layouts/footer";
import TreeGraph from '../Components/treegraph';
import { IoMdArrowRoundBack } from "react-icons/io";
import { Link } from 'react-router-dom';


const Extendgraph = () => {

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
     }, []);


    return (        
        <>
          
          <div className='extendgraph'>
          <div className='container-fluid'>
         <Link to="/dashboard"><h4 className='text-start mainheading ps-2 pt-4'><IoMdArrowRoundBack/> Back</h4></Link>
          <TreeGraph/>
          </div>
          </div>

        </>
    )
}

export default Extendgraph;