import React, { useState, useEffect } from 'react';
import { Button, Container, Accordion } from 'react-bootstrap';
import Header from "../Layouts/header";
import Footer from "../Layouts/footer";
import Images from '../Components/Images';
import { getCmsContent, getfaqlist } from '../Action/Axios/cmsaxios';


const Faq = () => {
const [faqtopdata,setfaqtopdata] = useState([])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
        Getfaqlist();
        Getandsellnft();
    }, []);
    const Getfaqlist = async () => {
        let resp = await getfaqlist()
        console.log("roadssssfaq", resp?.data);
        if (resp?.status) SetValuess(resp?.data)
    }
    const Getandsellnft = async () => {
        var resp = await getCmsContent("faqtop");
        console.log("faqtop", resp.data);
        if (resp?.status) setfaqtopdata(resp?.data);
    };
    const [valuess, SetValuess] = useState([
        { id: 1,que:"1. What is PolyNyx?",ans:" PolyNyx is a groundbreaking online platform built on Blockchain technology, offering an open-source Smart contract MLM structure tailored for NFT enthusiasts. Inspired by the primordial deities literature, PolyNyx merges the realms of Polygon and the goddess Nyx to create a dynamic space for exploring, creating, and trading NFTs." }, 
        { id: 2,que:"2. How does PolyNyx work?",ans:" PolyNyx operates on a decentralized Blockchain network, ensuring transparency, security, and immutability. Through our open-source Smart contract MLM structure, users can securely trade, collect, and generate NFTs, leveraging the power of Blockchain technology to facilitate seamless transactions and interactions." }, 
        { id: 3,que:"3. What sets PolyNyx apart from other NFT platforms?",ans:" PolyNyx stands out for its innovative approach to NFTs, offering a unique blend of generative art, immersive gaming experiences, and passive income opportunities. Our platform prioritizes safety, security, and reliability, providing users with a dynamic ecosystem where creativity thrives and imagination knows no bounds." }, 
        { id: 4,que:"4. How can I earn passive income on PolyNyx?",ans:" PolyNyx offers users various avenues to earn passive income. Through our open-source Smart contract MLM structure, users can participate in the PolyNyx community and earn rewards for activities such as trading, collecting, and engaging with NFTs. Additionally, users can leverage our pixel art gaming quests and other features to unlock additional income streams." }, 
        { id: 5,que:"5. Is my data and assets secure on PolyNyx",ans:" Yes, PolyNyx prioritizes the safety and security of users' data and assets. Our platform employs robust encryption protocols and security measures to protect against unauthorized access and malicious activities. Additionally, transactions on PolyNyx are recorded on the Blockchain, ensuring transparency, immutability, and authenticity." }, 
        { id: 6,que:"6. Can I create my own NFTs on PolyNyx?",ans:" Yes, PolyNyx empowers users to create their own NFTs and unleash their creativity. Our platform offers tools and resources for users to mint, customize, and trade their NFT creations, providing a platform for artists, creators, and collectors to showcase their talents and share their unique visions with the world." },
        { id: 7,que:"7. How do I get started on PolyNyx?",ans:" Getting started on PolyNyx is easy. Users can easily create an account by connecting their wallet through WalletConnect, gaining access to a world of NFTs, gaming quests, and community engagement. Explore our marketplace, participate in trading, and join our vibrant community of NFT enthusiasts to start your PolyNyx journey today." },
        { id: 8,que:"8. What is the significance of the name PolyNyx?",ans:" The name PolyNyx is derived from the fusion of Polygon, a leading Blockchain network known for its scalability and efficiency, and Nyx, the mythical Greek goddess symbolizing the night and creativity. This inspired fusion reflects our commitment to innovation and limitless possibilities, offering users a gateway to explore new realms of imagination within the NFT world." },
        { id: 9,que:"9. How can I participate in PolyNyx gaming quests?",ans:" Participating in PolyNyx gaming quests is simple. Our platform offers a range of pixel art gaming experiences inspired by the primordial deities literature. Simply navigate to the gaming section of our platform, select a quest, and embark on an immersive journey through captivating game scenarios, where every move brings you closer to unlocking new NFT treasures." },
        { id: 10,que:"10. What support and resources does PolyNyx provide to its users?",ans:" PolyNyx is committed to providing comprehensive support and resources to its users. Our platform offers user-friendly interfaces, detailed documentation, and responsive customer support to assist users at every step of their NFT journey. Additionally, our vibrant community provides a space for users to connect, share insights, and collaborate on projects, fostering a culture of creativity, collaboration, and exploration." },
    ]);

    return (
        <>

            <div className='faqpage animehead'>

                <Header />

                {/* <div className='h-100vh'>
                      <h1 className='mainheading'>Coming Soon...</h1>
                </div> */}

                <div className='marketplacedetail_sec firsttop pb-5'>
                    <span className='violentgrad'></span>
                    <span class="blugradient"></span>
                    <img src={Images.detailleftarrow} className='detailleftarrow' />
                    <div className='container pt-4'>
                        <h2 className='text-start mainheading pb-3'>{faqtopdata.heading}</h2>
                        <p className='subtext mt-4'><div dangerouslySetInnerHTML={{
                                        __html: faqtopdata?.description,
                                    }}></div>
                        </p>

                    </div>
                </div>

                <div className='marketplacedetail_sec accord_sec pt-4'>
                    <img src={Images.lefttop} className='curve lefttop' />
                    <img src={Images.righttop} className='curve righttop' />
                    <img src={Images.circle} className='circle' />
                   <img src={Images.circle} className='circle1' />
                    <img src={Images.circle} className='circle2' />
                    <span class="yellowgradient1"></span>
                    <span className='yellowgradient2'></span>
                    <div className='container'>
                        <h1 className='mainheading pb-3'><img src={Images.headingblink} className='headingblink' /> FAQ</h1>

                        <div className='pbs px-3 px-md-4 px-lg-5 px-xl-5'>
                            <Accordion defaultActiveKey="0">
                                {valuess.map((e,i) =>
                                <Accordion.Item eventKey={i}>
                                    <Accordion.Header><h5 className='text-start mainheading mb-0'>{e.question}</h5></Accordion.Header>
                                    <Accordion.Body className='py-2 py-md-5 px-3 px-md-4'>
                                        <p className='subtext fw-200 mt-3'>
                                        <div dangerouslySetInnerHTML={{
                                            __html: e.answer,
                                        }}></div>
                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                )}
                                {/* <Accordion.Item eventKey="1">
                                    <Accordion.Header><h5 className='text-start mainheading mb-0'>2. How does PolyNyx work?</h5></Accordion.Header>
                                    <Accordion.Body >
                                        <p className='subtext fw-200 mt-3'>
                                        PolyNyx operates on a decentralized Blockchain network, ensuring transparency, security, and immutability. Through our open-source Smart contract MLM structure, users can securely trade, collect, and generate NFTs, leveraging the power of Blockchain technology to facilitate seamless transactions and interactions.
                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header><h5 className='text-start mainheading mb-0'>3. What sets PolyNyx apart from other NFT platforms?</h5></Accordion.Header>
                                    <Accordion.Body>
                                        <p className='subtext fw-200 mt-3'>
                                        PolyNyx stands out for its innovative approach to NFTs, offering a unique blend of generative art, immersive gaming experiences, and passive income opportunities. Our platform prioritizes safety, security, and reliability, providing users with a dynamic ecosystem where creativity thrives and imagination knows no bounds.
                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header><h5 className='text-start mainheading mb-0'>4. How can I earn passive income on PolyNyx?</h5></Accordion.Header>
                                    <Accordion.Body>
                                        <p className='subtext fw-200 mt-3'>
                                        PolyNyx offers users various avenues to earn passive income. Through our open-source Smart contract MLM structure, users can participate in the PolyNyx community and earn rewards for activities such as trading, collecting, and engaging with NFTs. Additionally, users can leverage our pixel art gaming quests and other features to unlock additional income streams.
                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header><h5 className='text-start mainheading mb-0'>5. Is my data and assets secure on PolyNyx</h5></Accordion.Header>
                                    <Accordion.Body className='py-5 px-4'>
                                        <p className='subtext fw-200 mt-3'>
                                        Yes, PolyNyx prioritizes the safety and security of users' data and assets. Our platform employs robust encryption protocols and security measures to protect against unauthorized access and malicious activities. Additionally, transactions on PolyNyx are recorded on the Blockchain, ensuring transparency, immutability, and authenticity.
                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="5">
                                    <Accordion.Header><h5 className='text-start mainheading mb-0'>6. Can I create my own NFTs on PolyNyx?</h5></Accordion.Header>
                                    <Accordion.Body className='py-5 px-4'>
                                        <p className='subtext fw-200 mt-3'>
                                        Yes, PolyNyx empowers users to create their own NFTs and unleash their creativity. Our platform offers tools and resources for users to mint, customize, and trade their NFT creations, providing a platform for artists, creators, and collectors to showcase their talents and share their unique visions with the world.
                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="6">
                                    <Accordion.Header><h5 className='text-start mainheading mb-0'>7. How do I get started on PolyNyx?</h5></Accordion.Header>
                                    <Accordion.Body className='py-5 px-4'>
                                        <p className='subtext fw-200 mt-3'>
                                        Getting started on PolyNyx is easy. Users can easily create an account by connecting their wallet through WalletConnect, gaining access to a world of NFTs, gaming quests, and community engagement. Explore our marketplace, participate in trading, and join our vibrant community of NFT enthusiasts to start your PolyNyx journey today.

                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="7">
                                    <Accordion.Header><h5 className='text-start mainheading mb-0'>8. What is the significance of the name PolyNyx?</h5></Accordion.Header>
                                    <Accordion.Body className='py-5 px-4'>
                                        <p className='subtext fw-200 mt-3'>
                                        The name PolyNyx is derived from the fusion of Polygon, a leading Blockchain network known for its scalability and efficiency, and Nyx, the mythical Greek goddess symbolizing the night and creativity. This inspired fusion reflects our commitment to innovation and limitless possibilities, offering users a gateway to explore new realms of imagination within the NFT world.
                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="8">
                                    <Accordion.Header><h5 className='text-start mainheading mb-0'>9. How can I participate in PolyNyx gaming quests?</h5></Accordion.Header>
                                    <Accordion.Body className='py-5 px-4'>
                                        <p className='subtext fw-200 mt-3'>
                                        Participating in PolyNyx gaming quests is simple. Our platform offers a range of pixel art gaming experiences inspired by the primordial deities literature. Simply navigate to the gaming section of our platform, select a quest, and embark on an immersive journey through captivating game scenarios, where every move brings you closer to unlocking new NFT treasures.
                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="9">
                                    <Accordion.Header><h5 className='text-start mainheading mb-0'>10. What support and resources does PolyNyx provide to its users?</h5></Accordion.Header>
                                    <Accordion.Body className='py-5 px-4'>
                                        <p className='subtext fw-200 mt-3'>
                                        PolyNyx is committed to providing comprehensive support and resources to its users. Our platform offers user-friendly interfaces, detailed documentation, and responsive customer support to assist users at every step of their NFT journey. Additionally, our vibrant community provides a space for users to connect, share insights, and collaborate on projects, fostering a culture of creativity, collaboration, and exploration.
                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item> */}
                            </Accordion>
                        </div>
                    </div>


                </div>

                <Footer />

            </div>

        </>
    )
}

export default Faq;