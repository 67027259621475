import React, { useState, useEffect, useRef } from 'react';
import { Button, Container, Nav, Navbar, Offcanvas } from 'react-bootstrap';
import Images from '../Components/Images';
import $ from "jquery";
import Walletmodal from '../Components/Modals/walletmodal';
import { Link, NavLink } from 'react-router-dom';
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from "react-redux";
import { Account_Connect, Account_disConnect } from "../Redux/action";
import { connectWallet } from "../Action/Hooks/useWallet";
import Config from "../Config/Config"
import { GetOrSave_Action } from '../Action/Controller/Token'

const Header = () => {

    const dispatch = useDispatch();
    const timerRef = useRef(null);
    const Wallet_Details = useSelector((state) => state.wallet_connect_context);

    const [Walletmodals, setWalletmodals] = useState(false)

    // useEffect(() => {
    //     if (localStorage.walletConnectType && localStorage.walletConnectType != null && localStorage.walletConnectType == "MetaMask") {
    //         walletConnect("MetaMask", "register");
    //     } else if (localStorage.walletConnectType && localStorage.walletConnectType == "WalletConnect" && localStorage.walletConnectType != null) {
    //         console.log("elseeeee3");
    //         walletConnect("WalletConnect", "register");
    //     } else if (localStorage.walletConnectType && localStorage.walletConnectType == "Coinbase" && localStorage.walletConnectType != null) {
    //         console.log("elseeeee3");
    //         walletConnect("Coinbase", "register");
    //     }
    // }, [Wallet_Details.UserAccountAddr]);

    const Disconnect = async () => {
        toast.success("Wallet Disconnected", { autoClose: 3000, theme: "dark" });
        dispatch({
            type: Account_disConnect,
            Account_Disconnect: {
                UserAccountAddr: "",
                UserAccountBal: 0,
                provider: null,
                Service_Fee: 0,
                Accounts: "",
                Web3: null,
                WalletConnected: "",
                AddressUserDetails: null,
                tokenAddress: null,
            },
        });
        if (localStorage.walletconnect != null && localStorage.walletConnectType == "WalletConnect") {
            await Wallet_Details.provider.disconnect();
            localStorage.removeItem("walletconnect");
        }
        localStorage.removeItem("walletConnectType");
        localStorage.removeItem("accountInfo");
        localStorage.removeItem("user_token");
        setWalletmodals(false)
    };

    // window.addEventListener("load", async (event) => {
    //     console.log("loaddd");
    //     if (localStorage.getItem("walletConnectType") == "MetaMask" || localStorage.walletConnectType == "Coinbase") {
    //         if (window.ethereum) {
    //             // console.log("tjghjfhio", window.ethereum);
    //             window.ethereum.on("accountsChanged", function (accounts) {
    //                 if (timerRef.current) {
    //                     clearTimeout(timerRef.current);
    //                 }
    //                 timerRef.current = setTimeout(() => {
    //                     walletConnect("MetaMask");
    //                 }, 1000);
    //             });

    //             window.ethereum.on("chainChanged", async function (networkId) {
    //                 //console.log("chainid",networkId)
    //                 // if (networkId == config.chainId) {
    //                 if (networkId) {
    //                     if (timerRef.current) {
    //                         clearTimeout(timerRef.current);
    //                     }
    //                     timerRef.current = setTimeout(() => {
    //                         walletConnect("MetaMask");
    //                     }, 1000);
    //                 } else {
    //                     dispatch({
    //                         type: Account_disConnect,
    //                     });
    //                 }
    //             });
    //         }
    //     } else if (localStorage.walletConnectType == "WalletConnect") {
    //         var provider3 = null;
    //         if (provider3 == null) {
    //             provider3 = await walletConnect("WalletConnect");
    //         } else if (provider3 != null) {
    //             provider3.on("connect", () => {
    //                 walletConnect("WalletConnect");
    //             });
    //             provider3.on("disconnect", () => {
    //                 localStorage.removeItem("walletConnectType");
    //             });
    //         }
    //     }
    // });

    // const walletConnect = async (type, register) => {
    //     var toasttxt = null
    //     try {
    //         var accountDetails = await connectWallet(type, Config.ChainID, register)
    //         if (accountDetails && accountDetails?.accountAddress !== undefined) {
    //             if (accountDetails != '') {
    //                 const IDcheck = await accountDetails?.web3?.eth?.getChainId();
    //                 console.log("ChainID Checking", IDcheck);
    //                 let SendData = {
    //                     addr: accountDetails?.accountAddress,
    //                     Referral: localStorage.getItem("ReferralID") ? localStorage.getItem("ReferralID") : null
    //                     //Config.AdminReferralKey
    //                 }
    //                 var UserSave = await GetOrSave_Action(SendData)
    //                 console.log("UserSave", UserSave);
    //                 if (UserSave?.data?.status) {
    //                     dispatch({
    //                         type: Account_Connect,
    //                         Account_Detail: {
    //                             UserAccountAddr: accountDetails?.accountAddress,
    //                             UserAccountBal: accountDetails?.coinBalance,
    //                             WalletConnected: "true",
    //                             Web3: accountDetails?.web3,
    //                             provider: accountDetails?.web3?.providers,
    //                             User: UserSave?.data?.User
    //                         }
    //                     })
    //                 } else {
    //                     dispatch({
    //                         type: Account_Connect,
    //                         Account_Detail: {
    //                             UserAccountAddr: accountDetails?.accountAddress,
    //                             UserAccountBal: accountDetails?.coinBalance,
    //                             WalletConnected: "true",
    //                             Web3: accountDetails?.web3,
    //                             provider: accountDetails?.web3?.providers,
    //                         }
    //                     })
    //                 }
    //             }
    //         } else {
    //             toast.error("Failed to Connect Wallet");
    //         }
    //     } catch (err) {
    //         toast.error("Failed to Connect Wallet");
    //     }
    // };

    return (
        <>
            {Walletmodals && <Walletmodal onDismiss={() => setWalletmodals(false)} />}

            <div className='px-3 px-md-5'>

                <div className='d-flex py-3 align-items-center justify-content-between'>
                    <Link to=""><img src={Images.logo} className='img-fluid logo logomobile' alt='images' /></Link>

                    {Wallet_Details.UserAccountAddr == "" || Wallet_Details.UserAccountAddr == undefined ?
                        <p onClick={() => setWalletmodals(true)} className='whtclr wallet mb-0'>Wallet Connect</p>
                        :
                        <p onClick={() => Disconnect()} className='whtclr wallet mb-0'>Wallet Connect</p>
                    }
                </div>

                <div className='px-1 mt-2'>
                    <ul className='headnav'>
                        <li><NavLink to="/marketplace" activeClassName="active">Marketplace</NavLink></li>
                        <li><NavLink to="/dashboard" activeClassName="active">Dashboard</NavLink></li>
                        <li><NavLink to="/faq" activeClassName="active">FAQ</NavLink></li>
                    </ul>
                </div>

                <div>

                </div>

            </div>

        </>
    )
}

export default Header;