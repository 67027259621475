import React, { useState, useEffect } from 'react';
import { Button, Container } from 'react-bootstrap';
import Images from '../Components/Images';
import { FaXTwitter } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import { isEmpty } from "../Action/Helper/commom";
import { toast } from "react-toastify";
import { Newsletter, getSociallink } from '../Action/Axios/cmsaxios';
import config from '../Config/Config'
const Footer = () => {
const [links,setlinks] = useState([])
const [email,setEmail] = useState('');
console.log("gfggggg",email);
const [Error,SetError] = useState({});

useEffect(()=>{
    Getandsellnft();
},[])
const Getandsellnft = async () => {

    var resp = await getSociallink();
    console.log("setlinks",resp?.data)
    if (resp?.status)
    setlinks(resp.data);
}

const NewsLetter = async() =>{
    const id = toast.loading("Subscribing...");
    
    let err = {};
    if(email=='') err.email="Email Id Required";
    if(email && !(config.EMAIL).test(email)) err.email = 'Invalid Email ID Format';
    SetError(err);
    // console.log('fhbdfhbdf',email)
    if(isEmpty(err)){
        let resp = await Newsletter({email : email});
        console.log("Newletter",resp )
        if(resp.status === true){
            toast.update(id ,{render:"Successfully Subscribed", type: "success", isLoading: false, autoClose: 1000,closeButton:true,closeOnClick:true})
            setEmail('');
        }
        else{
            toast.update(id ,{ render : "Already User", type :'error', isLoading: false, autoClose: 1000,closeButton:true,closeOnClick:true})
            SetError({email:resp.msg});
        }
    }
    else{
        toast.update(id ,{ render : err.email, type :'error', isLoading: false, autoClose: 1000,closeButton:true,closeOnClick:true})
    }
}
    return (
        <>

            <div className='footerall pt-5 pb-4'>
                <div className='container'>
                    <div className='row pt-0 pt-md-5 bb align-items-center'>
                        <div className='col-md-8 col-lg-6  mt-3'>
                            <h2 className='mainheading text-center text-md-start'>Subscribe to our Newsletter!</h2>
                            <p className='subtext'>Subscribe to our newsletter and stay updated</p>
                         <div className='d-flex subscribeinput pt-3'>
                         <div className='subsall' onClick={NewsLetter} style={{ cursor:"pointer" }}>
                            <h5 className='subtag mb-0'>
                            {/* <button className="tf-button" type="button" id="subscribe-button" onClick={NewsLetter}>
                                     Subscribe
                                        </button>   */}
                                        Subscribe
                            </h5>
                            <img src={Images.subs} className='img-fluid'/>   
                                         
                         </div>
                         <input type='email' placeholder="Enter your email" value={''||email} onChange={(event)=>{setEmail(event.target.value)}} required="" id="subscribe-email" className='form-control' autoComplete="off"/>
                         </div>
                        </div>
                        <div className='col-md-4 col-lg-2 mt-3 text-center'>
                            <ul>
                                <li><Link to="/about">About</Link></li>
                                <li><Link to="/terms">Terms</Link></li>
                                <li><Link to="/privacy">Privacy</Link></li>
                                {/* <li><Link to="#">Contact</Link></li> */}
                            </ul>
                        </div>
                        <div className='col-lg-4 mt-3 text-center'>
                        <Link to="/"><img src={Images.footerpoly} className='img-fluid footerpoly mb-3'/></Link>
                          <div className='sociala'>
                            {/* <a target='_blank' href='https://twitter.com/'>
                                <img src={Images.twitter}/>
                            </a>
                            <a target='_blank' href='https://discard.com/'>
                                <img src={Images.disc}/>
                            </a>
                            <a target='_blank' href='https://instagram.com/'>
                                <img src={Images.insta}/>
                            </a> */}
                             {links.map((mlink)=>(  
                                       
                                       <>
                                       {
                                          <a href={mlink.link} target="blank">
                                           
                                          <img src={`${config.Image_url}/socialimg/${mlink?.img}`} alt="dd"/>
                                          </a>
                                       }
                                       </>
                                      ))}
                          </div>
                          
                        </div>
                    </div>
                 <p className='mb-0 subtext text-center mt-3'>Copyright @ 2024 Polynyx. All Rights Reserved</p>
                 
                </div>
            </div>


        </>
    )
}

export default Footer;