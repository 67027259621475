import axios  from "axios"
import { data } from "jquery"
import config from '../../Config/Config'
import {Decryptdata  , EncryptData, AppenData } from "../Helper/EncHelper.js"

const jwttoken = localStorage.getItem("token")

export const fetchfaqList = async() =>{
    try{
        let resp = await axios({
          
                'method':'get',
                'url':`${config.Admin_Url}/user/faqlists`,
                "headers":{
                    'Content-Type': 'application/json',
                    "Authorization": jwttoken
                },
        })
        resp.data = await Decryptdata(resp?.data)
        return resp.data;
    }
    catch(err){console.log("err in gettok owner",err)}
}


export const getCmsContent = async (data) => {

    try {
        var resp = await axios({

            'method': 'GET',
            'url': `${config.Admin_Url}/cms/getcmslist`,
            "headers":{
                'Content-Type': 'application/json',
                "Authorization": jwttoken
            },
            params: { data: EncryptData(data) }
        })
        console.log("tokonwer resp", resp.data)
        let response = Decryptdata(resp?.data)
        return response;
    }
    catch (err) { console.log("err in gettok owner", err) }
}

export const getpolynyxlist = async (data) => {

    try {
        var resp = await axios({

            'method': 'GET',
            'url': `${config.Admin_Url}/cms/getpolynyxlist`,
            // params: { data: EncryptData(data) }
        })
        console.log("tokonwer resp", resp.data)
        let response = Decryptdata(resp?.data)
        return response;
    }
    catch (err) { console.log("err in gettok owner", err) }
}

export const getroadmaplist = async() =>{
    console.log("addroadmapaddroadmap");
    try{
        let resp = await axios({
          
                'method':'get',
                'url':`${config.Admin_Url}/cms/getroadmaplist`, 
                "headers":{
                    'Content-Type': 'application/json',
                    "Authorization": jwttoken
                },
                
               
        })
        resp.data = await Decryptdata(resp?.data)
        return resp.data;
    }
    catch(err){console.log("err in gettok owner",err)}
}

export const getfaqlist = async() =>{
    console.log("getfaqlistgetfaqlist");
    try{
        let resp = await axios({
          
                'method':'get',
                'url':`${config.Admin_Url}/cms/getfaqlist`, 
                "headers":{
                    'Content-Type': 'application/json',
                    "Authorization": jwttoken
                },
                
               
        })
        resp.data = await Decryptdata(resp?.data)
        return resp.data;
    }
    catch(err){console.log("err in gettok owner",err)}
}

export const getSociallink = async() =>{
    console.log("getSociallink");
    try{
        let resp = await axios({
          
                'method':'get',
                'url':`${config.Admin_Url}/cms/getsociallink`, 
                "headers":{
                    'Content-Type': 'application/json',
                    "Authorization": jwttoken
                },
                
               
        })
        resp.data = await Decryptdata(resp?.data)
        return resp.data;
    }
    catch(err){console.log("err in gettok owner",err)}
}

export const Newsletter = async(data) =>{
    console.log("emailletter",data);
    console.log("tokensss",localStorage.getItem("token"));
    let formdata = AppenData(data)
    try{
        let resp = await axios({
          
            method: 'POST',
            url: `${config.Admin_Url}/user/newsletter`,
            'headers': {
                "Content-Type": 'application/json',
                "Authorization":localStorage.getItem("token")
            },
            data: formdata[0]
                
               
        })
        resp.data = await Decryptdata(resp?.data)
        return resp.data;
    }
    catch(err){console.log("err in gettok owner",err)}
}