import React from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import "./App.css";
import { Provider } from 'react-redux'
import { store } from './Redux/store'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from "react-toastify";
import "./style.css";
import Homepage from "./Pages/Home";
import Marketplace from "./Pages/Marketplace";
import Faq from "./Pages/faq";
import Dashboard from "./Pages/Dashboard";
import Marketplacedetail from "./Pages/marketplacedetail";
import Mybank from "./Pages/mybank";
import Referral from "./Pages/referral";
import History from "./Pages/history";
import About from "./Pages/about";
import Terms from "./Pages/terms";
import Privacy from "./Pages/privacy";
import Extendgraph from "./Pages/expandgraph";
import Editprofile from "./Pages/profile";
// import TreeDummy from './Pages/TreeDummy'
import Readmore from './Components/Readmore'
import News from "./Pages/news";

function App() {


  return (
    <div className="App">

      <BrowserRouter basename="/" >
        <Provider store={store}>
          <ToastContainer
            closeOnClick={true} />
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/:id" element={<Homepage />} />
            <Route path="/marketplace" element={<Marketplace />} />
            <Route path="/marketplacedetail" element={<Marketplacedetail />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/mybank" element={<Mybank />} />
            <Route path="/referral" element={<Referral />} />
            <Route path="/history" element={<History />} />
            <Route path="/about" element={<About />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/extendgraph" element={<Extendgraph />} />
            <Route path="/editprofile" element={<Editprofile />} />
            {/* <Route path="/TreeDummy" element={<TreeDummy />} /> */}
            <Route path="/Article/:heading" element={<Readmore />} />
            <Route path="/news" element={<News />} />
          </Routes>
        </Provider>
      </BrowserRouter>


    </div>
  );
}

export default App;

