import React, { useState, useEffect } from 'react';
import { Button, Container } from 'react-bootstrap';
import Header from "../Layouts/header";
import Footer from "../Layouts/footer";
import Images from '../Components/Images';
import sample from '../Assets/initialvideo.mp4';
import sample1 from '../Assets/second.mp4';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Nftcardpage from "../Components/nftcard";
import Lottie from "lottie-react";
import { Link, useNavigate, useParams } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Blogcard from '../Components/blogcard';
import { GetNFTs, LevelUpData } from '../Action/Controller/Token';
import Web3 from 'web3';
import config from "../Config/Config"
import Abi from '../Action/ABI/Mint.json'
import { useSelector, useDispatch } from "react-redux";
import { getCmsContent, getfaqlist, getpolynyxlist, getroadmaplist } from '../Action/Axios/cmsaxios';
import { getnewslist } from '../Action/Axios/adminaxios';
import { Account_Connect, Account_disConnect } from "../Redux/action";
const Homepage = () => {
    const { id } = useParams()

    const History = useNavigate()
    const dispatch = useDispatch();
    const Wallet_Details = useSelector((state) => state.wallet_connect_context);
    console.log("Referral id Home page Params", id);
    useEffect(() => {
        EditHomeUrl()
    }, [])
    const EditHomeUrl = () => {
        if (id) {
            localStorage.setItem('ReferralID', id)
            localStorage.setItem('HeaderID', id)
            History('/')
        }
    }
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }, []);

    const [initialload, setInitialload] = useState(true);
    const [NFTDet, SetNFTDet] = useState([])
    const [NFTDetLen, SetNFTDetLen] = useState([])
    const [homecontent, setHomecontent] = useState([]);
    const [homecontentmidd, setHomecontentmidd] = useState([]);
    const [polynyxdata, setpolynyxdata] = useState([])
    const [roadmaplist, setroadmaplist] = useState([])
    const [faqlist, setfaqlist] = useState([])
    console.log("datadeata", polynyxdata)
    useEffect(() => {
        setTimeout(() => {
            setInitialload(false);
        }, 2200);
    }, []);

    // const [loading, setLoading] = useState(true);
    // const spinner = document.getElementById("spinner");
    // var element = document.getElementById("bodystyle");
    // useEffect(() => {
    //     setLoading(false)
    //     setTimeout(() => {
    //         spinner.style.display = "none";
    //         element.classList.remove("bodyname");
    //     }, 1200);
    // })

    const options = {
        items: 6,
        responsiveClass: true,
        loop: false,
        margin: 30,
        autoplay: false,
        dots: false,
        nav: true,
        navText: [`<img src=${Images.leftcaroimg} class='leftcaroimg' />`, ` <img src=${Images.leftcaroimg} class='leftcaroimg leftcaroimg1' />`],
        // rewind: true,
        // slideBy: 4,
        responsive: {
            0: {
                items: 1,
            },
            576: {
                items: 2,
            },
            991: {
                items: 3,
            },
            1199: {
                items: 3,
            },
            1200: {
                items: 4,

            }
        },
    };

    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block", background: "transparent" }}
                onClick={onClick}
            >
                <img src={Images.toparrows} className='img-fluid' />
            </div>

        );
    }
    const settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        speed: 1500,
        arrows: true,
        vertical: true,
        verticalSwiping: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        nextArrow: <SampleNextArrow />,
        responsive: [
            {
                breakpoint: 575,
                settings: {
                    vertical: false,
                }
            }]
    };

    const [value, SetTabName] = useState(0);
    const [valuess, SetValuess] = useState([
        { id: 1, que: "1. What is PolyNyx?", ans: " PolyNyx is a groundbreaking online platform built on Blockchain technology, offering an open-source Smart contract MLM structure tailored for NFT enthusiasts. Inspired by the primordial deities literature, PolyNyx merges the realms of Polygon and the goddess Nyx to create a dynamic space for exploring, creating, and trading NFTs." },
        { id: 2, que: "2. How does PolyNyx work?", ans: " PolyNyx operates on a decentralized Blockchain network, ensuring transparency, security, and immutability. Through our open-source Smart contract MLM structure, users can securely trade, collect, and generate NFTs, leveraging the power of Blockchain technology to facilitate seamless transactions and interactions." },
        { id: 3, que: "3. What sets PolyNyx apart from other NFT platforms?", ans: " PolyNyx stands out for its innovative approach to NFTs, offering a unique blend of generative art, immersive gaming experiences, and passive income opportunities. Our platform prioritizes safety, security, and reliability, providing users with a dynamic ecosystem where creativity thrives and imagination knows no bounds." },
        { id: 4, que: "4. How can I earn passive income on PolyNyx?", ans: " PolyNyx offers users various avenues to earn passive income. Through our open-source Smart contract MLM structure, users can participate in the PolyNyx community and earn rewards for activities such as trading, collecting, and engaging with NFTs. Additionally, users can leverage our pixel art gaming quests and other features to unlock additional income streams." },
        { id: 5, que: "5. Is my data and assets secure on PolyNyx", ans: " Yes, PolyNyx prioritizes the safety and security of users' data and assets. Our platform employs robust encryption protocols and security measures to protect against unauthorized access and malicious activities. Additionally, transactions on PolyNyx are recorded on the Blockchain, ensuring transparency, immutability, and authenticity." },
        { id: 6, que: "6. Can I create my own NFTs on PolyNyx?", ans: " Yes, PolyNyx empowers users to create their own NFTs and unleash their creativity. Our platform offers tools and resources for users to mint, customize, and trade their NFT creations, providing a platform for artists, creators, and collectors to showcase their talents and share their unique visions with the world." },
        { id: 7, que: "7. How do I get started on PolyNyx?", ans: " Getting started on PolyNyx is easy. Users can easily create an account by connecting their wallet through WalletConnect, gaining access to a world of NFTs, gaming quests, and community engagement. Explore our marketplace, participate in trading, and join our vibrant community of NFT enthusiasts to start your PolyNyx journey today." },
        { id: 8, que: "8. What is the significance of the name PolyNyx?", ans: " The name PolyNyx is derived from the fusion of Polygon, a leading Blockchain network known for its scalability and efficiency, and Nyx, the mythical Greek goddess symbolizing the night and creativity. This inspired fusion reflects our commitment to innovation and limitless possibilities, offering users a gateway to explore new realms of imagination within the NFT world." },
        { id: 9, que: "9. How can I participate in PolyNyx gaming quests?", ans: " Participating in PolyNyx gaming quests is simple. Our platform offers a range of pixel art gaming experiences inspired by the primordial deities literature. Simply navigate to the gaming section of our platform, select a quest, and embark on an immersive journey through captivating game scenarios, where every move brings you closer to unlocking new NFT treasures." },
        { id: 10, que: "10. What support and resources does PolyNyx provide to its users?", ans: " PolyNyx is committed to providing comprehensive support and resources to its users. Our platform offers user-friendly interfaces, detailed documentation, and responsive customer support to assist users at every step of their NFT journey. Additionally, our vibrant community provides a space for users to connect, share insights, and collaborate on projects, fostering a culture of creativity, collaboration, and exploration." },
    ]);

    const [itemss, SetItemss] = useState([
        { id: 1, title: " Explore the Cosmos", des: "Embark upon a cosmic odyssey to unravel the mysteries of creation hidden within the farthest reaches of the cosmos." },
        { id: 2, title: " Unlock Creativity", des: "Experiment with primary elements to unlock new hues and create pixel art characters of unparalleled beauty and complexity." },
        { id: 3, title: " Ascend to Greatness", des: "Personalize avatars with an advanced AI, infusing them with the essence of aspirations and ideals." },
        { id: 4, title: " Forge Bonds of Unity", des: "Merge avatars through marriage, forging a legacy that transcends individuality and shapes the destiny of the metaverse." },
        { id: 5, title: " Leave Your Mark Upon the Metaverse", des: "Explore the vast expanse of the metaverse, leaving a trail of discovery and triumph in your wake as you shape the cosmos itself." },

        { id: 6, title: " NFT Crafting", des: "Combine Red, Green, and Blue NFTs to unlock new hues and create unique pixel art characters." },
        { id: 7, title: " Avatar Customization", des: "Utilize an advanced AI to personalize avatars, shaping them according to your dreams and aspirations." },
        { id: 8, title: " Sacred Union", des: "Merge avatars in marriage to unlock new possibilities and shape the destiny of the metaverse." },
        { id: 9, title: " Cosmic Exploration", des: "Traverse the boundless expanse of the metaverse in search of adventure and discovery." },
        { id: 10, title: " LCollaborative Creatione", des: "Forge alliances with fellow adventurers, shaping the destiny of the universe together through cooperative gameplay and shared experiences." },
    ]);

    const [accouninfo, setAccountinfo] = useState([
        {
            desc: 'Lorem Ipsum1',
            number: '1.22 ETH',
            name: 'Jack Wilson',
            subtext: 'Bored Ape',
            image: Images.eth,
            profileimg: Images.profile,
            nftimg: Images.monkey,
        },
        {
            desc: 'Lorem Ipsum2',
            number: '1.22 ETH',
            name: 'Jack Wilson',
            subtext: 'Bored Ape',
            image: Images.eth,
            profileimg: Images.profile,
            nftimg: Images.monkey,
        },
        {
            desc: 'Lorem Ipsum3',
            number: '1.22 ETH',
            name: 'Jack Wilson',
            subtext: 'Bored Ape',
            image: Images.eth,
            profileimg: Images.profile,
            nftimg: Images.monkey,
        },
        {
            desc: 'Lorem Ipsumr',
            number: '1.22 ETH',
            name: 'Jack Wilson',
            subtext: 'Bored Ape',
            image: Images.eth,
            profileimg: Images.profile,
            nftimg: Images.monkey,
        },
        {
            desc: 'Lorem Ipsum',
            number: '1.22 ETH',
            name: 'Jack Wilson',
            subtext: 'Bored Ape',
            image: Images.eth,
            profileimg: Images.profile,
            nftimg: Images.monkey,
        },
        {
            desc: 'Lorem Ipsum',
            number: '1.22 ETH',
            name: 'Jack Wilson',
            subtext: 'Bored Ape',
            image: Images.eth,
            profileimg: Images.profile,
            nftimg: Images.monkey,
        },
        {
            desc: 'Lorem Ipsum',
            number: '1.22 ETH',
            name: 'Jack Wilson',
            subtext: 'Bored Ape',
            image: Images.eth,
            profileimg: Images.profile,
            nftimg: Images.monkey,
        },
        {
            desc: 'Lorem Ipsum1',
            number: '1.22 ETH',
            name: 'Jack Wilson',
            subtext: 'Bored Ape',
            image: Images.eth,
            profileimg: Images.profile,
            nftimg: Images.monkey,
        },
    ]
    )

    useEffect(() => {
        FetchNFTsData()
        Getandselldata();
        Getandsellnft();
        Getplynyxlist();
        Getroadmaplist();
        Getfaqlist();
        Getnewslist();
    }, []);

    const FetchNFTsData = async () => {
        console.log("FetchNFTsData");
        var Response = await GetNFTs()
        console.log("GetNFTs", Response?.Details);
        if (Response.status) {
            SetNFTDet(Response?.Details)
            SetNFTDetLen(Response?.Details?.length)
        }
    }

    const Getandselldata = async () => {
        var resp = await getCmsContent("homepage_box1");
        console.log("cmslistdfgfd", resp?.data)
        if (resp?.status) setHomecontent(resp.data);
    };
    const Getandsellnft = async () => {
        var resp = await getCmsContent("homepage_box2");
        console.log("cmslist", resp.data);
        if (resp?.status) setHomecontentmidd(resp?.data);
    };


    const Getplynyxlist = async () => {
        let records = await getpolynyxlist()
        console.log("recoredss", records.data);
        if (records.status) setpolynyxdata(records?.data)
    }


    const Getroadmaplist = async () => {
        let resp = await getroadmaplist()
        console.log("roadssss", resp?.data);
        if (resp?.status) setroadmaplist(resp?.data)
    }


    const Getfaqlist = async () => {
        let resp = await getfaqlist()
        console.log("roadssssfaq", resp?.data);
        if (resp?.status) SetValuess(resp?.data)
    }
    const Getnewslist = async () => {
        let resp = await getnewslist()
        console.log("Getnewslist", resp?.data);
        if (resp?.status) SetItemss(resp?.data)
    }

    const arrayChunk = (arr, n) => {
        const array = arr.slice();
        const chunks = [];
        while (array.length) chunks.push(array.splice(0, n));
        return chunks;
    };

    async function getapproveFun() {
        try {
            var web3 = new Web3(window.ethereum);
            var ConnectContract = await new web3.eth.Contract(Abi, "0x76f8a12A8bf10369c56c1b5eF2f5CD22e1Bb534c");
            console.log("ConnectContract", ConnectContract);
            var contract_Method_Hash = await
                ConnectContract
                    .methods
                    .mint("https://naifty.infura-ipfs.io/ipfs/QmUBmSfXjhccGVpNDAo3P4H74zNogCe8f5DiqQeVmMkdnZ", "0x025c1667471685c323808647299e5DbF9d6AdcC9", 100, 1000000000000000000, 3)
                    .send({
                        from: "0x025c1667471685c323808647299e5DbF9d6AdcC9"
                    }).on('transactionHash', (transactionHash) => {
                        return transactionHash
                    })
            console.log("contract_Method_Hash", contract_Method_Hash);

        } catch (e) {
            console.log("cxvxxxxxxxxcvvvvvv", e);
        }
    }

    useEffect(() => {
        GetLevelUps()
    }, [Wallet_Details?.UserAccountAddr])

    const GetLevelUps = async () => {
        console.log("Wallet_Details?.UserAccountAddrsdfasda", Wallet_Details?.UserAccountAddr);
        if (Wallet_Details?.UserAccountAddr) {
            console.log("asdasdasdsad");
            let SendData = {
                addr: Wallet_Details?.UserAccountAddr,
            }
            console.log("SendData", SendData);
            var SetDaata = await LevelUpData(SendData)
            console.log("SetDaata", SetDaata);
            if (SetDaata?.Status) {
                dispatch({
                    type: Account_Connect,
                    Account_Detail: {
                        UserLevels: SetDaata?.Data,
                    }
                })
            }
        }
    }

    return (
        <>

            {/* {!loading &&
                <> */}
            {initialload ?
                <div className='pos-rel'>
                    <video className='videoTagsss' autoPlay muted>
                        <source src="./loader.mp4" type='video/mp4' />
                    </video>
                </div>
                :
                <div className='Homepage'>
                    <Header />
                    <div className='section1 firsttop pb-4'>
                        <video className='videoTag' autoPlay muted>
                            <source src={sample} type='video/mp4' />
                        </video>
                        <div className='ss'>
                            <img src={Images.leftvector} className='leftvector' />
                            <img src={Images.rightvector} className='rightvector' />
                            <div className='container  text-center position-relative'>
                                <img src={Images.headerss} className='w-100' />
                                <div className='row'>
                                    <div className='col-md-10 col-xl-9 mx-auto'>
                                        <img src={Images.polyimg} className='polyimg' />
                                        <p className='subtext text-center mt-xxl-4 mb-xxl-4'> <div dangerouslySetInnerHTML={{
                                            __html: homecontent?.description,
                                        }}></div></p>
                                        <a href="#sss"><img src={Images.explorebtn} className='explorebtn' /></a>
                                    </div> </div></div>
                        </div>
                    </div>

                    <div id="sss" className='section2 py-5'>
                        <span className='violentgrad'></span>
                        <img src={Images.sec2vector} className='secvector' />
                        <img src={Images.topvector} className='topvector' />
                        <img src={Images.rosevector} className='rosevector' />
                        <div className='container pt-2 pb-3'>
                            <div className='row align-items-center'>
                                <div className='col-lg-6 backimg py-5 px-3'>
                                    <h1 className='heading'>{homecontentmidd?.heading}<br />
                                    </h1>
                                    <p className='subtext'> <div dangerouslySetInnerHTML={{
                                        __html: homecontentmidd?.description,
                                    }}></div></p>
                                </div>
                                <div className='col-lg-6'>
                                    <div className='row'>
                                        <div className='col-sm-6 col-md-6'>
                                            <div className='cardborder blucard'>

                                                <div className='cardborder rosecard'>
                                                    <img src={`${config.Image_url}/cmsimages/${homecontentmidd.image[0]}`} className='monkey' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-sm-6 col-md-6'>
                                            <div className='cardborder rosecard'>
                                                <img src={`${config.Image_url}/cmsimages/${homecontentmidd.image[1]}`} className='monkey' />
                                            </div>
                                        </div>
                                        <div className='col-sm-6 col-md-6'>
                                            <div className='cardborder greencard'>
                                                <img src={`${config.Image_url}/cmsimages/${homecontentmidd.image[2]}`} className='monkey' />
                                            </div>
                                        </div>
                                        <div className='col-sm-6 col-md-6'>
                                            <div className='cardborder yellowcard'>
                                                <img src={`${config.Image_url}/cmsimages/${homecontentmidd.image[3]}`} className='monkey' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='section3 py-5'>
                        <div className='container'>
                            <img src={Images.lines} className='lines' />
                            <h1 className='mainheading pb-3'>Featured NFTs <img src={Images.nfts} className='nfts' /></h1>
                            <span className='blugradient'></span>
                            <OwlCarousel className='owl-theme' nav {...options}>
                                {NFTDet.map((NFT, index) => (
                                    <div class='item'>
                                        <Link state={NFT} to='/marketplacedetail'>
                                            <Nftcardpage datas={NFT} />
                                        </Link>
                                    </div>
                                ))}
                            </OwlCarousel>
                        </div>
                    </div>

                    <div className='section4 py-4'>
                        <div className='container'>
                            <img src={Images.backlogo} className='backlogo' />
                            <img src={Images.lefttop} className='curve lefttop' />
                            <img src={Images.righttop} className='curve righttop' />
                            <img src={Images.leftbottom} className='curve leftbottom' />
                            <img src={Images.rightbottom} className='curve rightbottom' />
                            <img src={Images.arrow1} className='arrow arrow1' />
                            <img src={Images.arrow2} className='arrow arrow2' />
                            <img src={Images.arrow3} className='arrow arrow3' />
                            <img src={Images.arrow4} className='arrow arrow4' />
                            <img src={Images.polyleftvector} className='polyleftvector' />
                            <span className='blugradient1'></span>
                            <div className='row text-center'>
                                <div className='col-md-8 mx-auto'>
                                    <h1 className='mainheading mt-4 pb-2'><img src={Images.leftdot} className='leftdot' /> <img src={Images.headingblink} className='headingblink' /> Why Polynyx? <img src={Images.headingblink} className='headingblink' />  <img src={Images.leftdot} className='leftdot rightdot' /></h1>
                                    {/* <p className='subtext mb-0'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi rhoncus pellentesque tellus, vel vestibulum lacus dignissim sed. Phasellus id metus placerat, porta enim convallis, ullamcorper lectus. Nam est nisi, rutrum vel dictum sed, sagittis at dui.</p> */}
                                </div>
                            </div>



                            <div className='row pt-4 pb-5 pb-sm-0'>

                                <div className='col-lg-11 col-xl-11 mx-auto'>



                                    <div className='row'>
                                        {polynyxdata?.map((val) => (
                                            <div className='col-md-6 mt-5'>
                                                <div className='whyborder whyborderss py-4 px-3 px-sm-5 px-md-4'>
                                                    <h4 className='mainheading d-flex align-items-center text-start pb-2 mt-2'><img src={`${config.Image_url}/polynyximg/${val?.img}`} className='keylogo me-2' />{val.heading}</h4>
                                                    <p className='subtext ms-2'><div dangerouslySetInnerHTML={{
                                                        __html: val?.content
                                                    }}></div></p>
                                                </div>
                                            </div>
                                        ))}
                                        {/* <div className='col-md-6 mt-5'>
                                            <div className='whyborder1 whyborderss py-4 px-3 px-sm-5 px-md-4'>
                                                <h4 className='mainheading text-start mt-2 mt-md-4 pb-2'><img src={Images.check} className='keylogo me-2' /> Passive Income Opportunities</h4>
                                                <p className='subtext'>PolyNyx offers users a unique opportunity to earn passive income through our open-source Smart contract MLM structure. By participating in the PolyNyx community and engaging with NFTs, users can unlock rewards and incentives, providing a steady stream of income over time. Whether through trading, collecting, or gaming, PolyNyx empowers users to generate income passively while exploring their passions.</p>
                                            </div>
                                        </div>
                                        <div className='col-md-6 mt-5'>
                                            <div className='whyborder2 whyborderss py-4 px-3 px-sm-5 px-md-4'>
                                                <h4 className='mainheading text-start mt-2 mt-md-4 pb-2'><img src={Images.key} className='keylogo me-2' />Innovative Blend of Blockchain and Generative Art</h4>
                                                <p className='subtext ms-2'>At PolyNyx, we combine cutting-edge Blockchain technology with the captivating allure of generative art. Our platform offers a dynamic ecosystem where users can discover and create pixel-perfect NFTs, each representing a unique masterpiece waiting to be explored. By harnessing the power of Blockchain, PolyNyx ensures transparency, immutability, and authenticity in every NFT transaction.</p>
                                            </div>
                                        </div>
                                        <div className='col-md-6 mt-5'>
                                            <div className='whyborder3 whyborderss py-4 px-3 px-sm-5 px-md-4'>
                                                <h4 className='mainheading text-start mt-2 pb-2'><img src={Images.group} className='keylogo me-2' />Community-Centric Approach</h4>
                                                <p className='subtext ms-2'>PolyNyx is more than just a platform; it's a thriving community united by a shared passion for NFTs, creativity, and exploration. Our platform fosters collaboration and engagement, providing users with opportunities to connect, share insights, and support one another on their NFT journey. With PolyNyx, users become part of a vibrant community where ideas flourish, friendships form, and possibilities abound. Join us today and experience the PolyNyx community difference.</p>
                                            </div>
                                        </div> */}

                                    </div>

                                </div>

                            </div>


                        </div>
                    </div>



                    <div className='section5 py-5'>
                        <img src={Images.polyleftvector} className='polyleftvector2' />
                        <img src={Images.roaddownvector} className='roaddownvector' />
                        <img src={Images.circle} className='circle' />
                        <img src={Images.circle} className='circle1' />
                        <img src={Images.yellowline} className='yellowline' />
                        <img src={Images.bluroadtext} className='bluroadtext' />
                        <img src={Images.blurmaptext} className='blurmaptext' />
                        <img src={Images.tophigh} className='tophigh' />
                        <div className='container pt-4'>
                            <div className='col-md-6 col-lg-5 mx-auto position-relative'>
                                <img src={Images.roadmap} className='roadmap w-100 mt-5' />
                                <h1 className='road'>Roadmap</h1>
                            </div>
                        </div>

                        <div className='fullposition d-none d-xl-block'>
                            <img src={Images.fullroadmap} className='fullroadmap w-100 mt-5' />
                            <span className='yellowgradient1'></span>
                            <span className='yellowgradient2'></span>
                            {/* {roadmaplist?.filter((item)=>console.log("itemmmm",item.step === 1))} */}
                            <div className='roadsec1'>
                                <h6 className='roadhead d-flex align-items-center'> <span className='whitebox me-2'></span> {roadmaplist.find((val) => val.step == 1).question}</h6>
                                {/* <p className='subtext'>{roadmaplist.find((val)=>val.step == 1).answer}</p> */}
                                <p className='subtext'><div dangerouslySetInnerHTML={{
                                    __html: roadmaplist.find((val) => val.step == 1).answer,
                                }}></div></p>
                            </div>
                            <div className='roadsec2'>
                                <span className='d-flex'><span className='whitebox mb-2 me-2'></span><span className='whitebox mb-2'></span></span>
                                <h6 className='roadhead d-flex align-items-center'>{roadmaplist.find((val) => val.step == 2).question}</h6>
                                <p className='subtext'><div dangerouslySetInnerHTML={{
                                    __html: roadmaplist.find((val) => val.step == 2).answer,
                                }}></div></p>
                            </div>
                            <div className='roadsec3'>
                                <span className='d-flex'><span className='whitebox mb-2 me-2'></span><span className='whitebox mb-2 me-2'></span><span className='whitebox mb-2'></span></span>
                                <h6 className='roadhead d-flex align-items-center'>{roadmaplist.find((val) => val.step == 3).question}</h6>
                                <p className='subtext'><div dangerouslySetInnerHTML={{
                                    __html: roadmaplist.find((val) => val.step == 3).answer,
                                }}></div></p>
                            </div>
                            <div className='roadsec4'>
                                <span className='d-flex rotate'><span className='whitebox mb-2 me-2'></span><span className='whitebox mb-2 me-2'></span><span className='whitebox mb-2 me-2'></span><span className='whitebox mb-2'></span></span>
                                <h6 className='roadhead text-start text-lg-start mt-1 mt-lg-4 me-2'>{roadmaplist.find((val) => val.step == 4).question}</h6>
                                <p className='subtext me-2'><div dangerouslySetInnerHTML={{
                                    __html: roadmaplist.find((val) => val.step == 4).answer,
                                }}></div></p>
                                <span className='d-flex pt-2'><span className='whitebox mb-2 me-2'></span><span className='whitebox mb-2 me-2'></span><span className='whitebox mb-2 me-2'></span><span className='whitebox mb-2 me-2'></span><span className='whitebox mb-2'></span></span>
                                <h6 className='roadhead text-start text-lg-start mt-1 mt-lg-2 me-2'>{roadmaplist.find((val) => val.step == 5).question}</h6>
                                <p className='subtext me-2'><div dangerouslySetInnerHTML={{
                                    __html: roadmaplist.find((val) => val.step == 5).answer,
                                }}></div></p>
                            </div>
                        </div>

                        <div className='fullposition1 d-block d-xl-none mt-5'>
                            <span className='yellowmobgrad'></span>
                            <div className='d-flex align-items-end'>
                                <img src={Images.roadmobile1} className='img-fluid' />
                                <div className='roadsec11 me-3'>
                                    <h6 className='roadhead d-flex align-items-center'> <span className='whitebox me-2'></span> Genesis: Inception of Creation</h6>
                                    <p className='subtext'>In the boundless expanse of the cosmos, Nyx, the sovereign of darkness, yearned for the spark of creation. From the depths of the void, she birthed three celestial essences: Red, Green, and Blue. These primal hues, imbued with the essence of possibility, became the foundation upon which the universe would be built. Thus began the grand tapestry of existence, where darkness and light danced in eternal harmony.</p>
                                </div>
                            </div>
                            <div className='w2'>
                                <img src={Images.roadmobile2} className='img-fluid' />
                                <div className='roadsec22'>
                                    <span className='d-flex'><span className='whitebox mb-2 me-2'></span><span className='whitebox mb-2'></span></span>
                                    <h6 className='roadhead d-flex align-items-center'>Nemesis: The Pursuit of Knowledge</h6>
                                    <p className='subtext'>As intrepid explorers ventured forth into the celestial expanse, they unlocked the secrets of creation hidden within the primary elements. Through the delicate art of alchemy, they blended these hues to unveil new shades, each a testament to the boundless creativity of the cosmos. With each discovery, they moved closer to unlocking the mysteries of existence itself. In this episode, primordial characters are created.</p>
                                </div>
                            </div>
                            <div className='w3'>
                                <img src={Images.roadmobile3} className='img-fluid' />
                                <div className='roadsec33'>
                                    <span className='d-flex'><span className='whitebox mb-2 me-2'></span><span className='whitebox mb-2 me-2'></span><span className='whitebox mb-2'></span></span>
                                    <h6 className='roadhead d-flex align-items-center'> Lilith: The Dance of Shadows and Light</h6>
                                    <p className='subtext'>Empowered by an advanced AI, seekers breathed life into their pixelated creations, crafting avatars of unparalleled beauty and complexity. Through customization and personalization, they infused their avatars with the essence of their aspirations, forging bonds that transcended the digital realm. Thus, the metaverse became a canvas upon which their dreams took flight, illuminated by the brilliance of their imagination.</p>
                                </div>
                                <img src={Images.roadmobile4} className='img-fluid' />
                            </div>
                            <div className='d-flex align-items-center ms-4'>
                                <div className='roadsec44 mt-3'>
                                    <span className='d-flex'><span className='whitebox mb-2 me-2'></span><span className='whitebox mb-2 me-2'></span><span className='whitebox mb-2 me-2'></span><span className='whitebox mb-2'></span></span>
                                    <h6 className='roadhead text-start text-lg-start mt-1 mt-lg-2 me-2'> Elves: Guardians of the Celestial Realm</h6>
                                    <p className='subtext me-2'>Bound by the sacred bonds of marriage, adventurers merged their avatars in a union of hearts and minds, forging a legacy that transcended individuality. From this divine union, progeny were born, bearing the legacy of their forebears and shaping the destiny of the metaverse. Together, they stood as guardians of the celestial realm, united in purpose and bound by destiny. In this episode offsprings are born.</p>
                                    <span className='d-flex pt-2'><span className='whitebox mb-2 me-2'></span><span className='whitebox mb-2 me-2'></span><span className='whitebox mb-2 me-2'></span><span className='whitebox mb-2 me-2'></span><span className='whitebox mb-2'></span></span>
                                    <h6 className='roadhead text-start text-lg-start mt-1 mt-lg-2 me-2'> Eros: The Triumph of Love</h6>
                                    <p className='subtext me-2'>As the curtains rose on the grand stage of the metaverse, players bore witness to the culmination of their journey. From the celestial heights to the darkest depths, they roamed unfettered, exploring the myriad wonders of existence with a sense of wonder and awe. Together, they carved their mark upon the fabric of reality, weaving tales of valor and triumph that echoed throughout eternity.</p>
                                </div>
                                <img src={Images.roadmobile4} className='img-fluid' />
                            </div>
                        </div>

                    </div>

                    <div className='section6 faq'>
                        <img src={Images.faqtopborder} className='faqtopborder' />
                        <span className='violentgradient'></span>
                        <div className='container'>
                            <div className='text-end mrright pe-2'>
                                <h1 className='topfaq'>FAQ</h1>
                                <img src={Images.disclaimer} className='disclaimer img-fluid' />
                                <img src={Images.faqtextback} className='faqtextback1 img-fluid' />
                                <img src={Images.whtborderbox} className='whtborderbox1 img-fluid' />
                            </div>
                            <div className='row mt-0 px-lg-2'>
                                <div className='col-lg-2 px-3 px-lg-2'>
                                    <ul>
                                        {valuess.slice(0, 6).map((e, i) =>
                                            <li onClick={() => SetTabName(i)}>
                                                <h1 className='number'>0{i + 1}</h1>
                                                <img src={i == 0 ? value == i ? Images.blutopborder : Images.whitetopvector : i == 5 ? value == i ? Images.blubottomborder : Images.bottomvector : value == i ? Images.bluborder : Images.normalwhitevector} className='whitetopvector img-fluid' />
                                            </li>
                                        )}
                                    </ul>
                                </div>
                                <div className='col-lg-10 px-3 px-lg-0'>
                                    {valuess.slice(0, 6).map((e, i) =>
                                    (value == i &&
                                        <div className="faqborder" >
                                            <h5 className={value == i ? "que  animated2 mb-3" : "que mb-3"}>{e.question}</h5>
                                            <p className={value == i ? "subtext animated2 late" : "subtext"}><div dangerouslySetInnerHTML={{
                                                __html: e.answer,
                                            }}></div></p>
                                        </div>)
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='section7 blog pt-5 pb-5'>
                        {/* <img src={Images.dotsquare} className='dotsquarefull img-fluid' /> */}
                        <div className='container'>
                            <h1 className='mainheading d-block d-xl-none pb-3'>News & Events <img src={Images.nfts} className='nfts' /></h1>
                            <div className='blogback px-3 px-lg-2 px-xl-0 pt-3 px-lg-0 pt-lg-0 pb-5 pb-lg-3'>
                                <img src={Images.dotsquare} className='dotsquare img-fluid' />
                                <div className='row align-items-center'>
                                    <div className='col-lg-1 col-xl-1 position-relative'>
                                        <img src={Images.news} className='news img-fluid' />
                                        <img src={Images.toparrows} className='toparrows img-fluid' />
                                    </div>

                                    <div className='col-lg-12 col-xl-11 py-lg-4 ps-lg-5 ps-xl-0 pe-3 pe-lg-5 pe-xl-5'>
                                        <Slider {...settings} className='d-none d-lg-block'>
                                            {arrayChunk(itemss, 3).map((row, i) => (
                                                <div className="items">
                                                    <div className='row'>
                                                        {row.map((col, i) => (
                                                            <div className='col-lg-4 mt-3 mt-lg-0 pt-5'>
                                                                <Blogcard datas={col} />
                                                            </div>
                                                        )
                                                        )}
                                                    </div>
                                                </div>
                                            ))
                                            }

                                        </Slider>

                                        <Slider {...settings} className='d-block d-lg-none'>
                                            {itemss.map((e, i) => (
                                                <div className="items">
                                                    <div className='row'>
                                                        <div className='col-12 col-sm-11 col-md-9 mx-auto  mt-3 mt-lg-0 pt-5'>
                                                            <Blogcard datas={e} />
                                                        </div>

                                                    </div>
                                                </div>
                                            )
                                            )}
                                        </Slider>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />

                </div>
            }
        </>
        //     }
        // </>
    )
}

export default Homepage;