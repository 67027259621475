import Web3 from "web3";
import { EthereumProvider } from '@walletconnect/ethereum-provider';
import Config from "../../Config/Config"
import { toast } from "react-toastify";
import { createPublicClient, http } from 'viem'
import { mainnet, avalancheFuji, bscTestnet } from '@wagmi/core/chains'
import { getAccount, switchNetwork } from '@wagmi/core'
import TokenABI from "../ABI/TokenABI.json"
const Web3Utils = require('web3-utils');


export const connectWallet = async (type, configchainid) => {
    console.log("connectWallet", type, configchainid);
    var accountDetails = {}
    var web3Obj = {}
    if (type == "MetaMask" || type == 'BinanceWallet' || type == 'Coinbase') {
        console.log("MetaMask", type, configchainid);
        web3Obj = await MetamaskWallet(type, configchainid)
    }
    else if (type == 'WalletConnect') {
        console.log("WalletConnect");
        web3Obj = await WalletConnect(type, configchainid)
    }
    else if (type == 'CoinbaseMob') {
        console.log("Coinbase");
        web3Obj = await CoinBaseWallet(type, configchainid)
    }
    if (web3Obj) {
        try {
            const accounts = await web3Obj.web3.eth.getAccounts();
            if (accounts.length != 0) {
                accountDetails.accountAddress = accounts[0].toString().toLowerCase();
            }
            else {
                accountDetails.accountAddress = web3Obj.accountAddress;
            }
            try {
                var coinBalance = await web3Obj.web3.eth.getBalance(accountDetails.accountAddress);
                var CurrBalance = parseInt(coinBalance)
                accountDetails.coinBalance = Web3Utils.fromWei(CurrBalance, "ether")
                var conObj = new web3Obj.web3.eth.Contract(
                    TokenABI, Config.TokenAddress
                )
                // console.log("sdflsdnflsdnfl",conObj);
                var TokBal = await conObj.methods.balanceOf(accountDetails.accountAddress).call()
                accountDetails.tokenBalance = Web3Utils.fromWei(TokBal, "ether")
                console.log("accountDetails.tokenBalance", accountDetails.tokenBalance, TokBal);
            } catch (err) {
                console.log("Catch Logs On First try in ConnectWallet", err);
                return accountDetails;
            }
            accountDetails.web3 = web3Obj.web3;
            return accountDetails;
        }
        catch (e) {
            console.log("Catch Logs in ConnectWallet", e);
            return accountDetails;
        }
    }

}

export const MetamaskWallet = async (type, configchainid) => {
    var web3
    try {
        if (window.ethereum && type == 'MetaMask') {
            web3 = new Web3(window.ethereum);
            if (window.ethereum.isMetaMask == true) {
                const chainId = await web3.eth.getChainId();
                console.log("accountDetails type id@che", chainId, configchainid, parseInt(chainId), parseInt(configchainid))
                if (parseInt(chainId) != parseInt(configchainid)) {
                    chainIdCheck(configchainid)
                    localStorage.setItem('walletConnectType', type)
                    var Web3Object = {
                        web3: web3,
                    }
                    return Web3Object
                }
                await window.ethereum.enable().then(async () => {
                    // User has allowed account access to DApp...
                    const accounts = await web3.eth.getAccounts();
                    const account = accounts[0].toString().toLowerCase();
                    localStorage.setItem("accountInfo", account)
                    localStorage.setItem('walletConnectType', type)
                });
            }
            else {
                toast.error("Please Uninstall CoinbaseWallet or Connect to Coinbase")
                return false;
            }
        }
        // Legacy DApp Browsers
        else if (window.web3) {
            web3 = new Web3(window.web3.currentProvider);
            const chainId = await web3.eth.getChainId();
            if (parseInt(chainId) != parseInt(configchainid)) {
                chainIdCheck()
                localStorage.setItem('walletConnectType', type)
                return true
            }
            const accounts = await web3.eth.getAccounts();
            const account = accounts[0].toString().toLowerCase();
            localStorage.setItem("accountInfo", account)
            localStorage.setItem('walletConnectType', type)
        }
        // Non-DApp Browsers
        else {
            toast.error('No Dapp Supported Wallet Found');
            console.log("No Dapp Supported Wallet Found")
        }
    } catch (e) {
        console.log("MetaMask Last Catch Console", e)
    }
    var Web3Object = {
        web3: web3,
    }
    return Web3Object;
}

export const WalletConnect = async (type, configchainid) => {
    try {
        console.log("Wallet Connect", type, configchainid);
        const CHAIN_ID = configchainid;
        const id = "b8a1daa2dd22335a2fe1d2e139980ae0"//"d92e2db22a14de8b6f2b3ac926e7793c" //b8a1daa2dd22335a2fe1d2e139980ae0 //ceda0e402fa096c2645a342d4fd402f3
        console.log("localStorage.getItem accountInfo", localStorage.getItem("accountInfo"));
        // if (localStorage.getItem("accountInfo")) {
        //     try {
        //         const provider = await EthereumProvider.init({
        //             projectId: 'b8a1daa2dd22335a2fe1d2e139980ae0', // required
        //             chains: [CHAIN_ID], // required
        //             showQrModal: true // requires @walletconnect/modal
        //         })
        //         console.log("providerprovider", provider);
        //         await provider.connect()
        //         var web3 = new Web3(provider);
        //         const accounts = await provider.request({
        //             method: 'eth_requestAccounts',
        //         });
        //         var account = accounts[0].toString().toLowerCase();
        //         localStorage.setItem("accountInfo", account)
        //         localStorage.setItem('walletConnectType', type)
        //         let Web3Object = {
        //             web3: web3
        //         }
        //         return Web3Object;
        //     } catch (err) {
        //         return false
        //     }
        // }
   

        // else {
        try {
            console.log("Config.HexString", Config.HexString);
            const provider = await EthereumProvider.init({
                projectId: id, // required
                chains: [Config.ChainID], // required
                showQrModal: true // requires @walletconnect/modal
            })
            console.log("Provider From Wallet Connect", provider, Config.HexString);
            await provider.enable()

            var web3 = new Web3(provider);
            console.log("web3", web3);
            const chainId = await web3.eth.getChainId();
            console.log("chainId", chainId)
            // const accounts1 = await provider.request({
            //     method: 'wallet_switchEthereumChain',
            //     params: [{ chainId: Config.HexString }],
            // });
            const accounts = await provider.request({
                method: 'eth_requestAccounts',
            });
            if (parseInt(chainId) != parseInt(configchainid)) {
                chainIdCheck(configchainid)
                // localStorage.setItem('walletConnectType', type)
                // var Web3Object = {
                //     web3: web3,
                // }
                // return Web3Object
            }
            var account = accounts[0].toString().toLowerCase();
            localStorage.setItem("accountInfo", account)
            localStorage.setItem('walletConnectType', type)
            console.log("Wallet Connect Account", accounts);
            let Web3Object = {
                web3: web3
            }
            return Web3Object;
        }
        catch (err) {
            console.log("Error On First Try Catch", err);
            return false
        }
    }
    // }
    catch (err) {
        console.log("Errorrrr On Last Wallet Connect", err);
        return false
    }
}



export const CoinBaseWallet = async (type) => {
    try {
        console.log("connectCoinBaseWallet the mobile");
        const publicClient = createPublicClient({
            chain: bscTestnet,
            transport: http()
        })
        const chainId = await publicClient.getChainId()
        let network = ""

        if (Number(chainId) !== Number(Config.CHAIN_ID)) {
            network = await switchNetwork({
                chainId: Config.ChainID,
            })
        }
        var web3 = new Web3(publicClient);
        let Web3Object = {
            web3: web3
        }
        return Web3Object;

    }
    catch (err) {
        console.log('connect error', err)
    }


}

const chainIdCheck = async (configchainid) => {
    try {
        if (window.ethereum) {
            try {
                await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: Config.HexString }], // chainId must be in hexadecimal numbers
                });
                return true;
            } catch (error) {
                // This error code indicates that the chain has not been added to MetaMask
                // if it is not, then install it into the user MetaMask
                if (error.code === 4902) {
                    try {
                        await window.ethereum.request({
                            method: 'wallet_addEthereumChain',
                            params: [
                                {
                                    chainId: Config.HexString,
                                    chainName: Config.NetworkName,
                                    rpcUrls: [Config.RPC_URL],
                                },
                            ],
                        });
                        return true;
                    } catch (addError) {
                        // console.log("err in nested catch",addError);
                        console.error(addError);
                    }
                }
                console.error(error);
            }
        } else {
            // if no window.ethereum then MetaMask is not installed
            //console('MetaMask is not installed. Please consider installing it: https://metamask.io/download.html');
            return false;

        }
    } catch (e) {
        return false;
    }
}