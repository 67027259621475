import axios  from "axios"
import { data } from "jquery"
import config from '../../Config/Config'
import {Decryptdata  , EncryptData, AppenData } from "../Helper/EncHelper.js"

const jwttoken = localStorage.getItem("token")

export const getnewslist = async() =>{
    console.log("getnewslist");
    try{
        let resp = await axios({
          
                'method':'get',
                'url':`${config.Admin_Url}/user/getnewslist`, 
                "headers":{
                    'Content-Type': 'application/json',
                    "Authorization": jwttoken
                },
                
               
        })
        resp.data = await Decryptdata(resp?.data)
        return resp.data;
    }
    catch(err){console.log("err in gettok owner",err)}
}

export const fetchnewslist  = async(data)=>{
    console.log("fdfdffdfgdd",data,EncryptData(data));
    
    try{
        let  resp = await axios({
          
                'method':'GET',
                'url':`${config.Admin_Url}/user/fetchnewslist`,
                "headers":{
                    'Content-Type': 'application/json',
                    "Authorization": jwttoken
                },
                'params' : {data : EncryptData(data)} 
        })
        console.log("gsergt",resp.data)
        resp.data = await Decryptdata(resp?.data)
        return resp.data;
    }
    catch(err){console.log("err in gettok owner",err)}
}   