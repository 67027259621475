import React, { useEffect, useState } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { MdKeyboardDoubleArrowLeft } from "react-icons/md";
import Images from "./Images";
import { useSelector } from "react-redux";
import { DiscoLight } from '../Action/Controller/Token'
import { Bar } from "react-chartjs-2";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const transactionHistoryData = [
  {
    id: 1,
    name: "All Transactions",
  },
  {
    id: 2,
    name: "Binary",
  },
  {
    id: 3,
    name: "MLM",
  },
  {
    id: 4,
    name: "Staking",
  },

  {
    id: 5,
    name: "Pools",
  },
  {
    id: 6,
    name: "Withdraw",
  },
  {
    id: 7,
    name: "Maintain Binary",
  },
];

const customXaxis = [
  {
    id: 1,
    colour: "blue",
  },
  {
    id: 2,
  },
  {
    id: 3,
  },
  {
    id: 4,
  },

  {
    id: 5,
  },
  {
    id: 6,
  },
  {
    id: 7,
  },
  {
    id: 8,
  },
  {
    id: 9,
  },
  {
    id: 10,
  },
  {
    id: 11,
    colour: "blue",
  },
  {
    id: 12,
  },
  {
    id: 13,
  },
  {
    id: 14,
  },
  {
    id: 15,
  },
  {
    id: 16,
  },
  {
    id: 17,
  },
  {
    id: 18,
  },
  {
    id: 19,
  },
  {
    id: 20,
  },
  {
    id: 21,
  },
  {
    id: 22,
  },
  {
    id: 23,
  },
  {
    id: 24,
  },
  {
    id: 25,
  },
  {
    id: 26,
  },
  {
    id: 27,
  },
  {
    id: 28,
  },
  {
    id: 29,
  },
  {
    id: 30,
  },
  {
    id: 31,
  },
  {
    id: 32,
  },
  {
    id: 33,
  },
  {
    id: 34,
  },
  {
    id: 35,
  },
  {
    id: 36,
  },
  {
    id: 37,
  },
  {
    id: 38,
    colour: "blue",
  },
  {
    id: 39,
  },
  {
    id: 40,
  },
  {
    id: 41,
  },

  {
    id: 42,
  },
  {
    id: 43,
  },
  {
    id: 44,
  },
  {
    id: 45,
  },
  {
    id: 46,
  },
  {
    id: 47,
  },
  {
    id: 48,
    colour: "blue",
  },
  {
    id: 49,
  },
  {
    id: 50,
  },
];

const customYaxis = [
  {
    id: 1,
    colour: "blue",
  },
  {
    id: 2,
    colour: "blue",
  },
  {
    id: 3,
  },
  {
    id: 4,
  },

  {
    id: 5,
  },
  {
    id: 6,
  },
  {
    id: 7,
  },
  {
    id: 8,
  },
  {
    id: 9,
  },
  {
    id: 10,
  },
  {
    id: 11,
  },
  {
    id: 12,
  },
  {
    id: 13,
  },
  {
    id: 14,
  },
  {
    id: 15,
  },
  {
    id: 16,
    colour: "blue",
  },
  {
    id: 17,
    colour: "blue",
  },
  {
    id: 18,
  },
  {
    id: 19,
  },

  {
    id: 20,
  },
  {
    id: 21,
  },
  {
    id: 22,
  },
  {
    id: 23,
  },
  {
    id: 24,
  },
  {
    id: 25,
  },
  {
    id: 26,
  },
  {
    id: 27,
  },
  {
    id: 28,
  },
  {
    id: 29,
  },
  {
    id: 30,
  },
  {
    id: 31,
  },
  {
    id: 32,
  },
  {
    id: 33,
  },
  {
    id: 34,
  },
];

const graphValue = [
  [1, 2],
  [2, 9],
  [3, 5],
  [6, 9],
];

const customDataYaxis = [
  {
    id: 1,
  },
  {
    id: 2,
  },
  {
    id: 3,
  },
  {
    id: 4,
  },

  {
    id: 5,
  },
  {
    id: 6,
  },
  {
    id: 7,
  },
  {
    id: 8,
  },
  {
    id: 9,
  },
  {
    id: 10,
  },
  {
    id: 11,
  },
  {
    id: 12,
  },
  {
    id: 13,
  },
  {
    id: 14,
  },
  {
    id: 15,
  },
  {
    id: 16,
  },
  {
    id: 17,
  },
  {
    id: 18,
  },
  {
    id: 19,
  },
  {
    id: 20,
  },
];

const Barcharts = () => {

  const Wallet_Details = useSelector((state) => state.wallet_connect_context);
  const [tableName, setTableName] = useState("All Transactions");
  const [xAxis, setxAxis] = useState(10);
  const [yAxis, setyAxis] = useState(15);

  const [customDataXaxis, setcustomDataXaxis] = useState([
    {
      id: 1,
      colour: "blue",
    },
    {
      id: 2,
    },
    {
      id: 3,
    },
    {
      id: 4,
    },

    {
      id: 5,
    },
    {
      id: 6,
    },
    {
      id: 7,
    },
    {
      id: 8,
    },
    {
      id: 9,
    },
    {
      id: 10,
    },
    {
      id: 11,
    },
    {
      id: 12,
    },
    {
      id: 13,
    },
    {
      id: 14,
    },
    {
      id: 15,
    },
    {
      id: 16,
    },
    {
      id: 17,
    },
    {
      id: 18,
    },
    {
      id: 19,
    },
    {
      id: 20,
    },
    {
      id: 21,
    },
    {
      id: 22,
    },
    {
      id: 23,
    },
    {
      id: 24,
    },
    {
      id: 25,
    },
    {
      id: 26,
    },
    {
      id: 27,
    },
    {
      id: 28,
    },
    {
      id: 29,
    },
    {
      id: 30,
    },
    {
      id: 31,
    },
    {
      id: 32,
    },
    {
      id: 33,
    },
    {
      id: 34,
    },
    {
      id: 35,
    },
    {
      id: 36,
    },
    {
      id: 37,
    },
    {
      id: 38,
      colour: "blue",
    },
    {
      id: 39,
    },
    {
      id: 40,
    },
    {
      id: 41,
    },

    {
      id: 42,
    },
    {
      id: 43,
    },
    {
      id: 44,
    },
    {
      id: 45,
    },
    {
      id: 46,
    },
    {
      id: 47,
    },
    {
      id: 48,
    },
    {
      id: 49,
    },
    {
      id: 50,
    },
  ])
  useEffect(() => {
    var x = document.getElementById("br_chrt_container");

    x.scrollTop = x.scrollHeight;

    var y = document.getElementById("br_chart_xaxis_data");

  }, []);
  const columns = [
    {
      key: "week",
      text: "Week",
      className: "tableWeek",
      align: "center",
      sortable: false,
    },
    {
      key: "dataRange",
      text: "Date Range",
      className: "tableDateRange",
      align: "center",
      sortable: false,
    },
    {
      key: "leftBalance",
      text: "Weekly Gained Left Balance",
      className: "tableLeftBalance",
      align: "center",

      sortable: false,
    },
    {
      key: "rightBalance",
      text: "Weekly Gained Right Balance",
      className: "tableRightBalance",
      align: "center",
      sortable: false,
    },
    {
      key: "gainedBalance",
      text: "Gained Balance",
      className: "tableGainedBalance",
      sortable: false,
      align: "center",
    },
    {
      key: "commission",
      text: "Commission",
      className: "tableCommission",
      sortable: false,
      align: "center",
    },
    // {
    //   key: "action",
    //   text: "Action",
    //   className: "action",
    //   width: 100,
    //   align: "center",
    //   sortable: false,
    //   cell: (record) => {
    //     return (
    //       <>
    //         <button
    //           className="btn btn-primary btn-sm"
    //           onClick={() => editRecord(record)}
    //           style={{ marginRight: "5px" }}
    //         >
    //           <i className="fa fa-edit"></i>
    //         </button>
    //         <button
    //           className="btn btn-danger btn-sm"
    //           onClick={() => deleteRecord(record)}
    //         >
    //           <i className="fa fa-trash"></i>
    //         </button>
    //       </>
    //     );
    //   },
    // },
  ];


  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    button: {
      excel: true,
      print: true,
      extra: true,
    },
  };



  const records = [
    {
      id: "1",
      week: "125",
      dataRange: "2/17/2024 - 2/24/2024",
      leftBalance: "0",
      rightBalance: "0",
      gainedBalance: " 0",
      commission: "0 BCN",
    },
    {
      id: "2",
      week: "125",
      dataRange: "2/17/2024 - 2/24/2024",
      leftBalance: "0",
      rightBalance: "0",
      gainedBalance: " 0",
      commission: "0 BCN",
    },
    {
      id: "3",
      week: "125",
      dataRange: "2/17/2024 - 2/24/2024",
      leftBalance: "0",
      rightBalance: "0",
      gainedBalance: " 0",
      commission: "0 BCN",
    },
    {
      id: "4",
      week: "125",
      dataRange: "2/17/2024 - 2/24/2024",
      leftBalance: "0",
      rightBalance: "0",
      gainedBalance: " 0",
      commission: "0 BCN",
    },
    {
      id: "5",
      week: "125",
      dataRange: "2/17/2024 - 2/24/2024",
      leftBalance: "0",
      rightBalance: "0",
      gainedBalance: " 0",
      commission: "0 BCN",
    },
    {
      id: "6",
      week: "125",
      dataRange: "2/17/2024 - 2/24/2024",
      leftBalance: "0",
      rightBalance: "0",
      gainedBalance: " 0",
      commission: "0 BCN",
    },
    {
      id: "7",
      week: "125",
      dataRange: "2/17/2024 - 2/24/2024",
      leftBalance: "0",
      rightBalance: "0",
      gainedBalance: " 0",
      commission: "0 BCN",
    },
    {
      id: "8",
      week: "125",
      dataRange: "2/17/2024 - 2/24/2024",
      leftBalance: "0",
      rightBalance: "0",
      gainedBalance: " 0",
      commission: "0 BCN",
    },
    {
      id: "9",
      week: "125",
      dataRange: "2/17/2024 - 2/24/2024",
      leftBalance: "0",
      rightBalance: "0",
      gainedBalance: " 0",
      commission: "0 BCN",
    },
    {
      id: "10",
      week: "125",
      dataRange: "2/17/2024 - 2/24/2024",
      leftBalance: "0",
      rightBalance: "0",
      gainedBalance: " 0",
      commission: "0 BCN",
    },
  ];

  const columnsTwo = [
    {
      key: "number",
      text: "No",
      className: "historyNumber",
      align: "center",
      sortable: false,
    },
    {
      key: "date",
      text: "Date",
      className: "historyDate",
      align: "center",
      sortable: false,
    },
    {
      key: "amount",
      text: "Amount",
      className: "historyAmount",
      align: "center",

      sortable: false,
    },
    {
      key: "type",
      text: "Type",
      className: "history type",
      align: "center",
      sortable: false,
    },
    {
      key: "transaction",
      text: "Transaction",
      className: "historyTransaction",
      sortable: false,
      align: "center",
    },
  ];

  const transactionRecords = [
    {
      id: 1,
      number: 1,
      date: "09/26/2021   3:04:25 PM",
      amount: <p className="text-danger mb-0">-4000 BCN</p>,
      type: "Withdraw",
      transaction: "93c74...6bc4a",
    },
    {
      id: 2,
      number: 2,
      date: "09/26/2021   3:04:25 PM",
      amount: <p className="text-success mb-0">+4000 BCN</p>,
      type: "Withdraw",
      transaction: "93c74...6bc4a",
    },
  ];
  const binaryRecords = [
    {
      id: 1,
      number: 1,
      date: "10/15/2024   5:04:25 PM",
      amount: <p className="text-danger mb-0">-6000 BCN</p>,
      type: "Withdraw",
      transaction: "93c74...6bc4a",
    },
    {
      id: 2,
      number: 2,
      date: "10/15/2024   5:04:25 PM",
      amount: <p className="text-success mb-0">+6000 BCN</p>,
      type: "Withdraw",
      transaction: "93c74...6bc4a",
    },
  ];

  const editRecord = () => {
    console.log();
  };

  const deleteRecord = () => {
    console.log();
  };

  // useEffect(() => {
  //   br_chrt_container.scrollTo({
  //     top: 0,
  //     bottom: 0,
  //     behavior: "smooth",
  //   });
  // }, []);

  const option = {
    responsive: true,
    plugins: {
      legend: { position: "chartArea" },
      title: {
        display: true,
        text: "Modular Bar Chart",
      },
    },
  };
  const data = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    datasets: [
      {
        label: "Product A",
        data: [20, 30, 40, 50, 60, 70],
        backgroundColor: "green",
      },
      {
        label: "Product B",
        data: [15, 20, 25, 40, 45, 60],
        // backgroundColor: "red",
        backgroundColor: (context) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          if (!chartArea) {
            return null;
          }
          if (context.dataIndex === 0) {
            return;
            getGradient(chart);
          } else {
            return "black";
          }
        },
      },
    ],
  };

  const handleTransactionHistory = (name) => {
    setTableName(name);
  };

  const getGradient = (chart) => {
    const {
      ctx,
      chartArea: { top, bottom, left, right },
    } = chart;
    const gradientSegment = ctx.createLinearGradient(left, 0, right, 0);
    gradientSegment.addColorStop(0, "red");
    gradientSegment.addColorStop(0.5, "orange");
    gradientSegment.addColorStop(1, "green");
    return gradientSegment;
  };

  // const dummyFunction = (xGraphValue, yGraphValue) => {
  //   console.log("xgraph", xGraphValue, yGraphValue);
  //   const xValMap = customDataXaxis.map((val) => console.log("val", val));

  //   console.log("xvalmap", xValMap);
  //   const yValMap = customDataYaxis.map((val) => {
  //     return val;
  //   });
  //   console.log("xval", xValMap, yValMap);
  // };

  const [colorActive, setColorActive] = useState([
    [1, 19],
    [2, 13],
    [3, 15],
    [4, 8],
    [5, 6],
    [6, 10],
    [7, 14],
    [7, 14],
    [8, 10],
    [9, 15],
    [10, 21],
  ]);

  useEffect(() => {
    Discoo()
  }, [Wallet_Details.UserAccountAddr])

  const Discoo = async () => {
    let Payload = { user: { curraddress: Wallet_Details.UserAccountAddr } }
    console.log("Wallet_DetailsDailyRec", Wallet_Details.UserAccountAddr);
    if (Wallet_Details.UserAccountAddr) {
      const GETID = await DiscoLight(Payload)
      console.log("GETID", GETID);
      setcustomDataXaxis(GETID?.FindTransak || customDataXaxis)
      setColorActive(GETID?.graphData || colorActive)
    }
  }

  return (
    <div className="container">
      <div className="allcolor">
        <div className="list1">
          <div className="color1 widthclr"></div>
          <p className="whttexts mb-2">Min</p>
        </div>
        <div className="list1">
          <div className="color2 widthclr"></div>
          <p className="whttexts mb-2">Binary</p>
        </div>
        <div className="list1">
          <div className="color3 widthclr"></div>
          <p className="whttexts mb-0">Maintain<br />Binary</p>
        </div>
      </div>
      {/* react js chart */}

      {/* <div className="row" style={{ color: "white" }}>
        <div className="col-9">
          <Bar options={option} data={data} id="chart" />
        </div>
      </div> */}


      <div className="custom_bar_chart  py-5">
        <div className="row">
          <div className="col-10 ms-auto me-2 position-relative">
            {/* <div className="br_chrt_hiden position-absolute  "></div> */}

            <div
              className="br_chrt_container p-3 position-relative"
              id="br_chrt_container"
            >
              {/* <div className="br_cont_yaxis pe-3">
                <ul className="d-flex flex-column-reverse px-0 mb-0 pb-2">
                  {customYaxis.map((val) => (
                    <li>{val.id}</li>
                  ))}
                </ul>
              </div> */}
              {/* <div className="br_cont_xaxis d-flex flex-column-reverse pt-3 ps-5">
                {" "}
                <ul className="d-flex gap-2 px-0 mb-0  ">
                  {customXaxis.map((val) => (
                    <li>{val.id}</li>
                  ))}
                </ul>
              </div> */}
              {" "}
              <div
                className="position-absolute br_chart_xaxis_data br2_chart_xaxis_data d-flex gap-2 "
                id="br_chart_xaxis_data"
              >
                {customDataXaxis.map((xval) => (
                  <ul
                    className="d-flex flex-column-reverse align-items-center mb-0 px-0"
                    style={{ gap: "5px" }}
                  >
                    {customDataYaxis.map((yval) => (
                      <li
                        className={`d-flex align-items-center justify-content-center`}
                      >

                        <div
                          className={`br_cht_blc_box mb-0 ${colorActive.some(
                            (val) => val[0] === xval.id && val[1] >= yval.id
                          )} ${(100 / customDataYaxis.length) * yval.id < 16
                            ? "sky_blue"
                            : "pink"
                            } ${(100 / customDataYaxis.length) * yval.id > 40
                              ? "lite_sky_blue"
                              : ""
                            }  ${(100 / customDataXaxis.length) * xval.id > 15 &&
                              (100 / customDataYaxis.length) * yval.id > 40
                              ? "lite_yelow"
                              : ""
                            }`}
                        >
                        </div>

                      </li>
                    ))}
                  </ul>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Barcharts;
