import CryptoJS from 'crypto-js';
import Config from '../../Config/Config'
import io from 'socket.io-client'

export const socket = io.connect(Config.Back_URL)

export const EncryptData = (data, key) => {
    try {
        if (key) {
            return CryptoJS.AES.encrypt(data, Config.encodekey).toString()
        } else {
            const encJson = CryptoJS.AES.encrypt(JSON.stringify(data), Config.encodekey).toString();
            const encData = CryptoJS.enc.Base64.stringify(
                CryptoJS.enc.Utf8.parse(encJson)
            );
            return encData;
        }
    }
    catch (err) {
        console.log("encerr", err);
        return false
    }
}

export const Decryptdata = ((data, key) => {
    if (key) {
        console.log('rthdrtjhdddd', data, Config.encodekey)
        return CryptoJS.AES.decrypt(data, Config.encodekey).toString(CryptoJS.enc.Utf8)
    }
    else {
        console.log('rthdrtjh', data, Config.encodekey)
        const decData = CryptoJS.enc.Base64.parse(data)?.toString(CryptoJS.enc.Utf8);
        console.log("fasdfsfsdfsdfsdfsdsd", decData)
        const bytes = CryptoJS.AES.decrypt(decData, Config.encodekey).toString(CryptoJS.enc.Utf8);
        console.log("Decryptdata 111 payload", bytes);
        return JSON.parse(bytes)
    }
})

export const handleCallback = (callback) => {
    return async (data) => {
        try {
            return await callback(data);
        } catch (error) {
            return ({
                error: error,
                success: "error",
                status: false,
                data: [],
                msg: `${'From user' + error.toString()}`
            })
        }
    };
};

export const AppenData = (data) => {

    var formdata = new FormData()
    var SendDta = Object.entries(data).map((item) => {
        console.log("item", item);
        if (Array.isArray(item[1])) {
            var come = item[1].map(data => {
                console.log("arrayitem", data);
                if (data?.type && data?.size) {
                    console.log("appitem", item[0], data);
                    formdata.append(item[0], data)
                }
                else {
                    console.log("data", data);
                    formdata.append(item[0], EncryptData(data))
                }
                return formdata
            })

            return come
        }
        else {
            if (item[1]?.type && item[1]?.size) {
                formdata.append(item[0], item[1])
            }
            else {
                console.log("appiitem", item);
                formdata.append(item[0], EncryptData(item[1]))
            }
            return formdata
        }
    })
    return [formdata]
}
