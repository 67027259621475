import { combineReducers } from 'redux';
export const Account_Connect = 'Account_Connect';
export const Account_disConnect = 'Account_disConnect';

const initial_wallet_connect = {
  UserAccountAddr: '',
  LoginAddresss: '',
  LoginMail: '',
  UserAccountBal: 0,
  TokenBalance: 0,
  provider: null,
  Service_Fee: 0,
  Web3: null,
  Accounts: '',
  shareTag: '',
  Social: '',
  Category: '',
  WalletConnected: '',
  AddressUserDetails: null,
  tokenAddress: null,
  networkoption: {},
  networkConfiguration:
  {
    currencySymbol: '',
    tokenSymbol: '',
    tokenABI: {},
    tokenAddr: {},
    singleContract: "",
    multipleContract: "",
    trade: "",
    BNBPROVIDER: "",
    networkVersion: '',
    chainId: '',
    Chainid: 0,
    Refreash: ""
  },
  UserLevels: {},
  NotRefered: false

}
console.log("cnnjdbvhcfbihvbdfsj", initial_wallet_connect);
function wallet_connect_context(state = initial_wallet_connect, action) {
  switch (action.type) {

    case Account_Connect:
      return {
        ...state,
        ...action.Account_Detail
      };
    case Account_disConnect:
      return {
        ...state,
        ...action.Account_Disconnect
      };
    default:
      return state;
  }
}
const birdApp = combineReducers({
  wallet_connect_context
});

export default birdApp;