import React, { useState, useEffect } from 'react';
import { Button, Container, Accordion } from 'react-bootstrap';
import Header from "../Layouts/header";
import Footer from "../Layouts/footer";
import Images from '../Components/Images';
import { getCmsContent } from '../Action/Axios/cmsaxios';


const About = () => {
const [abouts,setabouts] = useState([])
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
        Getandsellnft();
    }, []);
    const Getandsellnft = async () => {
        var resp = await getCmsContent("about");
        console.log("abouttss", resp.data);
        if (resp?.status) setabouts(resp?.data);
    };
    return (

        <>

            <div className='faqpage animehead'>

                <Header />

                <div className='marketplacedetail_sec firsttop pb-5 termspara'>
                    <span className='violentgrad'></span>
                    <span class="blugradient"></span>
                    <img src={Images.detailleftarrow} className='detailleftarrow d-none d-xl-block' />
                    <div className='container pt-4'>
                    <h1 className='mainheading mt-4 pb-5'><img src={Images.leftdot} className='leftdot' /> <img src={Images.headingblink} className='headingblink' /> About <img src={Images.headingblink} className='headingblink' />  <img src={Images.leftdot} className='leftdot rightdot' /></h1>
                    <h3 className='mainheading mt-4 mb-4'>{abouts?.heading}</h3>
                    

                    <div className='row'>
                            <div className='col-xl-11 col-xxl-11 mx-auto'>
                            <p className='subtext mt-4'>
                        <br/><br/>
                        <div dangerouslySetInnerHTML={{
                                        __html: abouts?.description,
                                    }}></div>
                        </p>
                            </div>
                        </div>


                      

                    </div>
                </div>

                <Footer />
            </div>
        </>

    )
}

export default About;



