const Images = {
    logo : require("../Assets/logo.png"),
    leftvector : require("../Assets/leftvector.png"),
    rightvector : require("../Assets/rightvector.png"),
    polyimg : require("../Assets/polytext.png"),
    sec2vector : require("../Assets/2vector.png"),
    topvector : require("../Assets/leftarrow.png"),
    rosevector : require("../Assets/rightsvect.png"),
    blucard : require("../Assets/blucard.png"),
    greencard : require("../Assets/greencard.png"),
    rosecard : require("../Assets/rosecard.png"),
    yellowcard : require("../Assets/yellowcard.png"),
    monkey : require("../Assets/monkey.png"),
    monkey2 : require("../Assets/monkey2.png"),
    monkey3 : require("../Assets/monkey3.png"),
    monkey4 : require("../Assets/monkey4.png"),
    eth : require("../Assets/eth.png"),
    profile : require("../Assets/profile-icon.png"),
    rightcorner : require("../Assets/rightcorner.png"),
    cornerimg : require("../Assets/leftcorner.png"),
    leftcaroimg : require("../Assets/leftcarousel.png"),
    lines : require("../Assets/liness.png"),
    backlogo : require("../Assets/backgroundlogo.png"),

    tick : require("../Assets/tick.png"),
    check : require("../Assets/check.png"),
    key : require("../Assets/key.png"),
    group : require("../Assets/group.png"),

    lefttop : require("../Assets/lefttop.png"),
    righttop : require("../Assets/righttop.png"),
    leftbottom : require("../Assets/leftbottom.png"),
    rightbottom : require("../Assets/rightbottom.png"),

    arrow1 : require("../Assets/arrow1.png"),
    arrow2 : require("../Assets/arrow2.png"),
    arrow3 : require("../Assets/arrow3.png"),
    arrow4 : require("../Assets/arrow4.png"),

    leftdot : require("../Assets/leftdot.png"),

    nfts : require("../Assets/nfts.gif"),
    headingblink : require("../Assets/headingblink.gif"),
    headingblink : require("../Assets/headingblink.gif"),
    roadmap : require("../Assets/roadmap.gif"),
    fullroadmap : require("../Assets/fullroadmap.png"),
    yellowline : require("../Assets/yellowline.png"),

    polyleftvector : require("../Assets/polyleftvector.png"),
    circle : require("../Assets/circle.gif"),
    blurmaptext : require("../Assets/blurmaptext.png"),
    bluroadtext : require("../Assets/bluroadtext.png"),
    tophigh : require("../Assets/tophigh.png"),
    roaddownvector : require("../Assets/roaddownvector.png"),
    // json
    headerss : require("../Assets/header1.gif"),
    explorebtn : require("../Assets/explore_button2.gif"),

    footerpoly : require("../Assets/footerpoly.png"),

    twitter : require("../Assets/twitter.png"),
    disc : require("../Assets/discard.png"),
    insta : require("../Assets/instagram.png"),

    subs : require("../Assets/subscribe.png"),
    news : require("../Assets/news.png"),
    blogimg : require("../Assets/blogimg.png"),
    datewhite : require("../Assets/datewhite.png"),
    readmoreimg : require("../Assets/readmoreimg.png"),
    dotsquare : require("../Assets/dotsquare.png"),
    toparrows : require("../Assets/toparrows.png"),
    // faq
    whitetopvector : require("../Assets/faq/whitetopvector.png"),
    normalwhitevector : require("../Assets/faq/normalwhitevector.png"),
    bottomvector : require("../Assets/faq/bottomvector.png"),
    faqtopborder: require("../Assets/faq/faqtopborder.png"),
    bluborder: require("../Assets/faq/blucolor.png"),
    blutopborder: require("../Assets/faq/blutopborder.png"),
    blubottomborder: require("../Assets/faq/blubottomborder.png"),
    faqtextback: require("../Assets/faq/faqtextback.png"),
    whtborderbox: require("../Assets/faq/whtborderbox.png"),
    disclaimer: require("../Assets/faq/disclaimer.gif"),
    logoicon: require("../Assets/faviconss.png"),

    // modal
    metamask : require("../Assets/metamask.png"),
    coinbase : require("../Assets/Coinbase.png"),
    wallcon : require("../Assets/wallcon.png"),
    detailimg : require("../Assets/detailimg.png"),
    detailprofimg : require("../Assets/profdetailimg.png"),
    detailleftarrow: require("../Assets/detailleftarrow.png"),
    detailbottomarrow: require("../Assets/detailbottomarrow.png"),
    buyntnbtn: require("../Assets/buynftbtn.png"),

    roadmobile1: require("../Assets/roadmobile1.png"),
    roadmobile2: require("../Assets/roadmobile2.png"),
    roadmobile3: require("../Assets/roadmobile3.png"),
    roadmobile4: require("../Assets/roadmobile4.png"),


    toptreevector: require("../Assets/treegraph/toptreevector.png"),
    bottreevector: require("../Assets/treegraph/bottreevector.png"),


    personimg: require("../Assets/personimg1.png"),

    topline: require("../Assets/dashboard/topline.png"),
    rightline: require("../Assets/dashboard/rightline.png"),
    leftline: require("../Assets/dashboard/leftline.png"),
    cardimg: require("../Assets/dashboard/fullimages1.png"),
    dashprofimg: require("../Assets/dashboard/person.png"),
    iconss: require("../Assets/dashboard/icon.png"),
    menuss: require("../Assets/dashboard/menuicon.png"),
    bluelogo: require("../Assets/dashboard/bluelogo.png"),
    left1: require("../Assets/dashboard/left1.png"),
    right1: require("../Assets/dashboard/right1.png"),
    bankbot: require("../Assets/dashboard/bankbotvector.png"),
    banktop: require("../Assets/dashboard/banktopvector.png"),
    dashright1: require("../Assets/dashboard/dashright1.png"),
    ivector: require("../Assets/dashboard/ivector.png"),
    selectleft: require("../Assets/dashboard/selectleft.png"),
    botgraphvector: require("../Assets/dashboard/botgraphvector.png"),
}

export default Images