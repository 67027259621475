import React, { useState, useEffect } from 'react';
import { Button, Container } from 'react-bootstrap';
import Header from "../Layouts/header";
import Headermobile from "../Layouts/headermobile";
import Footer from "../Layouts/footer";
import Images from '../Components/Images';
import Sidebar from '../Layouts/sidebar';
import Countdown from "react-countdown";
import HistoryTable from '../Components/HistoryTable';
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router';

const History = () => {
    const Wallet_Details = useSelector((state) => state.wallet_connect_context);
    const history = useNavigate()
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }, []);

    const firstExample = {
        size: 23,
        value: 2.5,
        edit: false,
        color: "#fff",
        activeColor: "gold",
        isHalf: true,
    };

    return (
        <>

            <div className='dashboard animehead historyss ms1'>

                <div className='d-none d-lg-block'> <Header /></div>
                <div className='d-block d-lg-none'><Headermobile /></div>



                <div className='dashboardss'>
                    <img src={Images.topline} className='img-fluid topline w-100' />
                    <img src={Images.rightline} className='img-fluid rightline' />
                    <span style={{ background: Wallet_Details?.UserLevels?.Colour }} className='blugradient redgradient'></span>
                    <span style={{ background: Wallet_Details?.UserLevels?.Colour }} className='blugradient1 redgradient1'></span>
                    <span style={{ background: Wallet_Details?.UserLevels?.Colour }} className='blugradient blugradientss redgradient'></span>
                    <Sidebar />

                    <div className='alllist'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-7 mt-3 mt-sm-0 text-center overpos mx-auto'>
                                    <h1 className='mainheading font'>Weekly Balance</h1>
                                    <h5 className='mainheading font'>(125 weeks of activity)</h5>

                                </div>
                            </div>
                        </div>
                    </div>



                    {/* <div className='container'>
                        <div className='col-md-12 mt-5 mx-auto'>
                            <div className='mountain px-5'>

                            </div>
                        </div>
                    </div> */}

                    {/* 
                    <div className='container'>
                        <div className='col-md-12 mt-5 mx-auto'>
                            <div className='bankbackimg px-5'>
                            </div>
                        </div>
                    </div>  */}

                </div>

                <HistoryTable />


                <Footer />

            </div>

        </>
    )
}

export default History;