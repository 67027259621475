import React, { useState, useEffect } from 'react';
import { Button, Container } from 'react-bootstrap';
import Images from '../Components/Images';


const Nftcard = (props) => {


    return (

        <>

            <div className='nftcard px-0 py-3'>
                {console.log("Its NFTDetail Data", props)}
                <div className='buyimg'><p className='buytext mt-1 me-3'>Buy</p></div>
                <img src={Images?.cornerimg} className='cornerimg' />
                <p className='name mb-1 me-4'>{props?.datas?.tokenDesc}</p>
                <p className='ethname mb-3 me-4'><img src={Images.eth} className='eth me-2' /> <span>{props.datas.tokenPrice}</span> </p>
                <div className='text-center'><img src={props?.datas?.image} className='img-fluid cardimg' /></div>
                <div className='d-flex align-items-center mt-3 ms-4'>
                    <img src={props?.datas?.image} className='img-fluid profile' />
                    <div className='ms-2'>
                        <p className='subcardtext mb-1'>{props?.datas?.tokenName.slice(0,12)+".."}</p>
                        <p className='subcardtext mb-0'>{props?.datas?.subtext}</p>
                    </div>
                </div>
            </div>


        </>

    )
}

export default Nftcard;