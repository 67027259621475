import { axiosFunc } from '../Axios/Axios';
import Config from '../../Config/Config'
import { EncryptData, Decryptdata, AppenData } from '../Helper/EncHelper';

export const GetNFTs = async (data) => {
    console.log("Comming On GetNFTs");
    try {
        let senddata = {
            method: 'GET',
            url: `${Config.Back_URL}/Api/tokenApi/FetchNFT`,
        }
        let Resp = await axiosFunc(senddata)
        console.log("Response", Resp);
        return Resp;
    }
    catch (e) {
        console.log("Fetch NFt Catch Error", e)
        return { status: false }
    }
}

export const GetOrSave_Action = async (Payload) => {
    try {

        let senddata = {
            'method': 'post',
            'url': `${Config.Back_URL}/Api/tokenApi/GetSaveAddress`,
            'data': { data: EncryptData(Payload) }
        }
        console.log("senddata", senddata);
        let Resp = await axiosFunc(senddata)
        console.log("user_token", Resp);
        localStorage.setItem('user_token', Resp.RetData.Token);
        return {
            data: Resp.RetData
        }
    }
    catch (err) {
        console.log("GetOrSave_Action Function", err)
        return { status: false }
    }
}

export const GetProfiledata = async (payload) => {
    console.log("GetProfiledata", payload);
    var formdata = {}
    let senddata = {
        method: 'GET',
        url: `${Config.Back_URL}/Api/tokenApi/GetProfile`,
        params: { data: EncryptData(payload) }
    }
    console.log("senddata for Get Profile Info", senddata);
    let Resp = await axiosFunc(senddata)
    console.log("Response GetProfile", Resp);
    if (Resp.Success) {
        formdata['photo'] = Resp.UserDet.image || "";
        formdata['coverimage'] = Resp.UserDet.coverimage || "";
        formdata['Bio'] = Resp.UserDet.bio || "";
        formdata['CustomUrl'] = Resp.UserDet.customurl || "";
        formdata['Twitter'] = Resp.UserDet.twitter || "";
        formdata['Instagram'] = Resp.UserDet.instagram || "";
        formdata['Facebook'] = Resp.UserDet.facebook || "";
        formdata['Youtube'] = Resp.UserDet.youtube || "";
        formdata['Name'] = Resp.UserDet.name || "";
        formdata['Email'] = Resp.UserDet.email || "";
        formdata['verifiedmail'] = Resp.UserDet.verifiedmail || "";
        formdata['Site'] = Resp.UserDet.personalsite || "";
        formdata['curraddress'] = Resp.UserDet.curraddress || "";
        formdata['Follow'] = (Resp.Follow !== false) ? Resp.Follow : false
        formdata['Count'] = (Resp.Count) ? Resp.Count : false
        formdata['Joined'] = new Date(Resp.UserDet.date).toLocaleDateString('en-US', { month: "long", year: "numeric" }) || "";
    }
    return formdata
}

export const EditProfiledata = async (payload) => {
    var formData = new FormData();
    if (payload.File) { formData.append('Image', payload.File); }
    if (payload.photo) { formData.append('imagename', EncryptData(payload.photo)); }
    if (payload.Bio) { formData.append('Bio', EncryptData(payload.Bio)); }
    if (payload.Name) { formData.append('name', EncryptData(payload.Name)); }
    if (payload.CustomUrl) { formData.append('customurl', EncryptData(payload.CustomUrl)); }
    if ((payload.Email)) { formData.append('email', EncryptData(payload.Email)); }
    if (payload.curraddress) { formData.append('curraddress', EncryptData(payload.curraddress)); }
    if (payload.Facebook) { formData.append('facebook', EncryptData(payload.Facebook)); }
    if (payload.Twitter) { formData.append('twitter', EncryptData(payload.Twitter)); }
    if (payload.Instagram) { formData.append('instagram', EncryptData(payload.Instagram)); }
    if (payload.Youtube) { formData.append('youtube', EncryptData(payload.Youtube)); }
    if (payload.Site) { formData.append('site', EncryptData(payload.Site)); }

    let senddata = {
        'method': 'post',
        'url': `${Config.Back_URL}/Api/tokenApi/EditProfile`,
        // 'data': { data: EncryptData(Payload) }
        'data': formData,
        'headers': {
            // 'Authorization': tok,
            'content-type': 'multipart/form-data'
        }
    }
    let Resp = await axiosFunc(senddata)
    console.log("Response", Resp);
    return Resp
}

export const TreeGraphApi = async (data) => {
    console.log("Comming On TreeGraph");
    try {
        let senddata = {
            method: 'GET',
            url: `${Config.Back_URL}/Api/tokenApi/TreeGraph`,
            params: { data: EncryptData(data) }
        }
        let Resp = await axiosFunc(senddata)
        console.log("Response", Resp);
        return Resp;
    }
    catch (e) {
        console.log("Fetch NFt Catch Error", e)
        return { status: false }
    }
}

export const GetAdminDetails = async (payload) => {
    console.log("GetProfiledata", payload);
    let senddata = {
        method: 'GET',
        url: `${Config.Back_URL}/Api/tokenApi/NFTCreatorDetails`,
        //params: { data: EncryptData(payload) }
    }
    console.log("senddata for Get Profile Info", senddata);
    let Resp = await axiosFunc(senddata)
    console.log("Response GetProfile", Resp);
    return Resp
}

export const PurchaseNFTApi = async (payload) => {
    console.log("payload", payload);
    let senddata = {
        'method': 'post',
        'url': `${Config.Back_URL}/Api/tokenApi/BuyNowNFT`,
        'data': { data: EncryptData(payload) },
        // 'headers': {
        //     'Authorization': tok
        // }
    }
    let Resp = await axiosFunc(senddata)
    console.log("Response", Resp);
    return Resp
}


export const IncomeAndNode = async (data) => {
    console.log("Comming On IncomeAndNode", data);
    try {
        let senddata = {
            method: 'GET',
            url: `${Config.Back_URL}/Api/tokenApi/TransactionsAndRewards`,
            params: { data: EncryptData(data) }
        }
        let Resp = await axiosFunc(senddata)
        console.log("TransactionsAndRewards", Resp);
        return Resp;
    }
    catch (e) {
        console.log("TransactionsAndRewards Catch Error", e)
        return { status: false }
    }
}

export const BinaryBalance = async (payload) => {
    console.log("payload", payload);
    let senddata = {
        'method': 'post',
        'url': `${Config.Back_URL}/Api/tokenApi/userInvestment`,
        'data': { data: EncryptData(payload) },
        // 'headers': {
        //     'Authorization': tok
        // }
    }
    let Resp = await axiosFunc(senddata)
    console.log("Response", Resp);
    return Resp
}

export const WithDrawAmount = async (payload) => {
    console.log("payload", payload);
    let senddata = {
        'method': 'post',
        'url': `${Config.Back_URL}/Api/tokenApi/WithDrawAmount`,
        'data': { data: EncryptData(payload) },
        // 'headers': {
        //     'Authorization': tok
        // }
    }
    let Resp = await axiosFunc(senddata)
    console.log("Response", Resp);
    return Resp
}

export const Livegraph = async (data) => {
    console.log("Comming On Livegraph", data);
    try {
        let senddata = {
            method: 'GET',
            url: `${Config.Back_URL}/Api/tokenApi/Livegraph`,
            params: { data: EncryptData(data) }
        }
        let Resp = await axiosFunc(senddata)
        console.log("Livegraph", Resp);
        return Resp;
    }
    catch (e) {
        console.log("TransactionsAndRewards Catch Error", e)
        return { status: false }
    }
}

export const DailyTranscationRec = async (data) => {
    console.log("Comming On Livegraph", data);
    try {
        let senddata = {
            method: 'GET',
            url: `${Config.Back_URL}/Api/tokenApi/DailyRewardCal`,
            params: { data: EncryptData(data) }
        }
        let Resp = await axiosFunc(senddata)
        console.log("DailyRewardCal", Resp);
        return Resp;
    }
    catch (e) {
        console.log("DailyRewardCal Catch Error", e)
        return { status: false }
    }
}

export const LevelUpData = async (data) => {
    console.log("Comming On LevelUpData", data);
    try {
        let senddata = {
            method: 'post',
            url: `${Config.Back_URL}/Api/tokenApi/LevelUp`,
            data: { data: EncryptData(data) }
        }
        let Resp = await axiosFunc(senddata)
        console.log("DailyRewardCal", Resp);
        return Resp;
    }
    catch (e) {
        console.log("DailyRewardCal Catch Error", e)
        return { status: false }
    }
}

export const CheckReferred = async (data) => {
    console.log("Comming On CheckReferred", data);
    try {
        let senddata = {
            method: 'GET',
            url: `${Config.Back_URL}/Api/tokenApi/CheckReferred`,
            params: { data: EncryptData(data) }
        }
        let Resp = await axiosFunc(senddata)
        console.log("CheckReferredCheckReferred", Resp);
        return Resp;
    }
    catch (e) {
        console.log("CheckReferred Catch Error", e)
        return { status: false }
    }
}

export const DiscoLight = async (data) => {
    console.log("Comming On Livegraph", data);
    try {
        let senddata = {
            method: 'GET',
            url: `${Config.Back_URL}/Api/tokenApi/DiscoChart`,
            params: { data: EncryptData(data) }
        }
        let Resp = await axiosFunc(senddata)
        console.log("DailyRewardCal", Resp);
        return Resp;
    }
    catch (e) {
        console.log("DailyRewardCal Catch Error", e)
        return { status: false }
    }
}

export const CheckReferalCode = async (data) => {
    console.log("Comming On CheckReferredCheckReferred", data);
    try {
        let senddata = {
            method: 'GET',
            url: `${Config.Back_URL}/Api/tokenApi/CheckReferCode`,
            params: { data: EncryptData(data) }
        }
        let Resp = await axiosFunc(senddata)
        console.log("CheckReferredCheckReferre", Resp);
        return Resp;
    }
    catch (e) {
        console.log("CheckReferred Catch Error", e)
        return { status: false }
    }
}

export const FindCurrentLevelUser = async (data) => {
    console.log("Comming On CheckReferredCheckReferred", data);
    try {
        let senddata = {
            method: 'GET',
            url: `${Config.Back_URL}/Api/tokenApi/FindCurrentLevelUser`,
            params: { data: EncryptData(data) }
        }
        let Resp = await axiosFunc(senddata)
        console.log("CheckReferredCheckReferre", Resp);
        return Resp;
    }
    catch (e) {
        console.log("CheckReferred Catch Error", e)
        return { status: false }
    }
}

export const CheckLevels3 = async (data) => {
    console.log("Comming On CheckLevels3", data);
    try {
        let senddata = {
            method: 'GET',
            url: `${Config.Back_URL}/Api/tokenApi/CheckLevels3`,
            params: { data: EncryptData(data) }
        }
        let Resp = await axiosFunc(senddata)
        console.log("CheckLevels3", Resp);
        return Resp;
    }
    catch (e) {
        console.log("CheckLevels3 Catch Error", e)
        return { status: false }
    }
}
