import Config from '../../Config/Config'
import Web3 from 'web3';
export const useWeb3 = async (Wallet_Details) => {
    console.log("Wallet_Details", Wallet_Details);
    if (localStorage.walletConnectType == 'wc' && localStorage.walletconnect != null) {
        var provider = Config.Provider
        const web3 = new Web3(provider);
        return Wallet_Details;
    }
    else if (localStorage.walletConnectType && localStorage.walletConnectType != null && localStorage.walletConnectType == 'MetaMask') {
        var provider = window.ethereum;
        const web3 = new Web3(provider);
        console.log("web3web3web3web3web3web3web3", web3);
        return web3;

    } else {
        var provider = Config.Provider
        const web3 = new Web3(provider);
        return Wallet_Details;
    }
}