import { useMethodsContract, useTokenApprove, useWithdraw } from "./ContractMethods";
import { useWeb3 } from './Web3'
import Config from '../../Config/Config'
import Web3 from "web3";

export const useReciptCommon = async (Details) => {
    console.log("data args", Details)
    const web3InRpc = await useWeb3(Config.RPC_URL)
    try {
        const Hash = await useMethodsContract(Details)
        console.log("HashHashHash", Hash);
        const receipt = await web3InRpc.eth.getTransactionReceipt(Hash);
        console.log("receiptreceipt", receipt);
        return receipt;
    }
    catch (error) {
        return false
    }
}

export const useTokenApproveReceipt = async (Details) => {
    console.log("Details for Token", Details);
    // var web3InRpc = new Web3(Config.Provider)
    const web3InRpc = await useWeb3(Config.RPC_URL)
    console.log("web3InRpc", web3InRpc);
    try {
        const Hash = await useTokenApprove(Details)
        const receipt = await web3InRpc.eth.getTransactionReceipt(Hash);
        return receipt;
    }
    catch (error) {
        console.log("useTokenAppReceipt-Hookerror", error)
        return false
    }
}

export const useWithdrawAmount = async (Details) => {
    console.log("Details for Token", Details);
    // var web3InRpc = new Web3(Config.Provider)
    const web3InRpc = await useWeb3(Config.RPC_URL)
    console.log("web3InRpc", web3InRpc);
    try {
        const Hash = await useWithdraw(Details)
        const receipt = await web3InRpc.eth.getTransactionReceipt(Hash);
        return receipt;
    }
    catch (error) {
        console.log("useTokenAppReceipt-Hookerror", error)
        return false
    }
}