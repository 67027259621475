import React, { useEffect, useState } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { MdKeyboardDoubleArrowLeft } from "react-icons/md";
import Images from "./Images";
import { useSelector } from "react-redux";
import config from '../Config/Config'
import { IncomeAndNode } from '../Action/Controller/Token'
import { useNavigate } from "react-router";
const moment = require('moment');

const transactionHistoryData = [
  {
    id: 1,
    name: "All Transactions",
    MyUnderstand: "All Transactions"
  },
  {
    id: 2,
    name: "Binary",
    MyUnderstand: "Referral"
  },
  {
    id: 3,
    name: "MLM",
    MyUnderstand: "MLM"
  },
  {
    id: 4,
    name: "Withdraw",
    MyUnderstand: "Withdraw"
  },
];

const HistoryTable = () => {
  const Wallet_Details = useSelector((state) => state.wallet_connect_context);
  const history = useNavigate()
  console.log("Wallet_Details_Dashboard", Wallet_Details);
  const [tableName, setTableName] = useState("All Transactions");
  const columns = [
    {
      key: "Message",
      text: "Type",
      className: "tableWeek",
      align: "center",
      sortable: false,
    },
    {
      key: "created",
      text: "Date",
      className: "tableDateRange",
      align: "center",
      sortable: false,
      cell: (record) => {
        return (
          <>
            {moment(record?.created).format('MM/DD/YYYY h:mm:ss A')}
          </>
        );
      },
    },
    // {
    //   key: "ReferalType",
    //   text: "Status",
    //   className: "tableWeek",
    //   align: "center",
    //   sortable: false,
    // },
    {
      key: "Amount",
      text: "Invested/Reward Earned",
      className: "tableLeftBalance",
      align: "center",

      sortable: false,
    },
    {
      key: "From",
      text: "Invested Address /Referal Address",
      className: "tableRightBalance",
      align: "center",
      sortable: false,
    },
  ];

  const config = {
    page_size: 5,
    length_menu: [10, 20, 50],
    filename: "Users",
    no_data_text: "No user found!",

    language: {
      length_menu: "Show _MENU_ result per page",
      filter: "Filter in records...",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        first: "First",
        previous: <>  <MdKeyboardDoubleArrowLeft /> </>,
        next: <>  <MdKeyboardDoubleArrowRight /></>,
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
    defaultSortAsc: true,
  };

  const [records, setrecords] = useState([
    {
      id: "1",
      week: "125",
      dataRange: "2/17/2024 - 2/24/2024",
      leftBalance: "0",
      rightBalance: "0",
      gainedBalance: " 0",
      commission: "0 BCN",
    },
    {
      id: "2",
      week: "125",
      dataRange: "2/17/2024 - 2/24/2024",
      leftBalance: "0",
      rightBalance: "0",
      gainedBalance: " 0",
      commission: "0 BCN",
    },
    {
      id: "3",
      week: "125",
      dataRange: "2/17/2024 - 2/24/2024",
      leftBalance: "0",
      rightBalance: "0",
      gainedBalance: " 0",
      commission: "0 BCN",
    },
    {
      id: "4",
      week: "125",
      dataRange: "2/17/2024 - 2/24/2024",
      leftBalance: "0",
      rightBalance: "0",
      gainedBalance: " 0",
      commission: "0 BCN",
    },
    {
      id: "5",
      week: "125",
      dataRange: "2/17/2024 - 2/24/2024",
      leftBalance: "0",
      rightBalance: "0",
      gainedBalance: " 0",
      commission: "0 BCN",
    },
    {
      id: "6",
      week: "125",
      dataRange: "2/17/2024 - 2/24/2024",
      leftBalance: "0",
      rightBalance: "0",
      gainedBalance: " 0",
      commission: "0 BCN",
    },
    {
      id: "7",
      week: "125",
      dataRange: "2/17/2024 - 2/24/2024",
      leftBalance: "0",
      rightBalance: "0",
      gainedBalance: " 0",
      commission: "0 BCN",
    },
    {
      id: "8",
      week: "125",
      dataRange: "2/17/2024 - 2/24/2024",
      leftBalance: "0",
      rightBalance: "0",
      gainedBalance: " 0",
      commission: "0 BCN",
    },
    {
      id: "9",
      week: "125",
      dataRange: "2/17/2024 - 2/24/2024",
      leftBalance: "0",
      rightBalance: "0",
      gainedBalance: " 0",
      commission: "0 BCN",
    },
    {
      id: "10",
      week: "125",
      dataRange: "2/17/2024 - 2/24/2024",
      leftBalance: "0",
      rightBalance: "0",
      gainedBalance: " 0",
      commission: "0 BCN",
    },
  ])
  const [MyUnderstandName, setMyUnderstandName] = useState("All Transactions")
  const columnsTwo = [
    {
      key: "number",
      text: "No",
      className: "historyNumber",
      align: "center",
      sortable: false,
      cell: (row, index, column, id) => <p>{index + 1}</p>,
    },
    {
      key: "created",
      text: "Date",
      className: "historyDate",
      align: "center",
      sortable: false,
      cell: (record) => {
        return (
          <>
            {moment(record?.created).format('MM/DD/YYYY h:mm:ss A')}
          </>
        );
      },
    },
    {
      key: "Amount",
      text: "Invested/Reward Earned",
      className: "historyAmount",
      align: "center",

      sortable: false,
    },
    {
      key: "Message",
      text: "Type",
      className: "history type",
      align: "center",
      sortable: false,
    },
    {
      key: "From",
      text: "Invested Address /Referal Address",
      className: "historyTransaction",
      sortable: false,
      align: "center",
    },
  ];

  const transactionRecords = [
    {
      id: 1,
      number: 1,
      date: "09/26/2021   3:04:25 PM",
      amount: <p className="text-danger mb-0">-4000 BCN</p>,
      type: "Withdraw",
      transaction: "93c74...6bc4a",
    },
    {
      id: 2,
      number: 2,
      date: "09/26/2021   3:04:25 PM",
      amount: <p className="text-success mb-0">+4000 BCN</p>,
      type: "Withdraw",
      transaction: "93c74...6bc4a",
    },
  ];
  const binaryRecords = [
    {
      id: 1,
      number: 1,
      date: "10/15/2024   5:04:25 PM",
      amount: <p className="text-danger mb-0">-6000 BCN</p>,
      type: "Withdraw",
      transaction: "93c74...6bc4a",
    },
    {
      id: 2,
      number: 2,
      date: "10/15/2024   5:04:25 PM",
      amount: <p className="text-success mb-0">+6000 BCN</p>,
      type: "Withdraw",
      transaction: "93c74...6bc4a",
    },
  ];

  const editRecord = () => {
    console.log();
  };
  const deleteRecord = () => {
    console.log();
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const handleTransactionHistory = (name, MyUnderstand) => {
    console.log("name, MyUnderstand", name, MyUnderstand);
    setTableName(name);
    setMyUnderstandName(MyUnderstand)
  };

  useEffect(() => {
    NodeAndRewards()
  }, [Wallet_Details.UserAccountAddr])

  const NodeAndRewards = async () => {
    console.log("TreeGraphApi", Wallet_Details.UserAccountAddr);
    if (Wallet_Details.UserAccountAddr) {
      var Payload = { user: { curraddress: Wallet_Details.UserAccountAddr } }
      var Resp = await IncomeAndNode(Payload)
      console.log("Resp From Treee Transsssssss", Resp);
      if (Resp?.status) {
        setrecords(Resp.FindTransak)
      }
    }

  }

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-9 mt- mt-lg-4 mt-xl-0 py-5">
          {/* {" "} */}
          <div className="custom_react_datatable custom_table_bg">
            <ReactDatatable
              config={config}
              records={records}
              columns={columns}
            // config={configdata}
            // extraButtons={this.extraButtons}
            />

            {/* <div className="d-flex align-items-center justify-content-center justify-content-sm-end gap-1 p-sm-2 p-md-3 p-xl-4 mt-3 mt-xl-0">
              <button className="tab_pagn_btns border-0 p-0 d-flex align-items-center justify-content-center tab_pagn_hover">
                <MdKeyboardDoubleArrowLeft />
              </button>

              <button className="table_pagination_count   bg-light">1</button>
              <button className="table_pagination_count   bg-light">2</button>
              <button className="table_pagination_count   bg-light">3</button>
              <button className="table_pagination_count  bg-light">...</button>
              <button className="table_pagination_count  bg-light">14</button>
              <button className="tab_pagn_btns border-0 p-0 d-flex align-items-center justify-content-center">
                <MdKeyboardDoubleArrowRight />
              </button>
            </div> */}


          </div>
        </div>
      </div>

      {/* Transaction history */}
      <div className="row trans_hist py-5">
        <div className="col-md-5 col-lg-3">
          <div className="trans_hist_wrpr">
            <ul className="p-0 mb-0">
              {transactionHistoryData.map((val) => (
                <li
                  key={val.id}
                  className={`${tableName === val.name ? "active" : ""}`}
                  onClick={() => handleTransactionHistory(val.name, val.MyUnderstand)}
                >
                  {val.name}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="col-md-7 col-lg-9 ps-xl-0 mt-4 mt-md-0">
          <div className="trans_hist_table_bg">
            {" "}
            {console.log("records.filte", MyUnderstandName, records.filter((word) => MyUnderstandName == "All Transactions" ? word : word.Message == MyUnderstandName))}
            <ReactDatatable
              // config={config}
              records={MyUnderstandName == "All Transactions" ? records : records.filter((word) => word.Message == MyUnderstandName)}
              columns={columnsTwo}
            // extraButtons={this.extraButtons}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default HistoryTable;
