import { useEffect, useState } from "react";
import ChartViewer from "../Components/chartviewer";
import { Livegraph } from '../Action/Controller/Token'
import { useSelector, useDispatch } from "react-redux";
import isEmpty from '../Config/isEmpty'
export default function Chartsrealtime() {
  const Wallet_Details = useSelector((state) => state.wallet_connect_context);
  const [data, setData] = useState();

  useEffect(() => {
    const interval = setInterval(() => {
      const val = Math.floor(Math.random() * (100 - 30 + 1)) + 30;
      let array = [...data, val];
      array.shift();
      setData(array);
    }, 2000);
    return () => {
      window.clearInterval(interval); // clear the interval in the cleanup function
    };
  }, [data]);

  useEffect(() => {
    NodeAndRewards()
  }, [Wallet_Details.UserAccountAddr])

  const NodeAndRewards = async () => {
    console.log("Liveeeeeeee", Wallet_Details.UserAccountAddr);
    if (Wallet_Details.UserAccountAddr) {
      var Payload = { user: { curraddress: Wallet_Details.UserAccountAddr } }
      var Resp = await Livegraph(Payload)
      console.log("Resp From LiceChareddddd", Resp);
      if (!isEmpty(Resp?.LiveChart)) {
        console.log("qweqweqweqweqwe");
        setData(Resp?.LiveChart[0]?.earnings)
        // setData(Resp?.LiveChart[0]?.earnings)
      } else {
        console.log("logggg elseeee");
        setData([35, 51, 49, 62, 69, 91, 26, 16, 74, 43, 81, 44])
      }
    } else {
      setData([35, 51, 49, 62, 69, 91, 26, 16, 74, 43, 81, 44])
    }

  }

  return (
    <div className="Appss">
      <ChartViewer data={data} />
    </div>
  );
}
