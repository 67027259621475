import React, { useState, useEffect } from 'react';
import { Button, Container } from 'react-bootstrap';
import Header from "../Layouts/header";
import Headermobile from "../Layouts/headermobile";
import Footer from "../Layouts/footer";
import Images from '../Components/Images';
import Sidebar from '../Layouts/sidebar';
import Countdown from "react-countdown";
import { QRCode } from 'react-qrcode-logo';
import CopyToClipboard from "react-copy-to-clipboard";
import { FiCopy } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { Flip, toast } from 'react-toastify';
import Config from '../Config/Config'
import { getCmsContent } from '../Action/Axios/cmsaxios';

const Referral = () => {
    const Wallet_Details = useSelector((state) => state.wallet_connect_context);
    const [notes, setnotes] = useState([])
    console.log("Wallet_Details", Wallet_Details);
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
        Getandnotes();
    }, []);
    const Getandnotes = async () => {
        var resp = await getCmsContent("notes");
        console.log("mybank_notesdf", resp.data);
        if (resp?.status) setnotes(resp?.data);
    };
    const firstExample = {
        size: 23,
        value: 2.5,
        edit: false,
        color: "#fff",
        activeColor: "gold",
        isHalf: true,
    };

    return (
        <>

            <div className='dashboard animehead refs ms3'>

                <div className='d-none d-lg-block'> <Header /></div>
                <div className='d-block d-lg-none'><Headermobile /></div>



                <div className='dashboardss'>
                    <img src={Images.topline} className='img-fluid topline w-100' />
                    <img src={Images.rightline} className='img-fluid rightline' />
                    <span style={{ background: Wallet_Details?.UserLevels?.Colour }} className='blugradient redgradient'></span>
                    <span style={{ background: Wallet_Details?.UserLevels?.Colour }} className='blugradient1 redgradient1'></span>
                    <span style={{ background: Wallet_Details?.UserLevels?.Colour }} className='blugradient blugradientss redgradient'></span>
                    <Sidebar />
                    <div className='alllist'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-9 col-lg-7 mt-3 mt-xl-0 text-center overpos mx-auto'>
                                    <h4 className='mainheading mb-5 px-5 px-sm-0'>My Referral Code Informations</h4>
                                    <div className='overpos qrs pt-4 pb-4'>
                                        <QRCode value={Wallet_Details?.User?.Referral ? Config.Front_URL + Wallet_Details?.User?.Referral : Config.Front_URL + Config.AdminReferralKey}
                                         logoImage={Images.logoicon} logoWidth="52" logoHeight="52" fgColor="#000" removeQrCodeBehindLogo={false} 
                                         />
                                        {/* <QRCodeSVG size={190} value={Wallet_Details?.User?.Referral ? Config.Front_URL + Wallet_Details?.User?.Referral : Config.Front_URL + Config.AdminReferralKey} /> */}
                                    </div>

                                    <div className='row mt-5'>
                                        <div className='col-md-6 mx-auto mt-3 text-start'>
                                            <h5 className='text-center  mainheading'>My referral UID</h5>
                                            <CopyToClipboard text={Wallet_Details?.User?.Referral ? Config.Front_URL + Wallet_Details?.User?.Referral : Config.Front_URL + Config.AdminReferralKey}
                                                onCopy={() =>
                                                    toast.success("Copied Successfully", {
                                                        closeButton: true,
                                                        autoClose: 1500,
                                                        transition: Flip,
                                                        theme: "dark",
                                                    })
                                                }
                                            >
                                                <div className='text-start d-flex align-items-center justify-content-center'> <p className='bluclr mb-0 me-2'>{Wallet_Details?.User?.Referral ? Config.Front_URL + Wallet_Details?.User?.Referral : Config.Front_URL + Config.AdminReferralKey}</p><FiCopy className='ficpoy' /></div>
                                            </CopyToClipboard>
                                        </div>
                                        {/* <div className='col-md-6 mt-3 marketplace_section text-center'>

                                            <CopyToClipboard text={'http:/polynyx.com/register?ba521331'}>
                                                <div className='text-lg-start d-flex align-items-center justify-content-center'> <p className='bluclr mb-0 me-2'>http:/polynyx.com/register?ba521331</p> <FiCopy className='ficpoy' /></div>
                                            </CopyToClipboard>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className='container'>
                    <div className='col-md-12 mt-0 mt-sm-5 mx-auto'>
                        <div className='bankbackimg px-3 px-md-5'>
                            <div className='notebackimg mt-2 mt-md-5 py-4 px-4'>
                                <h4 className='text-start mainheading mb-3'>{notes?.heading}</h4>
                                <ul>
                                    <div dangerouslySetInnerHTML={{ __html: notes?.description }}></div>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>


                <Footer />

            </div>

        </>
    )
}

export default Referral;