import React, { useRef, useEffect, useState } from "react";
import { Button, Container, Row, Nav, NavDropdown, Navbar, Modal } from 'react-bootstrap';
import Images from "../Images";
import { useDispatch, useSelector } from "react-redux";
import { useReciptCommon, useTokenApproveReceipt } from '../../Action/Hooks/Recipt'
import Web3 from "web3";
import Config from '../../Config/Config'
import { toast } from 'react-toastify'
import { PurchaseNFTApi, BinaryBalance } from '../../Action/Controller/Token'
import { useNavigate } from "react-router";
const Web3Utils = require('web3-utils');

export default function Buymodal(props) {
    console.log("Propssss BuyModel", props);
    const history = useNavigate()
    const Wallet_Details = useSelector((state) => state.wallet_connect_context);
    const NFTDet = props?.state
    console.log("Wallet_Details", Wallet_Details, NFTDet);
    const [buymodal, setBuymodal] = useState(true);
    const [step, setStep] = useState(1)
    const [BtnDis, setBtnDis] = useState("Start")
    const [Quantity, setQuantity] = useState(1)
    const [Validation, setValidation] = useState(true)
    const [ValidationErr, setValidationErr] = useState(true)
    const [MaintainStatus, setMaintainStatus] = useState("start")

    const PurchaseNFT = async () => {
        setMaintainStatus("Process")
        var WebNew = new Web3(Config.RPC_URL)
        console.log("Validation", Validation);
        var totPrice1 = NFTDet.tokenPrice * (Quantity ? Quantity : 1)
        const web3 = new Web3();
        const chainId = await window.ethereum.networkVersion;
        console.log("chainId chainId parseInt", chainId);
        if (!chainId) {
            return toast.error("Cannot retrieve network. Please refresh the browser.");
        }
        if (chainId !== Config?.ChainID) {
            return toast.error(`Connect to ${Config?.NetworkName}`)
        }

        if (Number(Wallet_Details.UserAccountBal) == 0) {
            setMaintainStatus("start")
            return toast.error("Insufficient Currency To Purchase")
        }
        if (!(Number(Wallet_Details.TokenBalance) >= Number(totPrice1))) {
            setMaintainStatus("start")
            return toast.error("Insufficient Token To Purchase")
        }
        if (Validation == false) {
            setMaintainStatus("start")
            return toast.error("Validation Failed")
        }
        console.log("sdfsdaasdasdasdasd", NFTDet.tokenPrice);
        console.log("reqqqqqqqBodyyyyyyyyyyy", NFTDet, Web3Utils.toWei(NFTDet.tokenPrice, "ether"), NFTDet.tokenPrice);
        setBtnDis("On Process")
        //Wallet_Details?.RefDetails?.ToAddress
        var totPrice = NFTDet.tokenPrice * (Quantity ? Quantity : 1)
        //Wallet_Details?.HowRefered?.FromAddress
        var ContractData = {
            Wallet_Details: Wallet_Details,
            Method: "AddUser",
            Data: [NFTDet.tokenID, Web3Utils.toWei(totPrice, "ether"), Wallet_Details?.RefDetails?.FromAddress, Quantity ? Quantity : 1],
            SendData: true,
            Price: Web3Utils.toWei(NFTDet.tokenPrice, "ether")
        }
        console.log("ContractData", ContractData);
        var TokenAprrove = await TokenApprove()
        console.log("TokenAprrove", TokenAprrove);
        if (TokenAprrove) {
            const id = toast.loading("Initiating Purchase NFT", { closeButton: true });
            var ContractCall = await ReciptCommon(ContractData)
            console.log("ContractCall", ContractCall);
            // var route = String(ContractCall?.logs[2]?.data)
            // var sliceee = route?.slice(2)
            // console.log("qwewqeqweqweqweqw", sliceee);
            // var lengthuh = sliceee?.length / 2

            // var Status = Web3.utils.hexToNumber("0x" + sliceee?.slice(0, lengthuh))
            // var ReferalReward = Web3.utils.hexToNumber("0x" + sliceee?.slice(lengthuh))
            // console.log("qweqweqweqweqwe", Status, ReferalReward);
            // var TORewardGo = ContractCall?.logs[2]?.topics[2];

            // var RewardTo = WebNew.eth.abi.decodeParameter("address", TORewardGo);

            //Web3Utils.hexT(TORewardGo);
            // console.log("TORewardGo", TORewardGo, Quantity);
            if (ContractCall.status) {
                var SendDataToCall = {
                    NewOwner: Wallet_Details.UserAccountAddr,
                    NFTDet: NFTDet,
                    // ReferalReward: Web3Utils.fromWei(ReferalReward, "ether"),
                    //Status: Status,
                    Message: "Not Calimed",
                    // RewardTo: RewardTo.toLowerCase(),
                    QunatityPur: Quantity ? Quantity : 1,
                    AmountPur: (NFTDet?.tokenPrice) * (Quantity ? Quantity : 1),
                    QrignalAmount: NFTDet?.tokenPrice,
                    tokenID: NFTDet?.tokenID,
                    tokenOwner: NFTDet?.tokenOwner
                }
                var Key = { value: JSON.stringify(SendDataToCall) }
                var SaveNFT = await PurchaseNFTApi(Key)
                console.log("SaveNFT", SaveNFT);
                if (SaveNFT.Success) {
                    var TreeBalance = await BinaryBalance(Key)
                    if (TreeBalance.Success) {
                        toast.update(id, { render: "NFT Purchased Successfully", type: "success", isLoading: false, autoClose: 2500, });
                        setBuymodal(false)
                        setTimeout(() => {
                            history('/dashboard')
                        }, 2500)
                    } else {
                        toast.update(id, { render: "NFT Purchased Successfully", type: "success", isLoading: false, autoClose: 2500, });
                        setBuymodal(false)
                        setTimeout(() => {
                            history('/dashboard')
                        }, 2500)
                    }
                } else {
                    toast.update(id, { render: "Transaction Failed To Update", type: "error", isLoading: false, autoClose: 2500, });
                    setBuymodal(false)
                    setMaintainStatus("start")
                    setTimeout(() => {
                        history('/dashboard')
                    }, 2500)
                }
            } else {
                setMaintainStatus("start")
                toast.update(id, { render: "Transaction Failed", type: "error", isLoading: false, autoClose: 2500, });
            }
        }
    }

    const TokenApprove = async () => {
        console.log("TokenApprove", Wallet_Details, NFTDet.tokenPrice);
        const id = toast.loading("Approve Initiated", { closeButton: true });
        var totalAmt = (NFTDet.tokenPrice) * (Quantity ? Quantity : 1)
        console.log("totalAmttotalAmt", totalAmt);
        var Arg = { Coinname: Config.TokenName, NFTOwner: NFTDet.tokenOwner, tokenID: NFTDet.tokenID, PriceWithoutSerfee: Web3Utils.toWei(totalAmt, "ether"), PriceWithSerfee: Web3Utils.toWei(totalAmt, "ether"), Status: true, Provider: Wallet_Details }
        var Receipt = await useTokenApproveReceipt(Arg)
        console.log("ReceiptReceipt", Receipt);
        if (Receipt.status) {
            console.log("Receipt on condition", Receipt)
            toast.update(id, { render: "Token Amount Approved Successfully", type: "success", isLoading: false, autoClose: 2500, });
            return Receipt

        } else {
            toast.update(id, { render: "Transaction Failed", type: "error", isLoading: false, autoClose: 2500, });
            setBuymodal(false)
            return false
        }
    }

    const ReciptCommon = async (ContractData) => {
        let ContractCallInter = await useReciptCommon(ContractData)
        console.log("ContractCall", ContractCallInter);
        return ContractCallInter
    }

    const QuantityValidAndSet = async (Quan) => {
        var Quant = /^[0-9]+$/.test(Quan)
        console.log("Quant", Quant);
        var NumDigitOnly = /[^0-9\.]/g
        var NumANdDotOnly12 = Quan.replace(NumDigitOnly, '')
        console.log("NumANdDotOnly12", NumANdDotOnly12, NumANdDotOnly12 !== 0, typeof NumANdDotOnly12, Quantity);
        //if(Quantity == 0)
        if (Quant) {
            if (Number(NumANdDotOnly12) !== 0) {
                console.log("NFTDet.quantity >= Quant", NFTDet.quantity, NumANdDotOnly12, NFTDet.quantity >= NumANdDotOnly12);
                if (NumANdDotOnly12) {
                    if (NFTDet.quantity >= NumANdDotOnly12) {
                        setQuantity(NumANdDotOnly12)
                        setValidation(true)
                        setValidationErr("")
                    } else {
                        setQuantity(NumANdDotOnly12)
                        setQuantity(1)
                        setValidation(false)
                        setValidationErr("Enter Quantity is Greater than NFT Quantity")
                    }
                } else {
                    setQuantity(NumANdDotOnly12)
                    setValidation(false)
                    setValidationErr("Quantity must be greater than 1")
                }
            } else {
                setQuantity(NumANdDotOnly12)
                setValidation(false)
                setValidationErr("Quantity must be greater than 0")
            }
        } else {
            setQuantity(NumANdDotOnly12)
            setValidation(false)
            setValidationErr("Enter Valid Quantity")
        }

    }
    console.log("Errrrrrrrrr", ValidationErr, Validation);

    return (

        <div>

            <div className="walletlist">
                <Modal
                    className="walletmodal"
                    // onHide={props.onDismiss}
                    show={buymodal}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Header closeButton onClick={props.onDismiss}></Modal.Header>
                    <Modal.Body className="pb-5 px-4">
                        <div className={step == 1 ? 'step1' : "d-none"}>local
                            <h2 className='text-center mainheading mb-4'>Checkout</h2>
                            <div className="d-flex justify-content-between">
                                <div><p className="mheading mb-1">Seller</p><p className="mtext">{NFTDet?.tokenCreator?.slice(0, 14)}</p></div>
                                <div><p className="mheading mb-1">Buyer</p><p className="mtext">{Wallet_Details?.UserAccountAddr?.slice(0, 14)}</p></div>
                            </div>

                            <div className='marketplace_section'>
                                <h5 className='text-center inputname'>{NFTDet?.quantity} NFTs Available</h5>
                                <div className="row mt-3 text-center">
                                    <div className='col-md-10 mb-4 mx-auto'>
                                        <h4 className='text-center inputname'>Quantity</h4>
                                        <input type="text" value={Quantity} id="text" class="form-control user" placeholder="Quantity" onChange={(e) => QuantityValidAndSet(e.target.value)} />
                                        <span className="redclr text-start mt-2 me-auto">{ValidationErr}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <p className="mtext fw500 mb-1">Price of the NFT</p><p className="mtext text-center fw500">{NFTDet?.tokenPrice} POLYNYX</p>

                                {/* <p className="mtext text-center fw500">{NFTDet?.tokenPrice} POLYNYX</p> */}
                            </div>
                            <div className="d-flex justify-content-between">
                                <p className="mtext fw500 mb-1">Your Balance</p><p className="mtext">{Number(Wallet_Details?.UserAccountBal)?.toFixed(5)} MATIC</p>
                            </div>
                            <div className="d-flex justify-content-between">
                                <p className="mtext fw500 mb-1">Your Token Balance</p><p className="mtext">{Number(Wallet_Details?.TokenBalance)?.toFixed(2)} {Config.TokenName}</p>
                            </div>
                            {/* <div className="d-flex justify-content-between">
                                <p className="mtext fw500 mb-1">Service fee</p><p className="mtext">2.5%</p>
                            </div> */}
                            <div className="d-flex justify-content-between">
                                <p className="mtext fw500 mb-1">Amount to be paid</p><p className="mtext">{NFTDet?.tokenPrice * Quantity}</p>
                            </div>
                            <div className='mt-4 nftbtn position-relative text-center'>
                                <img src={Images.buyntnbtn} className='buyntnbtn img-fluid' />
                                <h4 className='text-start mainheading process paynft' onClick={() => Validation && Quantity !== 0 ? setStep(2) : toast.error("Validation Failed")}>Process to payment</h4>
                            </div>
                            <div className='mt-4 nftbtn position-relative text-center' onClick={props.onDismiss}>
                                <img src={Images.buyntnbtn} className='buyntnbtn img-fluid' />
                                <h4 className='text-start mainheading process paynft'>Cancel</h4>
                            </div>
                            {/* <div className="text-center"><Button onClick={props.onDismiss} className="btn cancelbtn mt-3">Cancel</Button></div> */}
                        </div>
                        <div className={step == 2 ? 'step2' : "d-none"}>
                            <h2 className='text-center mainheading mb-4'>Follow Steps</h2>
                            <p className="mtext text-center fw500 mb-1">Purchase</p>
                            <p className="mtext text-center fw500">Send transaction with your wallet</p>
                            {console.log("MaintainStatus", MaintainStatus)}
                            <div className={MaintainStatus == "Process" ? "mt-4 nftbtn position-relative text-center nftbtn_disable" : "mt-4 nftbtn position-relative text-center"} onClick={() => PurchaseNFT()}>
                                <img src={Images.buyntnbtn} className='buyntnbtn img-fluid' />
                                <h4 className='text-center mainheading paynft' >Proceed</h4>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>

        </div>
    )
}


