import React, { useState, useEffect } from 'react';
import { Button, Container } from 'react-bootstrap';
import Header from "../Layouts/header";
import Headermobile from "../Layouts/headermobile";
import Footer from "../Layouts/footer";
import Images from '../Components/Images';
import Sidebar from '../Layouts/sidebar';
import Countdown from "react-countdown";
import Barchartss from '../Components/barcharts'
import { useSelector, useDispatch } from "react-redux";
import { Await, useNavigate } from 'react-router';
import { IncomeAndNode, WithDrawAmount, DailyTranscationRec } from '../Action/Controller/Token'
import { useWithdrawAmount } from '../Action/Hooks/Recipt'
import { toast } from 'react-toastify'
import { getCmsContent } from '../Action/Axios/cmsaxios';
import Config from "../Config/Config";
import { Account_Connect } from "../../src/Redux/action";

const Web3Utils = require('web3-utils');

const Mybank = () => {
    const dispatch = useDispatch();
    const Wallet_Details = useSelector((state) => state.wallet_connect_context);
    const history = useNavigate()
    const [LeftIncome, setLeftIncome] = useState(0)
    const [RightIncome, setRightIncome] = useState(0)
    const [Rewardstotal, setRewardstotal] = useState({})
    const [timer, settimer] = useState(new Date())
    const [ClaimAmt, setClaimAmt] = useState(0)
    const [notes, setnotes] = useState([])
    const [RecordWithdraw, setRecordWithdraw] = useState([])
    const [TotalWithDraw, setTotalWithDraw] = useState(0)

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
        Getandnotes();

    }, []);

    const firstExample = {
        size: 23,
        value: 2.5,
        edit: false,
        color: "#fff",
        activeColor: "gold",
        isHalf: true,
    };

    useEffect(() => {
        NodeAndRewards()
        DailyRec()
        // const targetTime = Wallet_Details?.User?.date
        // const nextCommissionTimestamp = new Date(targetTime).getTime() + 24 * 60 * 60 * 1000;
        // console.log("Difference in milliseconds:", nextCommissionTimestamp);
        // settimer(new Date(nextCommissionTimestamp))
        if (Wallet_Details.UserAccountAddr) {
            const targetTime = Wallet_Details?.User?.date;
            const nextCommissionTimestamp = new Date(targetTime).getTime() + 24 * 60 * 60 * 1000;
            const currentTime = new Date().getTime();
            const timerEnds = new Date(nextCommissionTimestamp);
            const timerHasEnded = timerEnds <= currentTime;

            const targetTime1 = Wallet_Details?.User?.TimeUpdated;
            const nextCommissionTimestamp1 = new Date(targetTime1).getTime() + 24 * 60 * 60 * 1000;
            const currentTime1 = new Date().getTime();
            const timerEnds1 = new Date(nextCommissionTimestamp);
            const timerHasEnded1 = timerEnds1 <= currentTime1;

            console.log("Timer has ended:", timerHasEnded, timerHasEnded1);
            if (!timerHasEnded) {
                settimer(new Date(nextCommissionTimestamp))
            } else {
                settimer(new Date(nextCommissionTimestamp1))
            }
        } else {
            const defaultTime = new Date();
            defaultTime.setHours(0);
            defaultTime.setMinutes(0);
            defaultTime.setSeconds(0);
            defaultTime.setMilliseconds(0);
            settimer(defaultTime);
        }
    }, [Wallet_Details.UserAccountAddr, Wallet_Details.User])

    const NodeAndRewards = async () => {
        console.log("TreeGraphApi", Wallet_Details?.UserAccountAddr);
        if (Wallet_Details?.UserAccountAddr) {
            let Payload = { user: { curraddress: Wallet_Details?.UserAccountAddr } }
            const Resp = await IncomeAndNode(Payload)
            console.log("Resp From Mybank", Resp);
            if (Resp?.status) {
                setRewardstotal(Resp.TranscationCalimed[0])
                setRightIncome(Resp.RightInvest)
                setLeftIncome(Resp.LeftInvest)
                if (Wallet_Details?.User?.UserType == "Admin") {
                    setClaimAmt((Number(Wallet_Details?.User?.subscriptionPendingReward) + Number(Wallet_Details?.User?.treePendingReward) + Number(Wallet_Details?.User?.sellValue) + Number(Wallet_Details?.User?.LevelRewardClaimed)))
                } else {
                    console.log("asddddddddddsa");
                    if (Wallet_Details?.User?.buyValue == 0) {
                        setClaimAmt(0)
                    } else if (Wallet_Details?.User?.buyValue < (Number(Wallet_Details?.User?.subscriptionPendingReward) + Number(Wallet_Details?.User?.treePendingReward))) {
                        console.log("comesss on else ifffff", (Rewardstotal?.MLM + Rewardstotal?.totalClaimed)?.toFixed(5));
                        const SetTheAmt = Wallet_Details?.User?.buyValue - ((Number(Wallet_Details?.User?.subscriptionPendingReward) + Number(Wallet_Details?.User?.treePendingReward) + Number(Wallet_Details?.User?.LevelRewardClaimed)))
                        const MathAbs = Math.abs(SetTheAmt)
                        const setFinalValue = ((Number(Wallet_Details?.User?.subscriptionPendingReward) + Number(Wallet_Details?.User?.treePendingReward) + Number(Wallet_Details?.User?.LevelRewardClaimed))) - MathAbs
                        setClaimAmt(setFinalValue)
                    } else {
                        console.log("cccccccommmmmiiiiiinnggggggg", (Number(Wallet_Details?.User?.subscriptionPendingReward) + Number(Wallet_Details?.User?.treePendingReward) + Number(Wallet_Details?.User?.LevelRewardClaimed)));
                        setClaimAmt((Number(Wallet_Details?.User?.subscriptionPendingReward) + Number(Wallet_Details?.User?.treePendingReward) + Number(Wallet_Details?.User?.LevelRewardClaimed)))
                    }
                }

            }

        }

    }

    const ClaimToken = async () => {
        const id = toast.loading("Initiating Claim Reward", { closeButton: true });
        var ClaimAmount = Wallet_Details?.User?.UserType == "Admin" ? (Rewardstotal?.MLM + Rewardstotal?.totalClaimed)?.toFixed(5) : Wallet_Details?.User?.buyValue > (Rewardstotal?.MLM + Rewardstotal?.totalClaimed).toFixed(5) ? 0 : (Rewardstotal?.MLM + Rewardstotal?.totalClaimed)?.toFixed(5)
        console.log("ClaimAmount", ClaimAmount);
        const targetTime = Wallet_Details?.User?.date;
        const targetTimestamp = new Date(targetTime).getTime() + 24 * 60 * 60 * 1000;
        const currentTime = new Date().getTime();
        const remainingTime = targetTimestamp - currentTime;

        const targetTime1 = Wallet_Details?.User?.TimeUpdated;
        const targetTimestamp1 = new Date(targetTime1).getTime() + 24 * 60 * 60 * 1000;
        const currentTime1 = new Date().getTime();
        const remainingTime1 = targetTimestamp1 - currentTime1;

        if (ClaimAmt == 0) {
            toast.update(id, { render: "Claim Amount must be greater than zero", type: "error", isLoading: false, autoClose: 2500, });
            return false
        }
        if (Wallet_Details?.User?.UserType !== "Admin") {
            if (remainingTime > 0) {
                toast.update(id, { render: "You can't claim now. Please wait until the timer ends.", type: "error", isLoading: false, autoClose: 2500, });
                return false
            }
            if (remainingTime1 > 0) {
                toast.update(id, { render: "You can't claim now. Please wait until the timer ends.", type: "error", isLoading: false, autoClose: 2500, });
                return false
            }
            if (Wallet_Details.User.buyValue == 0) {
                toast.update(id, { render: "Invest first, and then you can claim.", type: "error", isLoading: false, autoClose: 2500, });
                return false
            }
            if (Wallet_Details.User.buyValue > ClaimAmt) {
                toast.update(id, { render: "Your investment value is low. Invest and then claim", type: "error", isLoading: false, autoClose: 2500, });
                return false
            }
            if (Wallet_Details.User.buyValue == 0) {
                toast.update(id, { render: "Invest first, and then you can claim.", type: "error", isLoading: false, autoClose: 2500, });
                return false
            }
        }
        var ContractData = {
            Wallet_Details: Wallet_Details,
            Data: [Wallet_Details?.HowRefered?.FromAddress, Web3Utils.toWei(ClaimAmt, "ether")],
            SendData: true,
            Price: Web3Utils.toWei(ClaimAmt, "ether")
        }
        var claimAmountContract = await ReciptCommon(ContractData)
        console.log("claimAmountContract", claimAmountContract);
        if (claimAmountContract) {
            var DataApi = {
                Claimer: Wallet_Details?.UserAccountAddr,
                ReferalReward: Web3Utils.fromWei(ClaimAmt, "ether"),
                ClaimAmount: ClaimAmt,
                Status: "Claim",
                User: Wallet_Details?.User?.UserType
            }
            var Key = { value: JSON.stringify(DataApi) }
            var ApiCall = await WithDrawAmount(Key)
            console.log("ApiCallApiCall", ApiCall);
            if (ApiCall.status) {
                toast.update(id, { render: "Claimed Successfully", type: "success", isLoading: false, autoClose: 2500, });
                setTimeout(() => {
                    history('/dashboard')
                }, 2500)

            } else {
                toast.update(id, { render: "Transaction Failed", type: "error", isLoading: false, autoClose: 2500, });
            }
        } else {
            toast.update(id, { render: "Transaction Failed", type: "error", isLoading: false, autoClose: 2500, });
        }

    }

    const ReciptCommon = async (ContractData) => {
        let ContractCallInter = await useWithdrawAmount(ContractData)
        console.log("ContractCall", ContractCallInter);
        return ContractCallInter
    }

    const Getandnotes = async () => {
        var resp = await getCmsContent("notes");
        console.log("mybank_notesdf", resp.data);
        if (resp?.status) setnotes(resp?.data);
    };

    const DailyRec = async () => {
        let Payload = { user: { curraddress: Wallet_Details.UserAccountAddr } }
        console.log("Wallet_DetailsDailyRec", Wallet_Details.UserAccountAddr);
        if (Wallet_Details?.User?.UserType !== "Admin") {
            if (Wallet_Details.UserAccountAddr) {
                console.log("Number(Wallet_Details?.User?.PendingAmount) < Config.ClaimLimit", Number(Wallet_Details?.User?.PendingAmount) > Config.ClaimLimit, Wallet_Details?.User?.PendingAmount, Config.ClaimLimit);
                if (Number(Wallet_Details?.User?.PendingAmount) > Config.ClaimLimit) {
                    console.log("Wallet_Details.UserAccountAddr", Wallet_Details.UserAccountAddr);
                    let resp = await DailyTranscationRec(Payload)
                    console.log("resp DailyRecccc", resp);
                    setTotalWithDraw(resp?.totalAmount)
                    setRecordWithdraw(resp?.DayWiseRecord)
                    if (resp?.DayWiseRecord) {
                        let PendingReward = Number(Wallet_Details?.User?.PendingAmount)
                        console.log("PendingReward in MyBank", PendingReward);
                        let TotalAmt = Number(resp?.totalAmount) + Number(PendingReward)
                        console.log("TotalAmt in MyBank", TotalAmt);
                        let Subtract = Number(TotalAmt) - Config.ClaimLimit
                        console.log("Subtract in MyBank", Subtract);
                        let PendingSubtract = PendingReward - (Math.abs(Subtract))
                        console.log("PendingSubtract in MyBank", PendingSubtract, Config.ClaimLimit, TotalAmt > Config.ClaimLimit);
                        if (TotalAmt > Config.ClaimLimit) {
                            let DataApi = {
                                Claimer: Wallet_Details?.UserAccountAddr,
                                PendingSubtract: PendingSubtract,
                                AmountTransferToAdmin: Subtract,
                                TotalAmt: TotalAmt,
                                Status: "AdminTransfer",
                            }
                            console.log("DataApi", DataApi);
                            let Key = { value: JSON.stringify(DataApi) }
                            let ApiCall = await WithDrawAmount(Key)
                            console.log("ApiCallApiCall", ApiCall);
                            if (ApiCall.status) {
                                dispatch({
                                    type: Account_Connect,
                                    Account_Detail: {
                                        User: ApiCall?.UserReferralUpdateUser
                                    }
                                })
                                NodeAndRewards()
                                DailyRec()
                            }
                        } else {
                            console.log("Commiiiinnnnggggg oooonnnn eeeellllsssseeee");
                        }
                    }
                }
            }
        }
    }

    return (
        <>

            <div className='dashboard animehead historyss ms'>

                <div className='d-none d-lg-block'> <Header /></div>
                <div className='d-block d-lg-none'><Headermobile /></div>

                <div className='dashboardss'>
                    <img src={Images.topline} className='img-fluid topline w-100' />
                    <img src={Images.rightline} className='img-fluid rightline' />
                    <span style={{ background: Wallet_Details?.UserLevels?.Colour }} className='blugradient redgradient'></span>
                    <span style={{ background: Wallet_Details?.UserLevels?.Colour }} className='blugradient1 redgradient1'></span>
                    <span style={{ background: Wallet_Details?.UserLevels?.Colour }} className='blugradient blugradientss'></span>
                    <Sidebar />
                    <div className='alllist'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-11 col-md-8 col-lg-7 mt-3 mt-xl-0 text-center overpos mx-auto'>
                                    <h4 className='mainheading bluee font'>Next Binary Commission Distribution Time:</h4>
                                    <h1 className='countss mt-4 bluee'><img src={Images.circle} className='fcircle me-2' /> <Countdown date={timer} /> <img src={Images.circle} className='fcircle ms-2' /></h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='container'>
                    <div className='col-md-12 mt-5 mx-auto'>
                        <div className='bankbackimg px-3 px-sm-5'>
                            <div className='row position-relative pb-4 px-2 px-md-3'>
                                <img src={Images.bankbot} className='bankbot' />
                                <img src={Images.banktop} className='banktop' />
                                <div className='col-lg-6 mt-4'>
                                    <div className='bankcardimg'>
                                        <p className='blktxt text-center ms-3 ms-sm-0 pt-2 mb-0'>My Total Income</p>
                                        <Barchartss />
                                    </div>
                                </div>
                                <div className='col-lg-6 mt-4'>
                                    {console.log("Wallet_Details", Wallet_Details)}
                                    <div className='row'>
                                        <div className='col-md-6 col-sm-6 mt-3'>
                                            <h6 className='text-start mainheading t1 py-3 px-2 mb-1'>Reward Claimed</h6>
                                            <h4 className='t2 py-4 ps-4'>{Wallet_Details?.User?.RewardClimed?.toFixed(4) || 0}</h4>
                                            {/* {Rewardstotal?.totalClaimed ? Rewardstotal?.totalClaimed?.toFixed(5) : 0} */}
                                        </div>
                                        <div className='col-md-6 col-sm-6 mt-3'>
                                            <h6 className='text-start mainheading t1 py-3 px-2 mb-1'>Reward Not Claimed</h6>
                                            <h4 className='t2 py-4 ps-4'>{Wallet_Details?.User?.subscriptionPendingReward ? (Number(Wallet_Details?.User?.subscriptionPendingReward || 0) + Number(Wallet_Details?.User?.treePendingReward || 0) + Number(Wallet_Details?.User?.LevelRewardClaimed || 0) + Number(Wallet_Details?.User?.sellValue || 0))?.toFixed(4) : 0}</h4>
                                        </div>
                                        <div className='col-md-6 col-sm-6 mt-3'>
                                            <h6 className='text-start mainheading t1 py-3 px-2 mb-1'>Total Income</h6>
                                            <h4 className='t2 py-4 ps-4'>{(Number(Wallet_Details?.User?.subscriptionPendingReward || 0) + Number(Wallet_Details?.User?.treePendingReward || 0) + Number(Wallet_Details?.User?.LevelRewardClaimed || 0) + Number(Wallet_Details?.User?.sellValue || 0))?.toFixed(4) || 0}</h4>
                                        </div>
                                        {/* Rewardstotal?.MLM ? Rewardstotal?.MLM : 0 */}
                                        <div className='col-md-6 col-sm-6 mt-3'>
                                            <h6 className='text-start mainheading t1 py-3 px-2 mb-1'>Withdrawable</h6>
                                            <h4 className='t2 py-4 ps-4'>{Wallet_Details?.User?.UserType !== "Admin" ? Wallet_Details?.User?.buyValue ? Wallet_Details?.User?.buyValue?.toFixed(5) : 0 : "Admin"}</h4>
                                        </div>
                                        <div className='col-md-12 d-flex align-items-center text-center mt-4'>
                                            <img src={Images.left1} className='left1 me-1 img-fluid d-none d-sm-block' />
                                            <div className='w-100'>
                                                <h6 className='mainheading t1 py-3 px-2 
                                                mb-1'>Maintain Binary Total Income</h6>
                                                <h4 className='t3 py-3 ps-4'>{ClaimAmt?.toFixed(4)}</h4>
                                            </div>
                                            <img src={Images.right1} className='ms-1 img-fluid d-none d-sm-block' />
                                        </div>
                                        <div className='col-md-8 text-center mt-5 mx-auto'>
                                            {/* <h4 className='textclr'>Withdrawal time reached !</h4> */}
                                            <div className='mt-4 nftbtn withdrawbtn position-relative'>
                                                <img src={Images.buyntnbtn} className='buyntnbtn img-fluid' />
                                                <h4 className='text-start mainheading buytextnft' onClick={() => ClaimToken()}>Withdraw (max = {ClaimAmt?.toFixed(4)})</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <div className='notebackimg mt-5 py-4 px-4'>
                                <h4 className='text-start mainheading mb-3'>{notes?.heading}</h4>
                                <ul>
                                    <div dangerouslySetInnerHTML={{ __html: notes?.description }}></div>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />

            </div>

        </>
    )
}

export default Mybank;