import React, { useState, useEffect, useRef } from 'react';
import { Button, Container, Nav, Navbar, Offcanvas, Dropdown } from 'react-bootstrap';
import Images from '../Components/Images';
import $ from "jquery";
import Walletmodal from '../Components/Modals/walletmodal';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Account_Connect, Account_disConnect } from "../Redux/action";
import { toast, Flip } from "react-toastify";
import Web3 from "web3";
import { connectWallet } from "../Action/Hooks/useWallet";
import Config from "../Config/Config"
import { GetOrSave_Action, CheckReferred, FindCurrentLevelUser } from '../Action/Controller/Token'
import { AiOutlineCaretDown } from 'react-icons/ai';
import { CgProfile } from "react-icons/cg";
import CopyToClipboard from "react-copy-to-clipboard";
import { FiCopy } from "react-icons/fi";
import { LuWallet } from "react-icons/lu";

const Header = () => {
    const dispatch = useDispatch();
    const timerRef = useRef(null);
    const history = useNavigate()
    const Wallet_Details = useSelector((state) => state.wallet_connect_context);
    console.log("Wallet_Details", Wallet_Details);
    const [menuOpen, setMenuOpen] = useState(false);  // initially closed
    const [Walletmodals, setWalletmodals] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const toggleMenu = () => {
        // alert(1)
        const getWindowWidth = window.innerWidth
        console.log("windn", getWindowWidth, menuOpen)
        if (getWindowWidth < 992) {

            setMenuOpen(false)
        } else { setMenuOpen(false) }

        // this handler is "regular"
        // setMenuOpen(!menuOpen);    // open and close...
    };


    $(window).scroll(function () {
        var scroll = $(window).scrollTop();
        if ($(this).scrollTop()) {
            $(".navbar").addClass("fixedTop");
        } else {
            $(".navbar").removeClass("fixedTop");
        }
        if (scroll >= 50) {
            $(".fixedTop").addClass('scroll');
            $("#scroll-top").addClass('show');
        }
        else {
            $(".fixedTop").removeClass('scroll');
            $("#scroll-top").removeClass('show');
        }
    });

    useEffect(() => {
        if (localStorage.walletConnectType && localStorage.walletConnectType != null && localStorage.walletConnectType == "MetaMask") {
            walletConnect("MetaMask", "register");
        } else if (localStorage.walletConnectType && localStorage.walletConnectType == "WalletConnect" && localStorage.walletConnectType != null) {
            console.log("elseeeee3");
            walletConnect("WalletConnect", "register");
        } else if (localStorage.walletConnectType && localStorage.walletConnectType == "Coinbase" && localStorage.walletConnectType != null) {
            console.log("elseeeee3");
            walletConnect("Coinbase", "register");
        }

    }, [Wallet_Details.UserAccountAddr]);

    useEffect(() => {
        FindTheLevel()
    }, [Wallet_Details.UserAccountAddr]);

    const FindTheLevel = async () => {
        if (Wallet_Details?.UserAccountAddr) {
            let Payload11 = { user: { curraddress: Wallet_Details?.UserAccountAddr } }
            var RefereddCheckReferred = await FindCurrentLevelUser(Payload11)
            console.log("RefereddHearrrrrCheckReferred", RefereddCheckReferred);
            if (RefereddCheckReferred?.status) {
                dispatch({
                    type: Account_disConnect,
                    Account_Disconnect: {
                        UserLevels: RefereddCheckReferred?.ReferedOrNot
                    },
                });
            }
        }
    }


    const Disconnect = async (a) => {
        console.log("Disconnect", Wallet_Details);
        dispatch({
            type: Account_disConnect,
            Account_Disconnect: {
                UserAccountAddr: "",
                UserAccountBal: 0,
                provider: null,
                Service_Fee: 0,
                Accounts: "",
                Web3: null,
                WalletConnected: "",
                AddressUserDetails: null,
                tokenAddress: null,
                UserLevels: {}
            },
        });
        if (a === "click")
            toast.success("Wallet Disconnected", { autoClose: 3000, theme: "dark" });
        if (
            localStorage.walletconnect != null &&
            localStorage.walletConnectType == "wc"
        ) {
            await Wallet_Details.provider.disconnect();
            localStorage.removeItem("walletconnect");
        }
        localStorage.removeItem("walletConnectType");
        localStorage.removeItem("accountInfo");
        localStorage.removeItem("user_token");
        setWalletmodals(false)
        setTimeout(() => {
            history('/')
        }, 500)
    };

    window.addEventListener("load", async (event) => {
        console.log("loaddd");
        if (localStorage.getItem("walletConnectType") == "MetaMask" || localStorage.walletConnectType == "Coinbase") {
            if (window.ethereum) {
                // console.log("tjghjfhio", window.ethereum);
                window.ethereum.on("accountsChanged", function (accounts) {
                    if (timerRef.current) {
                        clearTimeout(timerRef.current);
                    }
                    timerRef.current = setTimeout(() => {
                        walletConnect("MetaMask");
                    }, 1000);
                });

                window.ethereum.on("chainChanged", async function (networkId) {
                    //console.log("chainid",networkId)
                    // if (networkId == config.chainId) {
                    if (networkId) {
                        if (timerRef.current) {
                            clearTimeout(timerRef.current);
                        }
                        timerRef.current = setTimeout(() => {
                            walletConnect("MetaMask");
                        }, 1000);
                    } else {
                        dispatch({
                            type: Account_disConnect,
                        });
                    }
                });
            }
        } else if (localStorage.walletConnectType == "WalletConnect") {
            var provider3 = null;
            if (provider3 == null) {
                provider3 = await walletConnect("WalletConnect");
            } else if (provider3 != null) {
                provider3.on("connect", () => {
                    walletConnect("WalletConnect");
                });
                provider3.on("disconnect", () => {
                    localStorage.removeItem("walletConnectType");
                });
            }
        }
    });

    const walletConnect = async (type, register) => {
        var toasttxt = null
        try {
            var accountDetails = await connectWallet(type, Config.ChainID, register)
            console.log("accountDetailsaccountDetailsaccountDetailsherrrrrrrrr", accountDetails);
            if (accountDetails && accountDetails?.accountAddress !== undefined) {
                if (accountDetails != '') {
                    const IDcheck = await accountDetails?.web3?.eth?.getChainId();
                    console.log("ChainID Checking", IDcheck);
                    let SendData = {
                        addr: accountDetails?.accountAddress,
                        Referral: localStorage.getItem("ReferralID") ? localStorage.getItem("ReferralID") : Config.AdminReferralKey
                    }
                    let Payload11 = { user: { curraddress: accountDetails?.accountAddress } }
                    console.log("Payload11", Payload11);
                    var RefereddCheckReferred = await CheckReferred(Payload11)
                    console.log("RefereddHeadererrrrrrrrCheckReferred", RefereddCheckReferred);
                    if (RefereddCheckReferred.status) {
                        var UserSave = await GetOrSave_Action(SendData)
                        console.log("UserSave Headerrrrrr", UserSave);
                        if (UserSave?.data?.status) {
                            dispatch({
                                type: Account_Connect,
                                Account_Detail: {
                                    UserAccountAddr: accountDetails?.accountAddress,
                                    UserAccountBal: accountDetails?.coinBalance,
                                    TokenBalance: accountDetails?.tokenBalance,
                                    WalletConnected: "true",
                                    Web3: accountDetails?.web3,
                                    provider: accountDetails?.web3?.providers,
                                    User: UserSave?.data?.User,
                                    UserLevels: UserSave?.data?.LevelUser,
                                    RefDetails: UserSave?.data?.RefDetails,
                                    HowRefered: UserSave?.data?.HowRefered,
                                    AllUsers: UserSave?.data?.AllUser
                                }
                            })
                        } else {
                            dispatch({
                                type: Account_Connect,
                                Account_Detail: {
                                    UserAccountAddr: accountDetails?.accountAddress,
                                    UserAccountBal: accountDetails?.coinBalance,
                                    TokenBalance: accountDetails?.tokenBalance,
                                    WalletConnected: "true",
                                    Web3: accountDetails?.web3,
                                    provider: accountDetails?.web3?.providers,
                                }
                            })
                        }
                    } else {
                        // toast.update(id, {
                        //     render: "You Don't Any referral.Enter Referal Code",
                        //     type: "error",
                        //     isLoading: false,
                        //     autoClose: 2500,
                        //     closeButton: true,
                        //     theme: "dark"
                        // });
                        // //setWalletmodals(false)
                        // dispatch({
                        //     type: Account_Connect,
                        //     Account_Detail: {
                        //         NotRefered: true
                        //     }
                        // })
                    }
                }
            } else {
                toast.error("Failed to Connect Wallet");
            }
        } catch (err) {
            console.log("errrrrrrrrrrrrrrrrrrrrrr", err);
            toast.error("Failed to Connect Wallet");
            localStorage.clear();
        }
    };

    return (
        <>
            {Walletmodals && <Walletmodal onDismiss={() => setWalletmodals(false)} />}
            <Navbar className='ss' key="lg" expand="lg" >
                <Container>
                    <Navbar.Brand><Link to={"/"}><img src={Images.logo} className='img-fluid logo' alt='images' /> </Link></Navbar.Brand>
                    {/* <span><LuWallet/></span> */}
                    <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-lg`} onClick={() => setMenuOpen(!menuOpen)} />
                  
                    <Navbar.Offcanvas
                        id={`offcanvasNavbar-expand-lg`}
                        aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
                        placement="end"
                        restoreFocus={false}
                        show={menuOpen}
                        onHide={() => setMenuOpen(!menuOpen)}
                    >
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`}>
                                <Link to="/" onClick={() => toggleMenu()}><img src={Images.logo} className='img-fluid logo smalllogo' alt='images' /></Link>                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Nav className="ms-auto align-items-center">
                                <NavLink to="/marketplace" activeClassName="active" onClick={() => toggleMenu()}>Marketplace</NavLink>
                                <NavLink to="/dashboard" activeClassName="active" onClick={() => toggleMenu()}>Dashboard</NavLink>
                                <NavLink to="/faq" activeClassName="active" onClick={() => toggleMenu()}>FAQ</NavLink>
                                <NavLink to="/news" activeClassName="active" onClick={() => toggleMenu()}>News & Events</NavLink>
                                {Wallet_Details.UserAccountAddr == "" || Wallet_Details.UserAccountAddr == undefined ?
                                    < p onClick={() => {setWalletmodals(true);toggleMenu()}} className='whtclr wallet ms-0 mt-3 mt-lg-0 ms-lg-3 ms-xl-5 mb-0'>Wallet Connect</p>
                                    :
                                    <div class="dropdown">
                                        <button class="dropbtn whtclr"><CgProfile className='profiles' />

                                        </button>
                                        <div class="dropdown-content">
                                            <div className="d-flex justify-content-between align-items-center rightss mt-3 mb-2 px-3">
                                                <CopyToClipboard text={Wallet_Details?.UserAccountAddr}
                                                    onCopy={() =>
                                                        toast.success("Copied Successfully", {
                                                            closeButton: true,
                                                            autoClose: 1500,
                                                            transition: Flip,
                                                            theme: "dark",
                                                        })
                                                    }
                                                >
                                                    <div className='text-start d-flex align-items-center justify-content-center' style={{ cursor: "pointer" }}> <p className='bluclr mb-0 me-2'>{Wallet_Details?.UserAccountAddr?.slice(0, 14)}</p><FiCopy className='ficpoy' /></div>
                                                </CopyToClipboard>
                                            </div>
                                            <Link to="/editprofile">Profile</Link>
                                            <Link onClick={() => Disconnect()}>Disconnect</Link>
                                        </div>
                                    </div>

                                    // <Dropdown onMouseLeave={() => setShowDropdown(false)}
                                    //     onMouseOver={() => setShowDropdown(true)}>
                                    //     <Dropdown.Toggle className="dropbtn whtclr" id="dropdown-basic">
                                    //         <CgProfile className='profiles' />
                                    //     </Dropdown.Toggle>
                                    //     <Dropdown.Menu show={showDropdown}>

                                    //         <Link to="/editprofile">Profile</Link>
                                    //         <Link onClick={() => Disconnect()}>Disconnect</Link>                                    </Dropdown.Menu>
                                    // </Dropdown>
                                    // <p onClick={() => Disconnect()} className='whtclr wallet ms-0 mt-3 mt-lg-0 ms-lg-5 mb-0'>Disconnect</p>
                                }


                            </Nav>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container >
            </Navbar >



        </>
    )
}

export default Header;