import React, { useState, useEffect } from 'react';
import Tree from 'react-d3-tree';
import CustomNode from './customenode';
import Images from './Images';
import { TreeGraphApi } from '../Action/Controller/Token'
import { toast, Slide } from "react-toastify";
import { useSelector } from "react-redux";

const treeData = {

  name: 'ca21665516',
  attributes: {
    id: 'dSdsd****f1af'
  },
  children: [
    {
      name: 'ba21325551',
      attributes: {
        id: 'TUnas****f1af',
      },
    },
    {
      name: 'ba12884418',
      attributes: {
        id: 'Posdf****f1sd',
      },
      children: [
        {
          name: 'vc58623519',
          attributes: {
            id: 'Uopds****bhnc',
          },
          children: [
            {
              name: 'vc58623519',
              attributes: {
                id: 'Uopds****bhnc',
              },
            },
            {
              name: 'ba12884418',
              attributes: {
                id: 'Posdf****f1sd',
              },
            },
          ],
        },
        {
          name: 'ba12884418',
          attributes: {
            id: 'Posdf****f1sd',
          },

        },
      ],
    },
  ],
};


const TreeGraph = (Props) => {
  console.log("PropsPropsPropsPropsPropsProps", Props);
  const Wallet_Details = useSelector((state) => state.wallet_connect_context);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [tree, settree] = useState({})

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  const containerStyles = {
    width: '100%',
    // height: '100vh',
  };

  useEffect(() => {
    console.log("Wallet_Details.Refreash", Wallet_Details.Refreash);
    TreeGraphData()
  }, [Wallet_Details.UserAccountAddr, Wallet_Details.Refreash])

  const TreeGraphData = async () => {
    console.log("TreeGraphApi", Wallet_Details.UserAccountAddr);
    if (Wallet_Details.UserAccountAddr) {
      var Payload = { user: { curraddress: Wallet_Details.UserAccountAddr } }
      var Resp = await TreeGraphApi(Payload)
      console.log("Resp From Treee", Resp);
      if (Resp?.status) {
        // var mapdata = Resp?.TreeData[0]?.map((item) => {
        //   let newObj = { ...item, "ConnectAdd": Wallet_Details.UserAccountAddr }
        //   return newObj
        // })
        settree(Resp?.TreeData[0])
      }
    }

  }


  return (
    <div style={containerStyles} id="treeWrapper">

      <style>
        {`
        #treeWrapper circle {
          fill: #f35800;
        }
        #treeWrapper path.rd3t-link {
          stroke: #FC0101;
          stroke-width: 3px;
        }
        #treeWrapper text {
          // font-size: 13px;
          fill: #fff;
          margin-bottom:12px;
        }
        #treeWrapper .rd3t-node{
          fill: transparent;
          stroke: transparent;
          stroke-width: 0;
        }
        #treeWrapper .rd3t-leaf-node {
          fill: transparent;
          stroke: transparent;
          stroke-width: 0;
      }
      `}
      </style>

      <Tree data={tree}
        renderCustomNodeElement={(rd3tProps) =>
          <CustomNode {...rd3tProps} />

        }
        enableLegacyTransitions={1}
        //  initialDepth={4}
        //  orientation={"vertical"}
        zoomable={0}
        nodeSize={{
          x: 190,
          y: 160
        }}
        // orientation={windowWidth > 575 ? "horizontal" : "vertical"}
        translate={{ x: 200, y: 200 }}
        pathFunc="curve"
      />
    </div>
  );
};

export default TreeGraph;





