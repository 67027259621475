import React, { useState, useEffect } from 'react';
import { Button, Container, Accordion } from 'react-bootstrap';
import Header from "../Layouts/header";
import Footer from "../Layouts/footer";
import Images from '../Components/Images';
import Blogcard from '../Components/blogcard';
import { getnewslist } from '../Action/Axios/adminaxios';

function News() {

     const [itemss, SetItemss] = useState([
        { id: 1, title: " Explore the Cosmos", des: "Embark upon a cosmic odyssey to unravel the mysteries of creation hidden within the farthest reaches of the cosmos." },
        { id: 2, title: " Unlock Creativity", des: "Experiment with primary elements to unlock new hues and create pixel art characters of unparalleled beauty and complexity." },
        { id: 3, title: " Ascend to Greatness", des: "Personalize avatars with an advanced AI, infusing them with the essence of aspirations and ideals." },
        { id: 4, title: " Forge Bonds of Unity", des: "Merge avatars through marriage, forging a legacy that transcends individuality and shapes the destiny of the metaverse." },
        { id: 5, title: " Leave Your Mark Upon the Metaverse", des: "Explore the vast expanse of the metaverse, leaving a trail of discovery and triumph in your wake as you shape the cosmos itself." },

        { id: 6, title: " NFT Crafting", des: "Combine Red, Green, and Blue NFTs to unlock new hues and create unique pixel art characters." },
        { id: 7, title: " Avatar Customization", des: "Utilize an advanced AI to personalize avatars, shaping them according to your dreams and aspirations." },
        { id: 8, title: " Sacred Union", des: "Merge avatars in marriage to unlock new possibilities and shape the destiny of the metaverse." },
        { id: 9, title: " Cosmic Exploration", des: "Traverse the boundless expanse of the metaverse in search of adventure and discovery." },
        { id: 10, title: " LCollaborative Creatione", des: "Forge alliances with fellow adventurers, shaping the destiny of the universe together through cooperative gameplay and shared experiences." },
    ]);

    useEffect(() => {
        Getnewslist();
    }, []);

    const Getnewslist = async () => {
        let resp = await getnewslist()
        console.log("Getnewslist", resp?.data);
        if (resp?.status) SetItemss(resp?.data)
    }

    return (

        <div className='faqpage animehead'>

            <Header />

            <div className='marketplacedetail_sec firsttop pb-5 termspara'>
                <span className='violentgrad'></span>
                <span class="blugradient"></span>
                <img src={Images.detailleftarrow} className='detailleftarrow d-none' />
                <div className='container pt-4'>
                    <h1 className='mainheading mt-4 pb-4'><img src={Images.leftdot} className='leftdot' /> <img src={Images.headingblink} className='headingblink' /> News & Events <img src={Images.headingblink} className='headingblink' />  <img src={Images.leftdot} className='leftdot rightdot' /></h1>
                    <div className='row pt-3 pt-xl-5'>
                        {itemss.map((e, i) => (
                            <div className='col-12 col-sm-10 col-md-6 col-lg-4 col-xl-4 col-xxl-4 mt-3 mx-auto mx-md-0 pt-5'>
                                <Blogcard datas={e} />
                            </div>
                        ))}
                    </div>

                </div>
            </div>

            <Footer />
        </div>


    )
}

export default News;