import React, { useState, useEffect } from 'react';
import { Button, Container } from 'react-bootstrap';
import Images from '../Components/Images';
import { FaCalendar } from "react-icons/fa";
import { getnewslist } from '../Action/Axios/adminaxios';
import config from '../Config/Config'
import { Link } from 'react-router-dom';

const Blogcard = (props) => {
  console.log("Propss",props)
const [news,setnews] = useState([])
useEffect(()=>{
  Getnewslist();
},[])
const Getnewslist = async () => {
  let resp = await getnewslist()
  console.log("Getnewslist", resp?.data);
  if (resp?.status) setnews(resp?.data)
}
    return (

        <>
{/* {news?.map((val)=>(
  
))

} */}
            <div className='blogcard'>
                <div className='px-3 py-3'>
                
                  <div className='blogpos'>
                    
                    <h6 className='datelist mb-0'><FaCalendar className='me-1'/> <span>{props.datas.date && `${new Date(props.datas.date).toLocaleString('default', { month: 'long' })} ${new Date(props.datas.date).getDate()},${new Date(props.datas.date).getFullYear()}`}</span></h6>
                  <img src={Images.datewhite} className='img-fluid datewhite' />
                  </div>
                <div className='text-center'><img src={`${config.Image_url}/newsimg/${props.datas.img}`} className='img-fluid blogimg' /></div>
                <h6 className='bloghead mt-2'>{props.datas.heading}</h6>
                <p className='subtext mt-3 textheight mb-3'><div dangerouslySetInnerHTML={ {__html: props?.datas?.content?.toString().length > 80 ?  props?.datas?.content.toString().slice(0,120)+"...." : props?.datas?.content}}></div>
                </p>
                </div>
                <div className='read'> <Link to={`/Article/${props.datas.heading}`} ><h5 className='readtext mb-0'>Read More</h5></Link><img src={Images.readmoreimg} className='img-fluid readmoreimg' /></div>
                {/* <Link to="/Readmore" state={news}>Link Text</Link> */}
                 
            </div>

        </>

    )
}

export default Blogcard;