import Config from '../../Config/Config'
import ContractABI from '../ABI/ContractABI.json'
import TokenAbi from '../ABI/TokenABI.json'
import Web3 from 'web3'
import { useWeb3 } from '../Hooks/Web3'

export const useMethodsContract = async (Details) => {
    const web3InRpc = new Web3(new Web3.providers.HttpProvider(Config.RPC_URL))
    try {
        const Provider = Details.Wallet_Details
        var Hash = null

        var Contract = await ContractIntract(Provider)
        console.log("chainchain", Details.Data, Details.Method, Details.SendData, Details.Price);
        var encoded = await Contract.methods[Details.Method](...Details.Data);
        console.log("encoded", encoded);
        var Transcation = {
            from: Provider.UserAccountAddr,
            to: Config.Contract,
        };
        console.log("TranscationTranscation", Transcation);
        var gasprice = await web3InRpc.eth.getGasPrice();
        console.log("gasprice", gasprice);
        var gaslimit = await encoded.estimateGas(Transcation)
        console.log("gaslimit", gaslimit);
        var gasLimit = web3InRpc.utils.toHex(gaslimit);
        var gasPrice = web3InRpc.utils.toHex(gasprice);
        console.log("Intracttttt", gasPrice, gasLimit)
        // if (Details.SendData) {
        //     var sendValue = { from: Provider.UserAccountAddr, value: Details.Price, gasLimit: gasLimit, gasPrice: gasPrice }
        // } else {
        var sendValue = { from: Provider.UserAccountAddr, gasLimit: gasLimit, gasPrice: gasPrice }
        // }
        console.log("sendsendsendsendsend", sendValue);
        try {
            await Contract.methods[Details?.Method](...Details?.Data).send(sendValue).on('transactionHash', async (transactionHash) => {
                Hash = transactionHash
            })
            return Hash
        }
        catch (error) {
            console.log("Error On Common Contract Functions", error)
            return false
        }
    } catch (e) {
        console.log("eeeeeeeeeeeeeeeee", e);
        return false
    }
}

export const ContractIntract = async (Provider) => {
    console.log("Config.RPC_URaaaaaaaaL", Config.RPC_URL);
    const web3 = await useWeb3(Config.RPC_URL)
    const web3InRpc = new Web3(window.ethereum)
    const contract = new web3InRpc.eth.Contract(ContractABI, Config.Contract);
    return contract;
}

export const useTokenApprove = async (Details) => {
    const web3InRpc = new Web3(new Web3.providers.HttpProvider(Config.RPC_URL))
    var Hash = null
    var Contract = await useTokenContract()
    try {
        const Wallet_Details = Details.Provider
        var encoded = await Contract.methods.approve(Config.Contract, Details.PriceWithSerfee)
        const Transcation = {
            from: Wallet_Details.UserAccountAddr,
        };
        var gasprice = await web3InRpc.eth.getGasPrice();
        var gaslimit = await encoded.estimateGas(Transcation)
        var gasLimit = web3InRpc.utils.toHex(gaslimit);
        var gasPrice = web3InRpc.utils.toHex(gasprice);
        try {
            await Contract.methods.approve(Config.Contract, Details.PriceWithSerfee).send({ from: Wallet_Details.UserAccountAddr, gasLimit: gasLimit, gasPrice: gasPrice }).on('transactionHash', async (transactionHash) => {
                Hash = transactionHash
            })
            return Hash
        }
        catch (error) {
            console.log("error in Token Aprrove", error);
            return false
        }
    } catch (e) {
        console.log("Alll catch in tokenApprove", e);
        return false
    }
}


export const useWithdraw = async (Details) => {
    console.log("Details", Details);
    const web3InRpc = new Web3(new Web3.providers.HttpProvider(Config.RPC_URL))
    console.log("web3InRpc", web3InRpc);
    var Hash = null
    var Contract = await ContractIntract()
    console.log("ContractContract", Contract);
    try {
        const Wallet_Details = Details.Wallet_Details
        var encoded = await Contract.methods.claimReward(...Details.Data)
        console.log("encoded", encoded);
        const Transcation = {
            from: Wallet_Details.UserAccountAddr,
            // value: Details.Price
        };
        var gasprice = await web3InRpc.eth.getGasPrice();
        console.log("gasprice", gasprice);
        var gaslimit = await encoded.estimateGas(Transcation)
        console.log("gaslimit", gaslimit);
        var gasLimit = web3InRpc.utils.toHex(gaslimit);
        console.log("gasLimit", gasLimit);
        var gasPrice = web3InRpc.utils.toHex(gasprice);
        console.log("gasPrice", gasPrice);
        try {
            await Contract.methods.claimReward(...Details.Data).send({ from: Wallet_Details.UserAccountAddr, gasLimit: gasLimit, gasPrice: gasPrice }).on('transactionHash', async (transactionHash) => {
                Hash = transactionHash
            })
            return Hash
        }
        catch (error) {
            console.log("error in Token Aprrove", error);
            return false
        }
    } catch (e) {
        console.log("Alll catch in tokenApprove", e);
        return false
    }
}
export const useTokenContract = async () => {
    const web3 = await useWeb3(Config.RPC_URL)
    console.log("web3web3", web3);
    const contract = new web3.eth.Contract(TokenAbi, Config.TokenAddress);
    console.log("contractcontract Token", contract);
    return contract
}

