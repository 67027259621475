import React from 'react'
import isEmpty from '../../Config/isEmpty'
import config from '../../Config/Config'
import moment from "moment";
import $ from 'jquery'
import Web3 from "web3"



export const isEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
}

export const isDate = (email) => {
    var re = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
    return re.test(email);
}


export const isCustomURL = (cusurl) => {
    let nameRegex = /^[:]+(\s{0,1}[a-zA-Z-, ])*$/;
    var istrue = ((config.nameFormat)?.test(cusurl)) ||
        ((nameRegex).test(cusurl)) ||
        ((cusurl).includes('.')) ||
        ((cusurl).includes(' ')) ||
        ((cusurl).match(/^(?:.*[|,/,\\])/))
    return isEmpty(istrue)
}

export const UserValidation = (Formdata) => {
    console.log("FormdataFormdata", Formdata);
    var valError = {}
    valError.Email = ((Formdata.Email) ? isEmail(Formdata.Email) : true) ? Formdata.Email == "" ? "Enter Email" : true : "Enter Valid Email"
    valError.CustomUrl = ((Formdata.CustomUrl) ? isCustomURL(Formdata.CustomUrl) : true) ? true : "CustomUrl Cannot have Smiley, ' . ' , ' / ' or Spaces "
    valError.Name = ((Formdata.Name) ? !((config.nameFormat).test(Formdata.Name)) : true) ? Formdata.Name == "" ? "Enter Name" : true : "Smiley's are Not allowed"
    console.log("valErrorvalErrorvalError", valError);
    if (valError.Email === true && valError.CustomUrl === true && valError.Name === true)
        return true
    else
        return valError
}

export const ImageValidation = (file, Page) => {
    // console.log("FILE AND PAGE VALIDATION IN CREATE AND BULK", file, Page);
    if (Page === 'Edit')
        var validExtensions = ["png", "webp", "jpeg", "PNG", "jpg", "JPEG", "JPG"];
    else
        var validExtensions = ["png", "webp", "jpeg", "PNG", "mp4", "jpg", "JPEG", "JPG"];
    var fileName = file.name;
    var fileNameExt = fileName.substr(fileName.lastIndexOf(".") + 1);
    if ($.inArray(fileNameExt, validExtensions) == -1)
        return { Success: false, Message: "Only these file types are accepted : jpg ,png ,webp ,jpeg" };
    if (30000000 < file.size)
        return { Success: false, Message: "File size exceeds 30 MB" };
    else
        return { Success: true }
}