import React, { useState, useEffect } from 'react';
import { Button, Container, Accordion } from 'react-bootstrap';
import Header from "../Layouts/header";
import Footer from "../Layouts/footer";
import Images from '../Components/Images';
import { toast, Slide } from "react-toastify";
import { useSelector } from "react-redux";
import { UserValidation, ImageValidation } from "../Action/Validations/Validation";
import config from '../Config/Config'
import isEmpty from "../Config/isEmpty";
import { EditProfiledata, GetProfiledata } from '../Action/Controller/Token'
import { useNavigate } from 'react-router';
import $ from 'jquery'
// import { v1_verifyuserMail } from "actions/v1/user";

const Profile = () => {
    const Wallet_Details = useSelector((state) => state.wallet_connect_context);
    const history = useNavigate()
    const initialForm = {
        Bio: "",
        CustomUrl: "",
        Twitter: "",
        Instagram: "",
        Facebook: "",
        Youtube: "",
        Email: "",
        Name: "",
        Site: "",
    }
    const [Formdata, setFormdata] = useState({ initialForm })
    const [valError, setvalError] = useState({})
    const [UpdateState, setUpdateState] = useState(false)
    const [Preview, setPreview] = useState('')
    useEffect(() => {
        console.log("zdas");
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }, []);

    const [file, setFile] = useState();

    useEffect(() => {
        console.log("Comming On UseEffect", Wallet_Details.UserAccountAddr);
        if (Wallet_Details.UserAccountAddr == "") {
            // toast.warning("Please Connect Your Wallet")
            setPreview(require('../Assets/logo.png'))
        }
        else {
            GetProfile()
        }
    }, [Wallet_Details.UserAccountAddr])

    const GetProfile = async () => {
        console.log("Wallet_Details.UserAccountAddr", Wallet_Details.UserAccountAddr);
        var Payload = { user: { curraddress: Wallet_Details.UserAccountAddr } }
        var Resp = await GetProfiledata(Payload)
        console.log("GetProfiledata Resp", Resp);
        if (!isEmpty(Resp)) {
            setFormdata(Resp)
            if (Resp.photo != "")
                setPreview(`${config.Back_URL}/Users/${Wallet_Details.UserAccountAddr}/profileImg/${Resp.photo}`)
            else
                setPreview(require('../Assets/logo.png'))
        }
    }

    const Handlefile = (e) => {
        if (e.target && e.target.files) {
            var file = e.target.files[0];
            var Resp = ImageValidation(file, "Edit")
            if (Resp.Success) {
                Formdata.File = file
                setPreview(URL.createObjectURL(file));
            }
            else
                toast.error(Resp.Message)
        }
    };

    const HandleChange = (e) => {
        e.preventDefault();
        const { id, value } = e.target;
        let formData = { ...Formdata, ...{ [id]: value } }
        setFormdata(formData)
    }

    const Submit = async () => {
        setUpdateState(true)
        Formdata.curraddress = Wallet_Details.UserAccountAddr
        var check = UserValidation(Formdata)
        console.log("check profile validationnnnn", check);
        if (check === true && Formdata.curaddress !== "") {
            const id = toast.loading("Updating Data Please Wait")
            var resp = await EditProfiledata(Formdata)
            // console.log("Editprofiledetail",resp)
            if (resp?.Success) {
                setTimeout(() => {
                    toast.update(id, { render: resp.Message, type: "success", isLoading: false, autoClose: 2000 });
                    history('/dashboard')
                }, 500);
                setUpdateState(false)
                if (Formdata.Email) {
                    if (resp.Mail) {
                        toast.success(`A verification Mail will be sent to ${Formdata.Email}`, { transition: Slide, theme: "dark", autoClose: false, closeButton: true, position: "bottom-right" })
                    }
                    else {
                        setTimeout(() => {
                            history('/dashboard')
                        }, 2500)
                    }
                }
            }
            else {
                setUpdateState(false)
                setTimeout(() => {
                    toast.update(id, { render: resp.Message, type: "error", isLoading: false, autoClose: 2000 });
                }, 500);
                $('html, body').animate({ scrollTop: 0 }, 'slow');
            }
        }
        else {
            setUpdateState(false)
            setvalError(check)
            toast.error("Form validation failed", { autoClose: 2500 })
            $('html, body').animate({ scrollTop: 0 }, 'slow');
        }
    }

    const Ipfsimageerr = (e) => {
        setPreview(require('../Assets/logo.png'))
    }

    return (

        <>

            <div className='faqpage animehead pt-5'>
                <Header />

                <div className='marketplace_section firsttop'>
                    <span class="violentgrad"></span>
                    <img src={Images.detailleftarrow} className='detailleftarrow' />
                    <img src={Images.circle} className='circle1' />
                    <div className='container'>

                        <div className='row'>
                            <div className='col-md-7 text-center mx-auto mb-5'>
                                <h4 className='ms-2 inputname mb-3'>Profile</h4>
                                <img src={Preview} class="img-fluid editprofileimg" onError={(e) => Ipfsimageerr(e)} />
                                <div className='browseinput'>
                                    {/* <img src={file} class="img-fluid editprofileimg"/> */}
                                    <button className='btn themebtn mt-3'> Upload Image</button>
                                    <input type="file" onChange={(e) => Handlefile(e)} />
                                </div>
                                <p className='whtclr mt-3 mb-1'> Profile Image</p>
                                <p className='whtclr'>( Image size must be 320px * 320px )</p>
                            </div>

                            <div className='col-md-6 mb-4'>
                                <h4 className='ms-2 inputname'>Name *</h4>
                                <input type="text" class="form-control user" onChange={(e) => { HandleChange(e) }} value={Formdata.Name} id="Name" placeholder="Name" />
                                {valError !== true && valError.Name &&
                                    <p className="Warning_label mt-2">{valError.Name}</p>
                                }
                            </div>
                            <div className='col-md-6 mb-4'>
                                <h4 className='ms-2 inputname'>Wallet Address</h4>
                                <input type="text" class="form-control user" disabled placeholder="Wallet Address" defaultValue={Wallet_Details.UserAccountAddr} />
                            </div>
                            <div className='col-md-6 mb-4'>
                                <h4 className='ms-2 inputname'>Email *</h4>
                                <input type="mail" onChange={(e) => { HandleChange(e) }} value={Formdata.Email} id="Email" class="form-control user" placeholder="Mail" />
                                {valError !== true && valError.Email &&
                                    <p className="Warning_label mt-2">{valError.Email}</p>

                                }
                            </div>
                            <div className='col-md-6 mb-4'>
                                <h4 className='ms-2 inputname'>Personal site or portfolio</h4>
                                <input type="text" onChange={(e) => { HandleChange(e) }} id="Site" value={Formdata.Site} class="form-control user" placeholder="your own website link" />
                            </div>
                            <div className='col-md-6 mb-4'>
                                <h4 className='ms-2 inputname'>Custom URL</h4>
                                <input type="text" class="form-control user" onChange={(e) => { HandleChange(e) }} value={Formdata.CustomUrl} id="CustomUrl" placeholder="your own website link" />
                                {valError !== true && valError.CustomUrl &&
                                    <p className="Warning_label mt-2">{valError.CustomUrl}</p>
                                }
                            </div>
                            <div className='col-md-6 mb-4'>
                                <h4 className='ms-2 inputname'>Bio</h4>
                                <input type="text" onChange={(e) => { HandleChange(e) }} value={Formdata.Bio} id="Bio" class="form-control user" placeholder="Tell about yourself" />
                            </div>

                            <div className='col-md-6 mb-4'>
                                <h4 className='ms-2 inputname'>Social Media Links</h4>
                                <input type="text" onChange={(e) => { HandleChange(e) }} value={Formdata.Twitter} id="Twitter" class="form-control user mb-3" placeholder="Twitter account link" />
                                <input type="text" onChange={(e) => { HandleChange(e) }} value={Formdata.Youtube} id="Youtube" class="form-control user mb-3" placeholder="Youtube channel link" />
                                <input type="text" onChange={(e) => { HandleChange(e) }} value={Formdata.Facebook} id="Facebook" class="form-control user mb-3" placeholder="Facebook account link" />
                                <input type="text" onChange={(e) => { HandleChange(e) }} value={Formdata.Instagram} id="Instagram" class="form-control user mb-3" placeholder="Instagram account link" />
                            </div>
                            <div className='col-md-6 mb-4'>
                                {console.log("Wallet_Detttttt", Wallet_Details)}
                                <h4 className='ms-2 inputname'>Referred By</h4>
                                <input type="text" id="Bio" class="form-control user" placeholder="Tell about yourself" disabled defaultValue={Wallet_Details?.RefDetails?.FromAddress} />
                            </div>
                            <div className='col-md-7 text-center mx-auto'>
                                <button className='btn themebtn px-4 py-2 mt-3' disabled={UpdateState} onClick={() => { Submit() }}> Submit</button>
                            </div>
                        </div>

                    </div>

                </div>
                <Footer />
            </div>

        </>

    )
}

export default Profile;
