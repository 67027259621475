import React, { useState, useEffect } from 'react';
import { Button, Container } from 'react-bootstrap';
import Header from "../Layouts/header";
import Headermobile from "../Layouts/headermobile";
import Footer from "../Layouts/footer";
import Images from '../Components/Images';
import Sidebar from '../Layouts/sidebar';
import ReactStars from "react-rating-stars-component";
import Chartss from "../Components/charts";
import Chartss1 from "../Components/chartsrealtime";
import { LuRefreshCcw } from "react-icons/lu";
import { IoIosRefresh } from "react-icons/io";
import { VscArrowSwap } from "react-icons/vsc";
import { IoExpand } from "react-icons/io5";
import Treegraph from '../Components/treegraph';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import config from '../Config/Config'
import { IncomeAndNode, LevelUpData, FindCurrentLevelUser, CheckLevels3 } from '../Action/Controller/Token'
import { Account_Connect, Account_disConnect } from "../Redux/action";
import { toast } from 'react-toastify';
import CopyToClipboard from "react-copy-to-clipboard";
import { FiCopy } from "react-icons/fi";
const moment = require('moment');

const Dashboard = () => {
    const dispatch = useDispatch();
    const Wallet_Details = useSelector((state) => state.wallet_connect_context);
    const history = useNavigate()
    console.log("Wallet_Details_Dashboard", Wallet_Details);
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }, []);

    const firstExample = {
        size: 21,
        value: Wallet_Details?.UserLevels?.StarLevel,
        count: 10,
        edit: false,
        color: "#fff",
        activeColor: "gold",
        // isHalf: true,

    };

    const [userdata, setUserdata] = useState(2)
    const [LeftIncome, setLeftIncome] = useState(0)
    const [RightIncome, setRightIncome] = useState(0)
    const [Rewardstotal, setRewardstotal] = useState({})
    const [filterText, setfilterText] = useState("")
    const [RENDTDet, SetRENDTDet] = useState(Wallet_Details?.User)

    useEffect(() => {
        GetLevelUps()
        UpdateLevel3()
    }, [Wallet_Details?.UserAccountAddr]);

    const GetLevelUps = async () => {
        console.log("Wallet_Details?.UserAccountAddrsdfasda ,Dashboard", Wallet_Details?.UserAccountAddr);
        if (Wallet_Details?.UserAccountAddr) {
            console.log("asdasdasdsad Dashboard");
            let SendData = {
                addr: Wallet_Details?.UserAccountAddr,
            }
            console.log("SendData", SendData);
            var SetDaata = await LevelUpData(SendData)
            console.log("SetDaata Dashboard", SetDaata);
            if (SetDaata?.Status) {
                dispatch({
                    type: Account_Connect,
                    Account_Detail: {
                        UserLevels: SetDaata?.Data,
                    }
                })
            }
        }
    }

    const UpdateLevel3 = async () => {
        if (Wallet_Details?.UserAccountAddr) {
            let Payload11 = { user: { curraddress: Wallet_Details?.UserAccountAddr } }
            var RefereddCheckReferred = await CheckLevels3(Payload11)
            console.log("RefereddHearrrrrCheckReferred", RefereddCheckReferred);
            if (RefereddCheckReferred?.status) {
                dispatch({
                    type: Account_disConnect,
                    Account_Disconnect: {
                        UserLevels: RefereddCheckReferred?.ReferedOrNot
                    },
                });
            }
        }
    }

    useEffect(() => {
        NodeAndRewards()
    }, [Wallet_Details.UserAccountAddr])

    const NodeAndRewards = async () => {
        console.log("TreeGraphApi", Wallet_Details?.UserAccountAddr);
        if (Wallet_Details?.UserAccountAddr) {
            var Payload = { user: { curraddress: Wallet_Details?.UserAccountAddr } }
            var Resp = await IncomeAndNode(Payload)
            console.log("Resp From Treee", Resp);
            if (Resp?.status) {
                setRewardstotal(Resp?.TranscationCalimed[0])
                setRightIncome(Resp?.RightInvest)
                setLeftIncome(Resp?.LeftInvest)
                dispatch({
                    type: Account_Connect,
                    Account_Detail: {
                        RewardDetail: Resp
                    }
                })
            }
        }

    }

    useEffect(() => {
        SetRENDTDet(Wallet_Details?.User)
    }, [Wallet_Details.UserAccountAddr])

    const filteredItems = async () => {
        console.log("filterText", filterText);
        if (filterText !== "") {
            var FilerData = Wallet_Details?.AllUsers?.filter(
                item =>
                    item?.curraddress?.toLowerCase().includes(filterText?.toLowerCase())
                    ||
                    item?.Referral?.toLowerCase().includes(filterText?.toLowerCase())
            );
            console.log("FilerData", FilerData);
            if (FilerData?.length > 0) {
                SetRENDTDet(FilerData[0])
            } else {
                toast.error("Enter Valid User ID")
                SetRENDTDet(Wallet_Details?.User)
            }
        } else {
            toast.error("Enter User ID")
            SetRENDTDet(Wallet_Details?.User)
        }
    }

    const RefreahNode = async () => {
        console.log("Date.now()Date.now()", Date.now());
        dispatch({
            type: Account_Connect,
            Account_Detail: {
                Refreash: Date.now()
            }
        })
        setTimeout(() => {
            toast.success("Data Refreshed")
        }, 800)
    }


    // useEffect(() => {
    //     filteredItems()
    // }, [filterText])

    // useEffect(() => {
    //     GetLevelUps()
    // }, [Wallet_Details?.UserAccountAddr])

    // const GetLevelUps = async () => {
    //     console.log("Wallet_Details?.UserAccountAddrsdfasda", Wallet_Details?.UserAccountAddr);
    //     if (Wallet_Details?.UserAccountAddr) {
    //         console.log("asdasdasdsad");
    //         let SendData = {
    //             addr: Wallet_Details?.UserAccountAddr,
    //         }
    //         console.log("SendData", SendData);
    //         var SetDaata = await LevelUpData(SendData)
    //         console.log("SetDaata", SetDaata);
    //         if (SetDaata?.Status) {
    //             dispatch({
    //                 type: Account_Connect,
    //                 Account_Detail: {
    //                     UserLevels: SetDaata?.Data,
    //                 }
    //             })
    //         }
    //     }
    // }

    return (
        <>

            <div className='dashboard animehead'>

                <div className='d-none d-lg-block'> <Header /></div>
                <div className='d-block d-lg-none'><Headermobile /></div>

                <div className='dashboardss'>

                    <img src={Images.topline} className='img-fluid topline w-100' />
                    <img src={Images.rightline} className='img-fluid rightline d-none d-md-block' />
                    <span style={{ background: Wallet_Details?.UserLevels?.Colour }} className={`blugradient plan${firstExample.value}`}></span>
                    <span style={{ background: Wallet_Details?.UserLevels?.Colour }} className={`blugradient1 plan${firstExample.value}`}></span>
                    <span style={{ background: Wallet_Details?.UserLevels?.Colour }} className={`blugradient blugradientss plan${firstExample.value}`}></span>
                    <Sidebar />

                    <div className='alllist'>
                        <div className='container'>
                            {/* <div className='planss d-flex justify-content-center mb-2'>
                                        <p className='mb-0 me-2 plan1' onClick={()=>setUserdata(1)}>1</p>
                                        <p className='mb-0 me-2 plan2' onClick={()=>setUserdata(2)}>2</p>
                                        <p className='mb-0 me-2 plan3' onClick={()=>setUserdata(3)}>3</p>
                                        <p className='mb-0 me-2 plan4' onClick={()=>setUserdata(4)}>4</p>
                                        <p className='mb-0 me-2 plan5' onClick={()=>setUserdata(5)}>5</p>
                                     </div> */}
                            <div className='row'>
                                <div className='col-md-9 col-lg-7 mt-2 mt-md-0 overpos mx-auto'>
                                    <img src={Images.cardimg} className='img-fluid cardimg' />
                                    <div className='f1'>
                                        <h3 className='statuses text-start mb-0'>STATUS</h3>
                                        <p className='starter mb-0'>{Wallet_Details?.UserLevels?.RankName ? Wallet_Details?.UserLevels?.RankName : "Starter"}</p>
                                        {/* <p className='starter mb-0'>Emperor/Empress (Leadership)</p> */}

                                    </div>
                                    {console.log("Wallet_Details.User.image", Wallet_Details?.User?.image)}
                                    <img src={Wallet_Details?.User?.image ? `${config.Back_URL}/Users/${Wallet_Details?.UserAccountAddr}/profileImg/${Wallet_Details?.User?.image}` : Images.dashprofimg} className='img-fluid dashprofimg' />
                                    <div className='f3'>
                                        <div className='position-relative'>
                                            <ReactStars {...firstExample} />
                                            <img src={Images.bluelogo} className='img-fluid bluelogo' />
                                        </div>
                                    </div>

                                </div>

                                <div className='col-md-12 overpos unionimg mx-auto'>
                                    <div className='row justify-content-center align-items-center px-0 px-xl-5'>
                                        <div className='col-md-12 col-lg-4 mt-3 d-flex align-items-center justify-content-center'>
                                            <img src={Images.iconss} className='img-fluid me-2' />
                                            <div className=''>
                                                <h6 className='text-start mainheading'>Total Left Payment</h6>
                                                <h4 className='textwht mb-0'>{LeftIncome ? LeftIncome?.toFixed(4) : 0}</h4>
                                            </div>
                                        </div>
                                        <div className='col-md-12 col-lg-4 mt-3'>
                                            <div className='text-center'>
                                                <h4 className='status'>{Wallet_Details?.User ? Wallet_Details?.User?.name ? Wallet_Details?.User?.name : Wallet_Details?.UserAccountAddr.slice(0, 14) : config.AdminAddress.slice(0, 14)}</h4>

                                                {/* <p className='subtext mb-0'>{Wallet_Details?.User?.Referral}</p> */}

                                                <CopyToClipboard
                                                    text={Wallet_Details?.User?.Referral}
                                                    onCopy={() =>
                                                        toast.success("Copied Successfully", {
                                                            closeButton: true,
                                                            autoClose: 1500,
                                                            theme: "dark",
                                                        })
                                                    }
                                                >

                                                    <div className='text-center d-flex align-items-center justify-content-center'>  <p className='subtext status1 mb-0 me-2'>{Wallet_Details?.User?.Referral || config.AdminReferralKey}</p><FiCopy className='ficpoy' /></div>

                                                </CopyToClipboard>


                                                {/* <h4 className='status'>0873gh8adsa48sgp</h4>
                                                <p className='subtext status1 mb-0'>op9hj0sk2jh</p> */}
                                            </div>
                                        </div>
                                        <div className='col-md-12 col-lg-4 mt-3 d-flex align-items-center justify-content-center'>
                                            <div className=''>
                                                <h6 className='text-start mainheading'>Total Right Payment</h6>
                                                <h4 className='textwht mb-0'>{RightIncome ? RightIncome?.toFixed(4) : 0}</h4>
                                            </div>
                                            <img src={Images.iconss} className='img-fluid me-2' />
                                        </div>
                                    </div>
                                </div>


                            </div>



                        </div>
                    </div>

                </div>


                <div className='container'>
                    <div className='row'>

                        <div className='col-10 col-sm-12 col-md-12 allflex mt-4 mb-5 mx-auto'>
                            <div className='allname mt-3'>
                                <h6 className='text-start mainheading px-3'>
                                    {/* Total Invested */}
                                    MLM Invesment
                                </h6>
                                <h5 className='numberdetail ps-3'>{(Number(LeftIncome ? LeftIncome : 0) +
                                    (RightIncome ? RightIncome : 0)).toFixed(4)}</h5>
                            </div>
                            <div className='allname mt-3'>
                                <h5 className='text-start mainheading px-3'>Total Income</h5>
                                <h5 className='numberdetail ps-3'>{(Number(Wallet_Details?.User?.totalRewardEarned) + Number(Wallet_Details?.User?.LevelReward) + Number(Wallet_Details?.User?.sellValue))?.toFixed(4) || 0}</h5>
                                {/* Rewardstotal?.totalClaimed ? Rewardstotal?.totalClaimed?.toFixed(5) : 0 */}
                            </div>
                            <div className='allname mt-3'>
                                <h5 className='text-start mainheading px-3'>Overall Balance</h5>
                                <h5 className='numberdetail ps-3'>{(Number(Wallet_Details?.User?.treePendingReward || 0) + Number(Wallet_Details?.User?.subscriptionPendingReward || 0) + Number(Wallet_Details?.User?.LevelRewardClaimed || 0))?.toFixed(4) || 0}</h5>
                            </div>
                            <div className='allname mt-3'>
                                <h5 className='text-start mainheading px-3'>My Invested</h5>
                                <h5 className='numberdetail ps-3'>{(Wallet_Details?.User?.Invesment?.toFixed(5)) || 0}</h5>
                            </div>
                            <div className='allname mt-3'>
                                <h5 className='text-start mainheading px-3'>Reward Earned</h5>
                                <h5 className='numberdetail ps-3'>{(Number(Wallet_Details?.User?.RewardClimed))?.toFixed(4) || 0}</h5>
                            </div>

                        </div>



                        <div className='col-md-12 pt-4 pb-5 overpos mx-auto'>
                            <img src={Images.dashright1} className='img-fluid dashright1' />
                            <img src={Images.botgraphvector} className='curve botgraphvector' />
                            <div className='dashgraph'>
                                {/* <Chartss /> */}
                                <Chartss1 />
                            </div>
                        </div>

                        <div className='col-md-12 pt-4 pb-3 marketplace_section overpos mx-auto'>
                            <div className='row mt-4'>
                                <div className='col-sm-5 col-md-3 d-flex mt-3'>
                                    <div className='blubox' onClick={() => { RefreahNode() }}><LuRefreshCcw /></div>
                                    <Link to='/extendgraph'><div className='blubox mx-2'><IoExpand /></div></Link>
                                    {/* <div className='blubox fw-600'><VscArrowSwap /></div> */}
                                </div>
                                <div className='col-sm-7 col-md-9  d-flex mt-3'>
                                    <div className='searchback d-none d-md-block d-lg-flex' onClick={() => setfilterText("")} ><IoIosRefresh /></div>
                                    {console.log("filterText", filterText)}
                                    <input type="text" class="form-control user" placeholder="Search User ID" onChange={(e) => { setfilterText(e.target.value) }} value={filterText} />
                                    <div className='findss'><h5 className='blktxt mb-0' onClick={() => { filteredItems() }}>Find</h5></div>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-12 pb-3'>
                            <div className='treeback'>
                                <Treegraph />
                            </div>
                        </div>

                        <div className='col-md-12 selectedtext overpos pb-5 mt-5'>
                            <img src={Images.selectleft} className='img-fluid selectleft' />
                            <img src={Images.roaddownvector} className='roaddowns' />
                            <h5 className='textclr d-flex align-items-center mb-4'> <img src={Images.ivector} className='img-fluid ivector me-2' /> User Details </h5>
                            {console.log("RENDTDet", RENDTDet)}
                            {RENDTDet ?
                                <div className='row'>
                                    <div className='col-sm-6 col-lg-4'>

                                        <CopyToClipboard
                                            text={RENDTDet?.Referral}
                                            onCopy={() =>
                                                toast.success("Copied Successfully", {
                                                    closeButton: true,
                                                    autoClose: 1500,
                                                    theme: "dark",
                                                })
                                            }
                                        >

                                            <div className=''>
                                                <p><span className='textclr'>ID :</span> <span className='bluclr'>{RENDTDet?.Referral}</span>  <FiCopy className='ficpoy' /></p>

                                            </div>

                                        </CopyToClipboard>

                                        {/* <p><span className='textclr'>Rank :</span> <span className='bluclr'>Starter</span></p> */}
                                        <p><span className='textclr'>Direct(s) :</span> <span className='bluclr'>{RENDTDet?.children?.length}</span></p>
                                        <p><span className='textclr'>Total left credit :</span> <span className='bluclr'>{RENDTDet?.leftTreecredit}</span></p>
                                        <p><span className='textclr'>Registration Date :</span> <span className='bluclr'>{moment(RENDTDet?.date).format('MM/DD/YYYY h:mm:ss A')}</span></p>
                                    </div>
                                    <div className='col-sm-6 col-lg-4'>
                                        <CopyToClipboard
                                            text={RENDTDet?.curraddress}
                                            onCopy={() =>
                                                toast.success("Copied Successfully", {
                                                    closeButton: true,
                                                    autoClose: 1500,
                                                    theme: "dark",
                                                })
                                            }
                                        >

                                            <div className=''>
                                                <p><span className='textclr'>Address :</span> <span className='bluclr'>{RENDTDet?.curraddress?.slice(0, 20) + "..."}</span>  <FiCopy className='ficpoy' /></p>

                                            </div>

                                        </CopyToClipboard>
                                        <p><span className='textclr'>Left downlines :</span> <span className='bluclr'>{RENDTDet?.LeftLegAddress ? 1 : 0} Persons</span></p>
                                        <CopyToClipboard
                                            text={RENDTDet?.RightLegAddress}
                                            onCopy={() =>
                                                toast.success("Copied Successfully", {
                                                    closeButton: true,
                                                    autoClose: 1500,
                                                    theme: "dark",
                                                })
                                            }
                                        >

                                            <div className=''>
                                                <p><span className='textclr'>RightLeg :</span> <span className='bluclr'>{RENDTDet?.RightLegAddress ? (RENDTDet?.RightLegAddress?.slice(0, 20) + "...") : "-"}</span>
                                                    {RENDTDet?.RightLegAddres && <FiCopy className='ficpoy' />}</p>
                                            </div>

                                        </CopyToClipboard>

                                        <p><span className='textclr'>Total Invesment :</span> <span className='bluclr'>{RENDTDet?.Invesment}</span></p>
                                        {/* <p><span className='textclr'>Is Maintained :</span> <span className='bluclr'>False</span></p> */}
                                    </div>
                                    <div className='col-sm-6 col-lg-4'>
                                        <p><span className='textclr'>Payment :</span> <span className='bluclr'>{(Number(RENDTDet?.totalRewardEarned) + Number(RENDTDet?.RewardClimed) + Number(RENDTDet?.LevelRewardClaimed))?.toFixed(4)}</span></p>
                                        <p><span className='textclr'>Total Right credit :</span> <span className='bluclr'>{RENDTDet?.rightTreecredit}</span></p>
                                        <CopyToClipboard
                                            text={RENDTDet?.LeftLegAddress}
                                            onCopy={() =>
                                                toast.success("Copied Successfully", {
                                                    closeButton: true,
                                                    autoClose: 1500,
                                                    theme: "dark",
                                                })
                                            }
                                        >

                                            <div className=''>
                                                <p><span className='textclr'>LeftLeg :</span> <span className='bluclr'>{RENDTDet?.LeftLegAddress ? (RENDTDet?.LeftLegAddress?.slice(0, 20) + "...") : "-"}</span>{RENDTDet?.LeftLegAddress && <FiCopy className='ficpoy' />}</p>

                                            </div>

                                        </CopyToClipboard>

                                        <p><span className='textclr'>unClaimed Amount :</span> <span className='bluclr'>{(Number(RENDTDet?.LevelRewardPending) + Number(RENDTDet?.treePendingReward) + Number(RENDTDet?.subscriptionPendingReward)).toFixed(4)}</span></p>
                                        {/* <p><span className='textclr'>Must Maintained :</span> <span className='bluclr'>False</span></p> */}
                                    </div>
                                </div>
                                : <h4 className='textclr mainheading text-center'>No Data Found</h4>}
                        </div>

                    </div>


                </div>



                <Footer />

            </div>

        </>
    )
}

export default Dashboard;